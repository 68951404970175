/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type GebuehrType = 'GEBUEHR' | 'DEPOT' | 'MITGLIEDER_BEITRAG';

export const GebuehrType = {
    GEBUEHR: 'GEBUEHR' as GebuehrType,
    DEPOT: 'DEPOT' as GebuehrType,
    MITGLIEDER_BEITRAG: 'MITGLIEDER_BEITRAG' as GebuehrType
};

