/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxBewerbung} from '@dv/shared/backend/model/jax-bewerbung';
import type {DayOfWeek, IPersistable, IRestModel, IValidable, Persisted} from '@dv/shared/code';
import {BewerbungsStatus, checkPresent, DvbDateUtil, DvbRestUtil, DvbUtil, KindergartenBelegung} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {Firma} from '../firma/Firma';
import type {KinderOrt} from '../kinderort/KinderOrt';
import {KinderOrtTransformer} from '../kinderort/KinderOrtTransformer';
import {BelegteEinheit} from './BelegteEinheit';
import {Pensum} from './Pensum';
import {PlatzTypen} from './PlatzTypen';

export interface ISelectedFirma extends Persisted<Firma> {
    selected: boolean;
}

export class Bewerbung implements IPersistable, IRestModel<JaxBewerbung, [boolean]>, IValidable {

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public static prioritaeten: readonly number[] = [1, 2, 3, 4];

    public constructor(
        public id: string | null = null,
        public kitas: KinderOrt[] = [],
        public kitasIds: string[] = [],
        public belegteEinheiten: BelegteEinheit[] = [],
        public firmen: Persisted<Firma>[] = [],
        public firmenSelection: ISelectedFirma[] = [],
        public firmenIds: string[] = [],
        public pensum: Pensum = new Pensum(),
        public subventionierterPlatz: boolean = false,
        public privaterPlatz: boolean = false,
        public gewuenschteBetreuungAb: moment.Moment | null = null,
        public prioritaet: number = 4,
        public kindergarten: boolean = false,
        public status: BewerbungsStatus = BewerbungsStatus.OFFEN,
        public wochenplanId: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Bewerbung {
        const answer = new Bewerbung();
        answer.id = data.id;
        answer.gewuenschteBetreuungAb = DvbRestUtil.localDateToMoment(data.gewuenschteBetreuungAb);
        answer.subventionierterPlatz = !!data.subventionierterPlatz;
        answer.privaterPlatz = !!data.privaterPlatz;
        answer.pensum.von = data.pensumVonPct;
        answer.pensum.bis = data.pensumBisPct;
        answer.kindergarten = !!data.kindergarten;
        answer.prioritaet = parseInt(data.prioritaet, 10);
        answer.status = data.status;
        answer.wochenplanId = data.wochenplanId;
        if (Array.isArray(data.firmen) && data.firmen.length > 0) {
            answer.firmen = data.firmen.map((f: any) => {
                answer.firmenIds.push(f.id);

                return Firma.apiResponseTransformer(f);
            });
            answer.firmenSelection = answer.firmen.map(f => Object.assign(angular.copy(f), {selected: true}));
        } else if (Array.isArray(data.firmenIds)) {
            answer.firmenIds = data.firmenIds;
        }
        if (Array.isArray(data.kitas) && data.kitas.length > 0) {
            answer.kitas = data.kitas.map((k: any) => {
                answer.kitasIds.push(k.id);

                return KinderOrtTransformer.create().apiResponseTransformer(k);
            });
        } else if (Array.isArray(data.kitasIds)) {
            answer.kitasIds = data.kitasIds;
        }
        if (Array.isArray(data.belegteEinheiten)) {
            answer.belegteEinheiten = data.belegteEinheiten.map((b: any) => BelegteEinheit.apiResponseTransformer(b));
        }

        return answer;
    }

    public platzTypen(kontingente?: any[]): PlatzTypen {
        const model = new PlatzTypen(
            this.privaterPlatz,
            this.subventionierterPlatz,
            this.kindergarten ? KindergartenBelegung.WUNSCH : KindergartenBelegung.KEINE,
            null,
            [],
        );

        // TODO macht es ueberhaut Sinn hier eine Filterung anzubieten? Statt Firmen koennte man auch FirmenIds
        // zurueck geben. Dann liegt es in der Verantwortung des Consumers bei Bedarf die Firma zu laden.
        if (Array.isArray(kontingente)) {
            const firmenIds = this.firmenIds;
            const filteredKontingente = kontingente.filter(k => k.firma?.id && firmenIds.includes(k.firma.id));
            model.firmen = filteredKontingente
                .map(k => k.firma);
            model.firmenKontingentIds = filteredKontingente.map(DvbUtil.mapToIdChecked);
        } else {
            model.firmen = this.firmen;
        }

        return model;
    }

    public isSelectedTag(dayOfWeek: DayOfWeek): boolean {
        return this.belegteEinheiten.some(einheit => angular.equals(einheit.wochentag, dayOfWeek));
    }

    public displayPensum(): string {
        return this.pensum.display();
    }

    public isKitaSet(): boolean {
        return this.kitas.length > 0;
    }

    public isPlatzSelected(): boolean {
        return this.subventionierterPlatz || this.privaterPlatz || this.firmenIds.length > 0;
    }

    public isValid(): boolean {
        return this.isKitaSet()
            && this.isPlatzSelected()
            && (this.gewuenschteBetreuungAb === null || DvbDateUtil.isValidMoment(this.gewuenschteBetreuungAb));
    }

    public hasGewuenschteBetreuungAb(): boolean {
        return DvbDateUtil.isValidMoment(this.gewuenschteBetreuungAb);
    }

    /**
     * @return TRUE when new firma selected, FALSE when already selected
     */
    public addFirmaToSelection(firma: ISelectedFirma): boolean {
        const isNew = !this.firmenSelection.some(f => f.id === firma.id);

        if (isNew) {
            const f = angular.copy(firma);
            f.selected = true;
            this.firmenSelection.push(f);
        }

        return isNew;
    }

    /**
     * @return TRUE when new firma added, FALSE when already included
     */
    public addFirma(firma: Firma): boolean {
        const firmaId = checkPresent(firma.id);

        if (!this.firmenIds.includes(firmaId)) {
            this.firmenIds.push(firmaId);

            return true;
        }

        return false;
    }

    public removeFirma(firma: Firma): void {
        DvbUtil.removeFromArray(firma.id, this.firmenIds);
    }

    /**
     * @return TRUE when new kita added, FALSE when already included
     */
    public addKita(kita: KinderOrt): boolean {
        const isNew = !this.kitas.some(k => k.id === kita.id);

        if (isNew) {
            this.kitas.push(kita);
            this.kitasIds.push(kita.id!);
        }

        return isNew;
    }

    public removeKita(kita: KinderOrt): void {
        DvbUtil.removeFromArray(kita, this.kitas);
        DvbUtil.removeFromArray(kita.id, this.kitasIds);
    }

    public toRestObject(withRelations: boolean = false): JaxBewerbung {
        const restObject: any = {
            id: this.id,
            gewuenschteBetreuungAb: DvbRestUtil.momentToLocalDate(this.gewuenschteBetreuungAb),
            pensumVonPct: this.pensum.von,
            pensumBisPct: this.pensum.bis,
            subventionierterPlatz: this.subventionierterPlatz,
            privaterPlatz: this.privaterPlatz,
            prioritaet: this.prioritaet,
            kindergarten: this.kindergarten,
            status: this.status,
            wochenplanId: this.wochenplanId,
        };

        if (withRelations) {
            restObject.kitasIds = this.kitasIds;
            restObject.belegteEinheiten = this.belegteEinheiten.map(b => b.toRestObject());
            restObject.firmenIds = this.firmenIds;
        }

        return restObject;
    }
}
