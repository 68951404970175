/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel, IValidable} from '@dv/shared/code';
import {DOKUMENT_LAYOUT_MAX_MM, DvbUtil} from '@dv/shared/code';
import {ImageBlob} from '../blob/ImageBlob';

export class DokumentLayoutLogo implements IRestModel, IValidable {

    public constructor(
            public image: ImageBlob | null = null,
            public width: number | null = null,
            public left: number | null = null,
            public top: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): DokumentLayoutLogo {
        const image = data.image ? ImageBlob.apiResponseTransformer(data.image) : null;

        return new DokumentLayoutLogo(image, data.width, data.left, data.top);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            logoTempBlobId: this.image ? this.image.id : null,
            width: this.width,
            left: this.left,
            top: this.top,
        };
    }

    public isValid(): boolean {
        return !!this.image &&
                this.width !== null && DvbUtil.isInRange(this.width, 0, DOKUMENT_LAYOUT_MAX_MM) &&
                this.left !== null && DvbUtil.isInRange(this.left, 0, DOKUMENT_LAYOUT_MAX_MM) &&
                this.top !== null && DvbUtil.isInRange(this.top, 0, DOKUMENT_LAYOUT_MAX_MM);
    }
}
