/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {checkPresent} from '@dv/shared/code';
import type {Rechnung} from '../Rechnung';

export class BankStatementEntryMatch {

    public static matchTypes: readonly BankStatementEntryMatch[] = [
        new BankStatementEntryMatch('REFERENZNUMMER', 'FAKTURA.KONTENABGLEICH.MATCH_BY_REFERENZNUMMER'),
        new BankStatementEntryMatch('KONTAKTPERSON', 'FAKTURA.KONTENABGLEICH.MATCH_BY_KONTAKTPERSON',
                (type): (string | { [index: string]: string } | undefined) => {
                    if (type.rechnungen.length === 0) {
                        return undefined;
                    }

                    return {name: checkPresent(type.rechnungen[0].kontaktpersonDisplayName)};
                }),
    ];

    public constructor(
            public key: string,
            public translationKey: string,
            public translationArgsFn: ((t: BankStatementEntryMatch) =>
                    (string | { [index: string]: string } | undefined)) | null = null,
            public loading: boolean = false,
            public rechnungen: Rechnung[] = [],
            public itemsPerPage: number = 10,
            public currentPage: number = 1,
            public pages: Rechnung[][] = [],
    ) {
    }

    public recomputePages(): void {
        this.pages = [];
        this.rechnungen.forEach((rechnung, index) => {
            const i = Math.floor(index / this.itemsPerPage);
            if (!Array.isArray(this.pages[i])) {
                this.pages[i] = [];
            }

            this.pages[i].push(rechnung);
        });
    }

    public getPagedItems(): Rechnung[] {
        return this.rechnungen.filter((_rechnung, index) => {
            const skip = (this.currentPage - 1) * this.itemsPerPage;

            return index > skip && index <= skip + this.itemsPerPage;
        });
    }
}
