/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungStatusText} from '@dv/shared/code';
import {KitaKonfigurationTexte} from '../KitaKonfigurationTexte';
import type {IKitaVendorKonfiguration} from '../konfiguration/IKitaVendorKonfiguration';

export class SmallInvoiceKitaKonfiguration implements IKitaVendorKonfiguration {

    public constructor(
        public bankKontoId: number | null = null,
        public bankKontoName: string | null = null,
        public esrId: number | null = null,
        public esrName: string | null = null,
        public layoutId: number | null = null,
        public layoutName: string | null = null,
        public paypal: boolean = false,
        public postfinance: boolean = false,
        public massenversandText: string | null = null,
        public massenversandOben: number | null = null,
        public massenversandLinks: number | null = null,
        public texte: { [index: string]: KitaKonfigurationTexte } = {},
    ) {
    }

    public static apiResponseTransformer(data: any): SmallInvoiceKitaKonfiguration {
        const answer = new SmallInvoiceKitaKonfiguration();
        answer.bankKontoId = data.bankKontoId;
        answer.bankKontoName = data.bankKontoName;
        answer.esrId = data.esrId;
        answer.esrName = data.esrName;
        answer.layoutId = data.layoutId;
        answer.layoutName = data.layoutName;
        answer.paypal = data.paypal;
        answer.postfinance = data.postfinance;
        answer.massenversandText = data.massenversandText;
        answer.massenversandOben = data.massenversandOben;
        answer.massenversandLinks = data.massenversandLinks;

        data.texte.forEach((entry: { status: RechnungStatusText; texte: any }) => {
            answer.texte[entry.status] = KitaKonfigurationTexte.apiResponseTransformer(entry.texte);
        });

        return answer;
    }

    public toRestObject(): Record<string, unknown> {

        const texte = Object.keys(this.texte).map(status => ({
            status,
            texte: this.texte[status].toRestObject(),
        }), this);

        return {
            bankKontoId: this.bankKontoId,
            bankKontoName: this.bankKontoName,
            esrId: this.esrId,
            esrName: this.esrName,
            layoutId: this.layoutId,
            layoutName: this.layoutName,
            paypal: this.paypal,
            postfinance: this.postfinance,
            massenversandText: this.massenversandText,
            massenversandOben: this.massenversandOben,
            massenversandLinks: this.massenversandLinks,
            texte,
        };
    }
}
