/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxKonto} from '@dv/shared/backend/model/jax-konto';
import {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import type {IPersistable, IRestModel} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import {DvbMandantKonfiguration} from '../dvb/DvbMandantKonfiguration';
import {SmallInvoiceMandantKonfiguration} from '../smallinvoice/SmallInvoiceMandantKonfiguration';
import type {IRechnungsKonfiguration} from './IRechnungsKonfiguration';
import {RechnungsKonfigurationStatus} from './RechnungsKonfigurationStatus';

export class RechnungsKonfiguration<T extends IRechnungsKonfiguration = IRechnungsKonfiguration>
    implements IRestModel, IPersistable {

    public static readonly STATUS: typeof RechnungsKonfigurationStatus = RechnungsKonfigurationStatus;

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public dtype: RechnungsKonfigurationType | null = null,
        public status: RechnungsKonfigurationStatus = RechnungsKonfigurationStatus.ACTIVE,
        public konfiguration: T | null = null,
        public konto: JaxKonto | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): RechnungsKonfiguration<IRechnungsKonfiguration> {
        const answer = new RechnungsKonfiguration();
        answer.id = data.id;
        answer.name = data.name;
        answer.status = data.status;
        answer.dtype = data.dtype;
        answer.konto = data.konto;

        switch (answer.dtype) {
            case RechnungsKonfigurationType.SMALLINVOICE:
                answer.konfiguration = SmallInvoiceMandantKonfiguration.apiResponseTransformer(data.konfiguration);
                break;
            case RechnungsKonfigurationType.DVB:
                answer.konfiguration = DvbMandantKonfiguration.apiResponseTransformer(data.konfiguration);
                break;
            default:
                throw new Error(`RechnungsKonfiguration dtype not supported: ${JSON.stringify(answer.dtype)}`);
        }

        return answer;
    }

    public isValid(this: RechnungsKonfiguration<IRechnungsKonfiguration>): boolean {
        return DvbUtil.isNotEmptyString(this.name) && !!this.konfiguration;
    }

    public isDvbRechnungsKonfiguration(): this is RechnungsKonfiguration<DvbMandantKonfiguration> {
        return this.dtype === RechnungsKonfigurationType.DVB;
    }

    public isSmallInvoiceRechnungsKonfiguration(): this is RechnungsKonfiguration<SmallInvoiceMandantKonfiguration> {
        return this.dtype === RechnungsKonfigurationType.SMALLINVOICE;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            dtype: this.dtype,
            status: this.status,
            konfiguration: this.konfiguration ? this.konfiguration.toRestObject() : null,
            kontoId: this.konto?.id,
        };
    }
}
