/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {UploadTempBlob} from '../blob/UploadTempBlob';

export class TarifTemplate extends UploadTempBlob {
    public static override $inject: readonly string[] = [];

    public constructor(
        id: string | null = null,
        filename: string | null = '',
        progress: number = 0,
        errorMessage: string | null = null,
        file: File | null = null,
        href: string | null = null,
        mimeType: string | null = null,
        size: number | null = null,
        public schemaVersion: number | null = null,
    ) {
        super(id, filename, progress, errorMessage, file, href, mimeType, size);
    }

    public static fromUploadTempBlob(blob: UploadTempBlob): TarifTemplate {
        return Object.assign(new TarifTemplate(), blob);
    }

    public static override apiResponseTransformer(data: any): TarifTemplate {
        const result = this.fromUploadTempBlob(super.apiResponseTransformer(data));
        result.schemaVersion = data.schemaVersion;

        return result;
    }
}
