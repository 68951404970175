/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxKindKontakt} from '@dv/shared/backend/model/jax-kind-kontakt';
import type {IPersistable, IRestModel} from '@dv/shared/code';

export abstract class AbstractKindKontakt implements IRestModel<JaxKindKontakt>, IPersistable {

    public id: string | null = null;
    public kontaktpersonId: string | null = null;
    public kindId: string | null = null;

    protected static baseTransformer<T extends AbstractKindKontakt>(data: JaxKindKontakt, answer: T): T {
        return Object.assign(answer, {
            id: data.id ?? null,
            kontaktpersonId: data.kontaktpersonId ?? null,
            kindId: data.kindId ?? null,
        });
    }

    public toRestObject(): JaxKindKontakt {
        return {
            id: this.id ?? undefined,
            kontaktpersonId: this.kontaktpersonId ?? undefined,
            kindId: this.kindId ?? undefined,
        };
    }
}
