/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export class SchulungsMandant {

    public constructor(
        public name: string | null = null,
        public password: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): SchulungsMandant {
        const answer = new SchulungsMandant();
        answer.name = data.name;
        answer.password = data.password;

        return answer;
    }
}
