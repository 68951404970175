/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Kind} from '../kind/Kind';
import {Kontaktperson} from '../kontakte/Kontaktperson';

export class Mitbewohner {

    public constructor(
        public kinder: Kind[] = [],
        public kontaktpersonen: Kontaktperson[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): Mitbewohner {
        const answer = new Mitbewohner();

        if (Array.isArray(data.kinder)) {
            answer.kinder = data.kinder.map((k: any) => Kind.apiResponseTransformer(k));
        }

        if (Array.isArray(data.kontaktpersonen)) {
            answer.kontaktpersonen = data.kontaktpersonen.map((k: any) => Kontaktperson.apiResponseTransformer(k));
        }

        return answer;
    }
}
