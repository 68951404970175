/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {DECIMAL_PCT_FACTOR, DvbDateUtil, DvbRestUtil, END_OF_TIME} from '@dv/shared/code';
import type moment from 'moment';

export abstract class AbstractLimitedPlaetze<T> implements IPersistable, ILimited, IRestModel {

    public id: string | null = null;
    public gueltigAb: moment.Moment | null = null;
    public gueltigBis: moment.Moment = END_OF_TIME;
    public plaetze: number | null = null;

    protected constructor(public type: T) {
    }

    protected static baseTransformer<T1, T2 extends AbstractLimitedPlaetze<T1>>(data: any, value: T2): T2 {
        value.id = data.id;
        value.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        value.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis)!;
        value.plaetze = parseFloat(data.prozentPunkteProWoche) / DECIMAL_PCT_FACTOR;

        return value;
    }

    public hasValidPlaetze(): boolean {
        return (this.plaetze ?? 0) >= 0;
    }

    public isValid(): boolean {
        return DvbDateUtil.isValidMoment(this.gueltigAb) && this.hasValidPlaetze();
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            prozentPunkteProWoche: this.plaetze! * DECIMAL_PCT_FACTOR,
        };
    }
}
