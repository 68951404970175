/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ApiResponseTransformer, ICustomField, ICustomFieldValue, IPersistable, IRestModel} from '@dv/shared/code';
import {checkPresent, CustomFieldUtil, DvbRestUtil, ParamValue} from '@dv/shared/code';

export class CustomFieldValueNotNamed implements IRestModel, IPersistable, ICustomFieldValue {

    public constructor(
        public id: string | null = null,
        public customField: ICustomField,
        public value: ParamValue = new ParamValue(null,
            CustomFieldUtil.customFieldTypeToParamValueType(checkPresent(customField.fieldType))),
    ) {
    }

    public static apiResponseTransformer<T extends ICustomField>(
        dataArray: any,
        customFieldResponseTransformer: ApiResponseTransformer<T>,
    ): CustomFieldValueNotNamed[] {
        return DvbRestUtil.transformArray(dataArray, {
            apiResponseTransformer: (data: any) => {
                const value = data.value ? ParamValue.apiResponseTransformer(data.value) : undefined;
                const customField = customFieldResponseTransformer.apiResponseTransformer(data.customField);

                return new CustomFieldValueNotNamed(data.id, customField, value);
            },
        });
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            customField: this.customField.toRestObject(),
            updateValue: this.value.toRestObject(),
        };
    }
}
