/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export enum ZeitraumFeldSelectionType {
    DEFAULT = 'default',
    /**
     * Extra Plaetze type additional
     */
    BORDER_GREEN = 'border-green',
    /**
     * Extra Plaetze type absence
     */
    BORDER_RED = 'border-red',
}
