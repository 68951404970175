/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {FakturaStatusPeriode} from './FakturaStatusPeriode';

export class FakturaStatuses {

    public constructor(public statuses: FakturaStatusPeriode[] = []) {
    }

    public static apiResponseTransformer(data: any): FakturaStatuses {
        const statuses = data.statuses.map((item: any) => FakturaStatusPeriode.apiResponseTransformer(item));

        return new FakturaStatuses(statuses);
    }
}
