/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel} from '@dv/shared/code';
import {EmailVersandProperties} from '../../email/EmailVersandProperties';

export class FinishRechnungsLauf implements IRestModel {

    public constructor(
            public rechnungsUebermittlungsEinheitIds: string[] = [],
            public emailVersandProperties: EmailVersandProperties | null = null,
            public rechnungsEmailVersandIds: string[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): FinishRechnungsLauf {
        const answer = new FinishRechnungsLauf();
        answer.rechnungsUebermittlungsEinheitIds = data.rechnungsUebermittlungsEinheitIds;
        answer.emailVersandProperties = EmailVersandProperties.apiResponseTransformer(data.emailVersandProperties);
        answer.rechnungsEmailVersandIds = data.rechnungsEmailVersandIds;

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            rechnungsUebermittlungsEinheitIds: this.rechnungsUebermittlungsEinheitIds,
            emailVersandProperties: this.emailVersandProperties?.toRestObject(),
            rechnungsEmailVersandIds: this.rechnungsEmailVersandIds,
        };
    }
}
