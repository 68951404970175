/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IEntityList, IPersistable, IRestModel, ISearchable, IValidable} from '@dv/shared/code';
import {checkPresent, SearchResultEntry} from '@dv/shared/code';
import angular from 'angular';
import {Translation} from '../i18n/Translation';
import {FirmenKontakt} from './FirmenKontakt';

export class Firma implements IPersistable, IRestModel, IEntityList, ISearchable, IValidable {

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public kontakt: FirmenKontakt | null = null,
        public symbol: string | null = null,
        public firmenPlatz: boolean = true,
        public alternativeKibonAbzug: number | null = null,
        public bsvRelevant: boolean = false,
        public externalAnmeldung: boolean = false,
        public externalAnmeldungName: Translation | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Firma {
        return new Firma(
            data.id,
            data.name,
            data.kontakt ? FirmenKontakt.apiResponseTransformer(data.kontakt) : null,
            data.symbol,
            data.firmenPlatz,
            data.alternativeKibonAbzug,
            data.bsvRelevant,
            data.externalAnmeldung,
            data.externalAnmeldungName ?
                Translation.apiResponseTransformer(data.externalAnmeldungName) :
                new Translation());
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            kontakt: this.kontakt ? this.kontakt.toRestObject() : null,
            symbol: this.symbol,
            firmenPlatz: this.firmenPlatz,
            alternativeKibonAbzug: this.alternativeKibonAbzug,
            bsvRelevant: this.bsvRelevant,
            externalAnmeldung: this.externalAnmeldung,
            externalAnmeldungName: this.externalAnmeldungName?.toRestObject(),
        };
    }

    public toSearchResultEntry(): SearchResultEntry {
        return new SearchResultEntry('KITA', this.id!, this.getDisplayName());
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public badgeText(): string | undefined {
        return undefined;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public isValid(): boolean {
        return this.hasName() && (this.hasValidKontakt() || !this.firmenPlatz) && this.hasSymbol();
    }

    public hasName(): boolean {
        return angular.isString(this.name) && this.name.length > 0;
    }

    public hasValidKontakt(): boolean {
        return angular.isObject(this.kontakt) && this.kontakt.isValid();
    }

    public hasSymbol(): boolean {
        return angular.isString(this.symbol) && this.symbol.length === 1;
    }

    public getDisplayName(): string {
        return this.name ?? '';
    }
}
