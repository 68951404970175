/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export class WochenPlaetze {

    public constructor(
        public plaetzeProzent: number | null = null,
        public maxPlaetzeProzent: number | null = null,
        public belegungProzent: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): WochenPlaetze {
        return new WochenPlaetze(
            parseFloat(data.plaetzeProzent),
            parseFloat(data.maxPlaetzeProzent),
            parseFloat(data.belegungProzent));
    }
}
