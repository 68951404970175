/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable} from '@dv/shared/code';
import {DvbRestUtil, LeistungsrechnungStatus} from '@dv/shared/code';
import type moment from 'moment';
import {DisplayTask} from '../tasks/DisplayTask';
import {EingewoehnungPosition} from './EingewoehnungPosition';
import {FixPosition} from './FixPosition';
import type {LeistungsrechnungWarning} from './LeistungsrechnungWarning';
import {TarifrechnungResult} from './TarifrechnungResult';

export class Leistungsrechnung implements IPersistable, ILimited {

    public constructor(
        public id: string | null = null,
        public kindId: string | null = null,
        public kitaId: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public summeResults: number | null = null,
        public status: LeistungsrechnungStatus | null = null,
        public tarifrechnungResults: TarifrechnungResult[] = [],
        public fixPositionen: FixPosition[] = [],
        public eingewoehnungPosition: EingewoehnungPosition | null = null,
        public tasks: DisplayTask[] = [],
        public warnings: LeistungsrechnungWarning[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): Leistungsrechnung {
        const answer = new Leistungsrechnung();

        answer.id = data.id;
        answer.kitaId = data.kitaId;
        answer.kindId = data.kindId;
        answer.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        answer.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis);

        answer.summeResults = parseFloat(data.summeResults);
        answer.status = data.status;

        if (Array.isArray(data.tarifrechnungResults)) {
            answer.tarifrechnungResults = data.tarifrechnungResults
                .map((r: any) => TarifrechnungResult.apiResponseTransformer(r));
        }

        if (Array.isArray(data.fixPositionen)) {
            answer.fixPositionen = data.fixPositionen
                .map((f: any) => FixPosition.apiResponseTransformer(f));
        }

        if (data.eingewoehnungPosition !== null) {
            answer.eingewoehnungPosition = EingewoehnungPosition.apiResponseTransformer(data.eingewoehnungPosition);
        }

        if (Array.isArray(data.tasks)) {
            answer.tasks = data.tasks.map((t: any) => DisplayTask.apiResponseTransformer(t));
        }

        if (Array.isArray(data.warnings)) {
            answer.warnings = data.warnings;
        }

        return answer;
    }

    public isVerrechenbar(): boolean {
        return this.tasks.length === 0;
    }

    public isDisplayable(): boolean {
        return this.hasResults()
            || this.tasks.length > 0
            || this.status === LeistungsrechnungStatus.RECHNUNGSDIFFERENZ
            || this.status === LeistungsrechnungStatus.VERRECHNET;
    }

    public hasResults(): boolean {
        return this.tarifrechnungResults.length > 0 ||
            this.fixPositionen.length > 0 ||
            this.eingewoehnungPosition !== null;
    }
}
