/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Zeiteinheit} from '@dv/shared/backend/model/zeiteinheit';
import type {ILimited, IPersistable, IRestModel, IValidable} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, DvbUtil, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

export class BetreuungsGutschein implements ILimited, IPersistable, IRestModel, IValidable {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public bgPensumProzent: number | null = null,
        public verguenstigterBetrag: number | null = null,
        public vollkosten: number | null = null,
        public minimalerElternbeitrag: number | null = null,
        public ausgestellterGutschein: number | null = null,
        public anspruchProzent: number | null = null,
        public kitaId: string | null = null,
        public bemerkung: string | null = null,
        public anspruchsberechtigteAnzahlZeiteinheiten: number | null = null,
        public verfuegteAnzahlZeiteinheiten: number | null = null,
        public zeiteinheit: Zeiteinheit = Zeiteinheit.PERCENTAGE,
    ) {
    }

    public static apiResponseTransformer(data: any): BetreuungsGutschein {
        const answer = new BetreuungsGutschein();
        answer.id = data.id;
        answer.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        answer.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis);
        answer.bgPensumProzent = parseFloat(data.bgPensumProzent);
        answer.verguenstigterBetrag = parseFloat(data.verguenstigterBetrag);
        answer.vollkosten = parseFloat(data.vollkosten);
        answer.bemerkung = data.bemerkung;
        answer.kitaId = data.kitaId;
        answer.zeiteinheit = data.zeiteinheit;

        if (angular.isNumber(data.minimalerElternbeitrag)) {
            answer.minimalerElternbeitrag = parseFloat(data.minimalerElternbeitrag);
        }

        if (angular.isNumber(data.ausgestellterGutschein)) {
            answer.ausgestellterGutschein = parseFloat(data.ausgestellterGutschein);
        }

        if (angular.isNumber(data.anspruchProzent)) {
            answer.anspruchProzent = parseInt(data.anspruchProzent, 10);
        }

        if (angular.isNumber(data.anspruchsberechtigteAnzahlZeiteinheiten)) {
            answer.anspruchsberechtigteAnzahlZeiteinheiten = data.anspruchsberechtigteAnzahlZeiteinheiten;
        }

        if (angular.isNumber(data.verfuegteAnzahlZeiteinheiten)) {
            answer.verfuegteAnzahlZeiteinheiten = data.verfuegteAnzahlZeiteinheiten;
        }

        return answer;
    }

    public static isValidBgPensumProzent(bgPensumProzent: number | null): boolean {
        return angular.isNumber(bgPensumProzent) && bgPensumProzent > 0 && Infinity > bgPensumProzent;
    }

    public isValid(): boolean {
        return isPresent(this.kitaId) &&
            DvbDateUtil.isValidMoment(this.gueltigAb) &&
            BetreuungsGutschein.isValidBgPensumProzent(this.bgPensumProzent) &&
            DvbUtil.isCHF(this.verguenstigterBetrag) &&
            DvbUtil.isCHF(this.vollkosten) &&
            this.verguenstigterBetrag > 0 &&
            this.vollkosten >= this.verguenstigterBetrag;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            kitaId: this.kitaId,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            bgPensumProzent: this.bgPensumProzent,
            verguenstigterBetrag: this.verguenstigterBetrag,
            // minimalerElternbeitrag is read-only
            // ausgestellterGutschein is read-only
            // anspruchProzent is read-only
            vollkosten: this.vollkosten,
            bemerkung: this.bemerkung,
        };
    }
}
