/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Adresse} from '@dv/shared/code';
import type {Translator} from '@dv/shared/translator';

/**
 * Only used to override the Adresses getDisplayGemeinde function.
 */
export class AdresseWithoutGemeinde extends Adresse {

    public static override apiResponseTransformer(data: any): AdresseWithoutGemeinde {
        const adresse = super.apiResponseTransformer(data);
        const adresseWithoutGemeinde = Object.assign(new AdresseWithoutGemeinde(), adresse);

        return adresseWithoutGemeinde;
    }

    public override getDisplayGemeinde(_translator: Translator): string {
        return '';
    }
}
