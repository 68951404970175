/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbRestUtil} from '@dv/shared/code';
import type {KinderOrtFraktion} from '../kinderort/KinderOrtFraktion';
import {KinderOrtFraktionTransformer} from '../kinderort/KinderOrtTransformer';
import {Belegung} from './Belegung';

export class BelegungenWithFraktionen {

    public constructor(
        public belegungen: Belegung[] = [],
        public fraktionen: KinderOrtFraktion[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): BelegungenWithFraktionen {

        return new BelegungenWithFraktionen(
            DvbRestUtil.transformArray(data.belegungen, Belegung),
            DvbRestUtil.transformArray(data.fraktionen, KinderOrtFraktionTransformer.create()));
    }
}
