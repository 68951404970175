/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Gebuehr} from './Gebuehr';
import {Rueckerstattung} from './Rueckerstattung';
import {Vorauszahlung} from './Vorauszahlung';

export function isRueckerstattung(value: unknown): value is Rueckerstattung {
    return value instanceof Rueckerstattung;
}

export function checkRueckerstattung(value: unknown): Rueckerstattung {
    if (isRueckerstattung(value)) {
        return value;
    }

    throw new Error(`Not a Rueckerstattung: ${JSON.stringify(value)}`);
}

export function isVorauszahlung(value: unknown): value is Vorauszahlung {
    return value instanceof Vorauszahlung;
}

export function checkVorauszahlung(value: unknown): Vorauszahlung {
    if (isVorauszahlung(value)) {
        return value;
    }

    throw new Error(`Not a Vorauszahlung: ${JSON.stringify(value)}`);
}

export function isGebuehr(value: unknown): value is Gebuehr {
    return value instanceof Gebuehr;
}

export function checkGebuehr(value: unknown): Gebuehr {
    if (isGebuehr(value)) {
        return value;
    }

    throw new Error(`Not a Gebuehr: ${JSON.stringify(value)}`);
}
