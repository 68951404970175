/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    IEntityList,
    IPersistable,
    IRestModel,
    ISearchable,
    IValidable,
    Persisted,
    SearchResultEntry,
} from '@dv/shared/code';
import {Adresse, checkPresent, DvbDateUtil, DvbUtil} from '@dv/shared/code';
import moment from 'moment';
import type {AnwesenheitsZeitConstraint} from '../belegung/monat/AnwesenheitsZeitConstraint';
import type {BetreuungsfaktorRegel} from '../betreuungsfaktor/BetreuungsfaktorRegel';
import type {Mandant} from '../mandant/Mandant';
import {ServiceContainer} from '../ServiceContainer';
import type {BewilligtePlaetze} from './BewilligtePlaetze';
import type {KinderOrtFraktion} from './KinderOrtFraktion';
import type {FirmenKontingent} from './kontingente/FirmenKontingent';
import type {Kontingente} from './kontingente/KontingentTransformer';
import type {SubventioniertesKontingent} from './kontingente/SubventioniertesKontingent';

export enum KinderOrtType {
    KITA = 'KITA',
    TAGES_ELTERN = 'TAGES_ELTERN',
}

export type KinderOrtId = string;

export abstract class KinderOrt implements IPersistable, IEntityList, ISearchable, IRestModel, IValidable {

    protected constructor(
        public dtype: KinderOrtType,
        public id: string | null = null,
        public titel: string | null = null,
        public name: string | null = null,
        public adresse: Adresse = new Adresse(),
        public email: string | null = null,
        public telefon: string | null = null,
        public website: string | null = null,
        public hasMonatsBlatt: boolean = false,
        public bewilligtePlaetze: BewilligtePlaetze[] = [],
        public subventioniertesKontingent: SubventioniertesKontingent | null = null,
        public firmenKontingente: FirmenKontingent[] = [],
        public gruppen: Persisted<KinderOrtFraktion>[] = [],
        public betreuungsfaktorRegeln: BetreuungsfaktorRegel[] = [],
        public mandant: Mandant | null = null,
        public maxDailyHours: number | null = null,
        public anwesenheitsZeitConstraints: AnwesenheitsZeitConstraint[] = [],
        public externalAccountingId: string | null = null,
        public rechnungsLaufLockedBefore: moment.Moment | null = null,
    ) {
    }

    public isValid(): boolean {
        return this.adresse.isValid();
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public badgeText(): string | undefined {
        return undefined;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public isBeendet(): boolean {
        const gueltigBis = this.getGueltigBis();

        return gueltigBis !== null && !DvbDateUtil.isEndOfTime(gueltigBis);
    }

    public getGueltigAb(): moment.Moment | null {
        if (this.bewilligtePlaetze.length === 0) {
            return null;
        }

        const ascOrderedBewilligtePlaetze = DvbDateUtil.sortLimitedEntitiesByGueltigAbAsc(this.bewilligtePlaetze);

        return moment(ascOrderedBewilligtePlaetze[0].gueltigAb);
    }

    public getGueltigBis(): moment.Moment | null {
        if (this.bewilligtePlaetze.length === 0) {
            return null;
        }

        const descOrderedBewilligtePlaetze = DvbDateUtil.sortLimitedEntitiesByGueltigAbDesc(this.bewilligtePlaetze);

        return moment(descOrderedBewilligtePlaetze[0].gueltigBis);
    }

    public getGruppenGueltigOn(stichtag: moment.Moment): Persisted<KinderOrtFraktion>[] {
        return DvbDateUtil.getEntitiesOn(this.gruppen, stichtag);
    }

    public getKontingente(): Kontingente[] {
        return this.subventioniertesKontingent ?
            (this.firmenKontingente as Kontingente[]).concat(this.subventioniertesKontingent) :
            this.firmenKontingente;
    }

    public getSubventioniertesKontingent(stichtag: moment.Moment): SubventioniertesKontingent | null {
        if (this.subventioniertesKontingent?.isGueltigOn(stichtag)) {
            return this.subventioniertesKontingent;
        }

        return null;
    }

    public getFirmenKontingente(stichtag: moment.Moment): FirmenKontingent[] {
        return this.firmenKontingente.filter(k => k.isGueltigOnWithKitaId(stichtag, checkPresent(this.id)));
    }

    public toRestObject(): Record<string, unknown> {
        return {
            dtype: this.dtype,
            id: this.id,
            titel: this.titel,
            name: this.name,

            adresse: this.adresse.toRestObject(),
            telefon: this.telefon,
            email: this.email,
            website: this.website,
            externalAccountingId: DvbUtil.isNotEmptyString(this.externalAccountingId?.trim()) ?
                this.externalAccountingId?.trim() :
                null,
        };
    }

    public getDisplayName(): string {
        return ServiceContainer.displayNameService.displayKinderOrtName(this.titel, this.name);
    }

    public abstract toSearchResultEntry(): SearchResultEntry;
}
