/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxSmallInvoiceBankKonto} from '@dv/shared/backend/model/jax-small-invoice-bank-konto';

export class SmallInvoiceBankKonto {

    public constructor(
        public id: number | null = null,
        public title: string | null = null,
        public institute: string | null = null,
        public number: string | null = null,
        public iban: string | null = null,
        public swiftbic: string | null = null,
        public postAccount: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxSmallInvoiceBankKonto): SmallInvoiceBankKonto {
        const answer = new SmallInvoiceBankKonto(
            data.id,
            data.title,
            data.institute,
            data.number,
            data.iban,
            data.swiftbic,
            data.postAccount,
        );

        return answer;
    }
}
