/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted} from '@dv/shared/code';
import {Adresse, checkPersisted, DvbRestUtil, DvbUtil, isPresent} from '@dv/shared/code';
import {Kind} from '../kind/Kind';
import {Kontaktperson} from '../kontakte/Kontaktperson';

export class AnmeldungAdresseHousehold {

    public constructor(
        public adresse: Persisted<Adresse>,
        public matchingKind: Persisted<Kind> | null = null,
        public matchingGesuchsteller: { [key: string]: Persisted<Kontaktperson> } = {},
        public relatedKinder: Persisted<Kind>[] = [],
        public kontakteAtAdresse: Persisted<Kontaktperson>[] = [],
        public kontakteAtOtherAdresse: Persisted<Kontaktperson>[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): AnmeldungAdresseHousehold {
        const kind = data.matchingKind ? Kind.apiResponseTransformer(data.matchingKind) : null;
        const adresse: Persisted<Adresse> = checkPersisted(Adresse.apiResponseTransformer(data.adresse));

        const gesuchsteller: { [key: string]: Persisted<Kontaktperson> } = {};
        if (data.matchingGesuchsteller) {
            Object.entries(data.matchingGesuchsteller).forEach(([key, value]) => {
                gesuchsteller[key] = Kontaktperson.apiResponseTransformer(value);
            });
        }

        const kinder = DvbRestUtil.transformArray(data.relatedKinder, Kind);
        const kontakteAtAdresse = DvbRestUtil.transformArray(data.kontakteAtAdresse, Kontaktperson);
        const kontakteAtOtherAdresse = DvbRestUtil.transformArray(data.kontakteAtOtherAdresse, Kontaktperson);

        const gesuchstellerList = Object.values(gesuchsteller);
        const gesuchstellerAtAdresse: Persisted<Kontaktperson>[] = [];
        const gesuchstellerAtOtherAdresse: Persisted<Kontaktperson>[] = [];

        gesuchstellerList.forEach(g =>
            adresse.equals(g.getAdresseOn()?.adresse) ?
                gesuchstellerAtAdresse.push(g) :
                gesuchstellerAtOtherAdresse.push(g));

        return new AnmeldungAdresseHousehold(adresse,
            kind,
            gesuchsteller,
            kinder,
            [...gesuchstellerAtAdresse, ...kontakteAtAdresse],
            [...gesuchstellerAtOtherAdresse, ...kontakteAtOtherAdresse]);
    }

    public hasMatch(): boolean {
        return isPresent(this.matchingKind)
            || Object.values(this.matchingGesuchsteller).some(isPresent);
    }

    public familienNamen(): string {
        const namen = this.kontakteAtAdresse.map(k => k.familienName)
            .concat(this.kontakteAtOtherAdresse.map(k => k.familienName));

        return DvbUtil.uniqueArray(namen)
            .join(', ');
    }
}
