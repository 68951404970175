/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {RechnungsLaufKontaktpersonEntry} from './RechnungsLaufKontaktpersonEntry';

export class RechnungsLaufEntry {

    public constructor(
        public verrechenbar: boolean = false,
        public rechnungsdifferenz: boolean = false,
        public rechnungsUebermittlungsEinheitId: string | null = null,
        public empfaenger: RechnungsLaufKontaktpersonEntry[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): RechnungsLaufEntry {
        const answer = new RechnungsLaufEntry();
        answer.verrechenbar = data.verrechenbar;
        answer.rechnungsdifferenz = data.rechnungsdifferenz;
        answer.rechnungsUebermittlungsEinheitId = data.rechnungsUebermittlungsEinheitId;

        if (Array.isArray(data.empfaenger)) {
            answer.empfaenger = data.empfaenger
                .map((g: any) => RechnungsLaufKontaktpersonEntry.apiResponseTransformer(g));
        }

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            verrechenbar: this.verrechenbar,
            rechnungsdifferenz: this.rechnungsdifferenz,
            rechnungsUebermittlungsEinheitId: this.rechnungsUebermittlungsEinheitId,
        };
    }

    public hasAbweichungToPrevious(): boolean {
        return this.hasAbweichungVormonat() || this.hasAbweichungRevisionsTotal();
    }

    public hasAbweichungVormonat(): boolean {
        return this.empfaenger.some(emp => emp.abweichungVormonat);
    }

    public hasAbweichungRevisionsTotal(): boolean {
        return this.empfaenger.some(emp => emp.abweichungRevisionsTotal);
    }
}
