/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel} from '@dv/shared/code';
import {StundenKontingent} from './StundenKontingent';

export class CalculatedStundenKontingent implements IRestModel {

    public constructor(
        public stundenKontingent: StundenKontingent,
        public verbuchteStunden: number,
    ) {
    }

    public static apiResponseTransformer(data: any): CalculatedStundenKontingent {
        return new CalculatedStundenKontingent(
            StundenKontingent.apiResponseTransformer(data.stundenKontingent),
            data.verbuchteStunden);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            stundenKontingent: this.stundenKontingent.toRestObject(),
            verbuchteStunden: this.verbuchteStunden,
        };
    }
}
