/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export enum LeistungsrechnungWarning {
    BG_PENSUM_MISMATCH = 'BG_PENSUM_MISMATCH',
    PENDENTE_MUTATIONS_MELDUNG = 'PENDENTE_MUTATIONS_MELDUNG',
    PENDENTE_VERFUEGUNG_MELDUNG = 'PENDENTE_VERFUEGUNG_MELDUNG',
}

export function isDeletableWarning(warning: LeistungsrechnungWarning): boolean {
    return warning === LeistungsrechnungWarning.PENDENTE_VERFUEGUNG_MELDUNG;
}
