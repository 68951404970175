/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable, IPersistable, IRestModel} from '@dv/shared/code';
import {Adresse, DvbRestUtil, DvbUtil, Geschlecht, presentCustomFieldValues} from '@dv/shared/code';
import type moment from 'moment';
import {LimitedAdresse} from '../adressen/LimitedAdresse';
import {BelegteEinheit} from '../belegung/BelegteEinheit';
import {Pensum} from '../belegung/Pensum';
import {CustomFieldValueNotNamed} from '../customfield/CustomFieldValueNotNamed';
import {Firma} from '../firma/Firma';
import {IsoLanguagesAndCountriesService} from '../isoLanguagesAndCountriesService';
import {AnmeldungKind} from '../kind/AnmeldungKind';
import {ExternalHousehold} from '../kind/ExternalHousehold';
import {Kind} from '../kind/Kind';
import type {KinderOrt} from '../kinderort/KinderOrt';
import {KinderOrtTransformer} from '../kinderort/KinderOrtTransformer';
import type {Wochenplan} from '../kinderort/Wochenplan';
import type {Language} from '../locale/Language';
import {ServiceContainer} from '../ServiceContainer';
import {ExternalAnmeldungCustomField} from './ExternalAnmeldungCustomField';
import {ExternalKontaktperson} from './ExternalKontaktperson';
import type {IExternalAnmeldung} from './IExternalAnmeldung';

export class ExternalAnmeldung implements IPersistable, IDisplayable, IRestModel, IExternalAnmeldung {

    public adressWieHauptkontakt: boolean = true;

    public constructor(
        public id: string | null = null,
        public familienName: string = '',
        public vorName: string | null = null,
        public geburtsTag: moment.Moment | null = null,
        public geburtsTermin: moment.Moment | null = null,
        public geschlecht: Geschlecht = Geschlecht.UNBEKANNT,
        public muttersprache: Language | null = null,
        public adresse: Adresse = new Adresse(),
        public bemerkung: string = '',
        public kinderOrte: KinderOrt[] = [],
        public pensum: Pensum = new Pensum(),
        public subventionierterPlatz: boolean = false,
        public privaterPlatz: boolean = false,
        public externalKontaktpersonen: ExternalKontaktperson[] = [],
        public gewuenschteBetreuungAb: moment.Moment | null = null,
        public belegteEinheiten: BelegteEinheit[] = [],
        public wochenplan: Wochenplan | null = null,
        public platzarten: Firma[] = [],
        public platzartIds: string[] = [],
        public customFieldValues: CustomFieldValueNotNamed[] = [],
        public anmeldeDatum: moment.Moment | null = null,
        public assignedKindId: string | null = null,
        public agbAccepted: boolean = false,
        public assignedKind: Kind | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): ExternalAnmeldung {
        const result = new ExternalAnmeldung();
        result.id = data.id;
        result.familienName = data.familienName;
        result.vorName = data.vorName;
        result.geburtsTag = DvbRestUtil.localDateToMoment(data.geburtsTag);
        result.geburtsTermin = DvbRestUtil.localDateToMoment(data.geburtsTermin);
        result.geschlecht = data.geschlecht;
        result.bemerkung = data.bemerkung;
        result.pensum.von = data.pensumVonPct;
        result.pensum.bis = data.pensumBisPct;
        result.subventionierterPlatz = !!data.subventionierterPlatz;
        result.privaterPlatz = !!data.privaterPlatz;
        result.gewuenschteBetreuungAb = DvbRestUtil.localDateToMoment(data.gewuenschteBetreuungAb);
        result.anmeldeDatum = DvbRestUtil.localDateToMoment(data.anmeldeDatum);
        result.assignedKindId = data.assignedKindId;

        if (data.assignedKind) {
            result.assignedKind = Kind.apiResponseTransformer(data.assignedKind);
        }

        if (data.muttersprache) {
            IsoLanguagesAndCountriesService.getLanguage(data.muttersprache).then(sprache => {
                result.muttersprache = sprache;
            });
        }

        if (data.adresse) {
            result.adressWieHauptkontakt = false;
            result.adresse = Adresse.apiResponseTransformer(data.adresse);
        }

        result.kinderOrte = DvbRestUtil.transformArray(data.kinderOrte, KinderOrtTransformer.create());

        result.externalKontaktpersonen =
            DvbRestUtil.transformArray(data.externalKontaktpersonen, ExternalKontaktperson);

        result.belegteEinheiten = DvbRestUtil.transformArray(data.belegteEinheiten, BelegteEinheit);

        result.platzarten = DvbRestUtil.transformArray(data.platzarten, Firma);
        result.platzartIds = result.platzarten.map(DvbUtil.mapToIdChecked);

        result.customFieldValues =
            CustomFieldValueNotNamed.apiResponseTransformer(data.customFieldValues, ExternalAnmeldungCustomField);

        return result;
    }

    public static initializeKind(kind: Kind, externalAnmeldung: ExternalAnmeldung): void {
        kind.familienName = externalAnmeldung.familienName;
        kind.vorName = externalAnmeldung.vorName ?? '';
        kind.geburtsTag = externalAnmeldung.geburtsTag;
        kind.geburtsTermin = externalAnmeldung.geburtsTermin;
        kind.geschlecht = externalAnmeldung.geschlecht;
        kind.muttersprache = externalAnmeldung.muttersprache;
        kind.bemerkung = externalAnmeldung.bemerkung;
        kind.anmeldeDatum = externalAnmeldung.anmeldeDatum;

        if (!externalAnmeldung.adressWieHauptkontakt) {
            const limitedAdresse = new LimitedAdresse(null, null, null, externalAnmeldung.adresse);
            kind.adressen.push(limitedAdresse);
        }
    }

    public toExternalHousehold(): ExternalHousehold {
        const anmeldungKind = this.toAnmeldungKind();
        const gesuchsteller = this.externalKontaktpersonen.map(k => k.toAnmeldungKontaktperson());

        return new ExternalHousehold(anmeldungKind, gesuchsteller);
    }

    public getAssignedKind(): Kind | null {
        return this.assignedKind;
    }

    public canImport(): boolean {
        return DvbUtil.isEmptyString(this.assignedKindId);
    }

    public toAnmeldungKind(): AnmeldungKind {
        return new AnmeldungKind(
            this.vorName ?? '---',
            this.familienName,
            this.geburtsTag ?? this.geburtsTermin,
            this.geschlecht,
            this.customFieldValues.slice());
    }

    public getDisplayName(): string {
        return ServiceContainer.displayNameService.displayKindName(this.familienName, this.vorName);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            familienName: this.familienName,
            vorName: this.vorName,
            geburtsTag: DvbRestUtil.momentToLocalDate(this.geburtsTag),
            geburtsTermin: DvbRestUtil.momentToLocalDate(this.geburtsTermin),
            geschlecht: this.geschlecht,
            muttersprache: this.muttersprache ? this.muttersprache.toRestObject() : null,
            adresse: this.adressWieHauptkontakt ? null : this.adresse.toRestObject(),
            bemerkung: this.bemerkung,
            kinderOrtIds: this.kinderOrte.map(k => k.id),
            pensumVonPct: this.pensum.von,
            pensumBisPct: this.pensum.bis,
            subventionierterPlatz: this.subventionierterPlatz,
            privaterPlatz: this.privaterPlatz,
            externalKontaktpersonen: this.externalKontaktpersonen.map(ek => ek.toRestObject()),
            gewuenschteBetreuungAb: DvbRestUtil.momentToLocalDate(this.gewuenschteBetreuungAb),
            belegteEinheiten: this.belegteEinheiten.map(b => b.toRestObject()),
            wochenplanId: this.wochenplan?.id,
            platzartIds: this.platzartIds,
            customFieldValues: presentCustomFieldValues(this.customFieldValues).map(val => val.toRestObject()),
        };
    }

    public getAnmeldeDatum(): moment.Moment | null {
        return this.anmeldeDatum;
    }
}
