/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxRelationshipWithKontaktperson} from '@dv/shared/backend/model/jax-relationship-with-kontaktperson';
import {KindKontaktRelationship} from './KindKontaktRelationship';
import {Kontaktperson} from './Kontaktperson';

export class RelationshipWithKontaktperson {

    public constructor(
        public kontaktperson: Kontaktperson | null = null,
        public kontaktpersonId: string | null = null,
        public relationship: KindKontaktRelationship = new KindKontaktRelationship(),
    ) {
    }

    public static apiResponseTransformer(data: JaxRelationshipWithKontaktperson): RelationshipWithKontaktperson {
        const answer = new RelationshipWithKontaktperson();
        if (data.kontaktperson) {
            answer.kontaktperson = Kontaktperson.apiResponseTransformer(data.kontaktperson);
            answer.kontaktpersonId = answer.kontaktperson.id;
        } else {
            answer.kontaktperson = null;
        }
        if (data.kontaktpersonId) {
            answer.kontaktpersonId = data.kontaktpersonId;
        }
        if (data.relationship) {
            answer.relationship = KindKontaktRelationship.apiResponseTransformer(data.relationship);
        }

        return answer;
    }

    public isNewKontaktperson(): boolean {
        return this.kontaktpersonId === null;
    }

    public hasAdresse(): boolean {
        return !!this.kontaktperson && this.kontaktperson.adressen.length > 0;
    }

    public toRestObject(): JaxRelationshipWithKontaktperson {
        const base = {
            relationship: this.relationship?.toRestObject(),
            kontaktpersonId: this.kontaktpersonId ?? undefined,
        };

        if (!this.kontaktperson) {
            return base;
        }

        if (this.isNewKontaktperson()) {
            const withKontaktperson = Object.assign(base, {kontaktperson: this.kontaktperson.toRestObject()});

            // Adresse des Kontaktes mitschicken
            if (this.kontaktperson.adressen.length === 1) {

                if (!this.kontaktperson.adressen[0].toRestObject) {

                    // Related to https://sentry.dvbern.ch/dvbern/frontend/issues/3846/
                    // Used to further analyze the issue. Can be removed once fixed

                    throw new Error(`toRestObject not a function: ${JSON.stringify(this.kontaktperson)}`);
                }

                withKontaktperson.kontaktperson.adressen = [this.kontaktperson.adressen[0].toRestObject()];
            }

            return withKontaktperson;
        }

        // Kontaktperson nur mitschicken, wenn sie noch nicht existiert (noch keine ID hat)
        base.kontaktpersonId = this.kontaktperson.id ?? undefined;

        return base;
    }
}
