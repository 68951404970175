/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsAngebotType} from '@dv/shared/backend/model/betreuungs-angebot-type';
import {SearchResultEntry} from '@dv/shared/code';
import angular from 'angular';
import {KinderOrtFraktion, KinderOrtFraktionType} from '../KinderOrtFraktion';

export class Gruppe extends KinderOrtFraktion {
    public static override $inject: readonly string[] = [];

    public name: string | null = null;
    public betreuungsAngebotType: BetreuungsAngebotType | null = null;

    public constructor() {
        super(KinderOrtFraktionType.GRUPPE);
    }

    public toSearchResultEntry(): SearchResultEntry {
        return new SearchResultEntry('GRUPPE', this.entityId(), this.getDisplayName());
    }

    public override toRestObject(): { dtype: string; id: string | null; attributes: Record<string, unknown> } {
        const data = super.toRestObject();
        data.attributes.name = this.name;
        data.attributes.betreuungsAngebotType = this.betreuungsAngebotType;

        return data;
    }

    public hasName(): boolean {
        return angular.isString(this.name) && this.name.length > 0;
    }

    public isValid(): boolean {
        return this.hasName();
    }

    public getDisplayName(): string {
        return this.name ?? '';
    }
}
