/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AbstractKontingent, KontingentType} from './AbstractKontingent';
import {SubventioniertesKontingentValue} from './SubventioniertesKontingentValue';

export class SubventioniertesKontingent
    extends AbstractKontingent<KontingentType.SUBVENTIONIERT, SubventioniertesKontingentValue> {

    public static override $inject: readonly string[] = [];

    public constructor(public kitaId: string | null = null) {
        super(KontingentType.SUBVENTIONIERT);
    }

    public static apiResponseTransformer(data: any): SubventioniertesKontingent {
        return super.baseTransformer(
            data,
            new SubventioniertesKontingent(data.kitaId),
            SubventioniertesKontingentValue);
    }

    public override toRestObject(): Record<string, unknown> {
        return {
            ...super.toRestObject(),
            kitaId: this.kitaId,
        };
    }
}
