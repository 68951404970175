/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitadminModul} from '@dv/shared/backend/model/kitadmin-modul';
import type {IPersistable, SubscriptionModel} from '@dv/shared/code';
import {Mandant} from './Mandant';
import {MandantCustomPosition} from './MandantCustomPosition';
import {MandantRechnungsPosition} from './MandantRechnungsPosition';

export class MandantRechnung implements IPersistable {

    public constructor(
        public id: string | null = null,
        public mandant: Mandant | null = null,
        public subscriptionModel: SubscriptionModel | null = null,
        public rechnungsPositionen: MandantRechnungsPosition[] = [],
        public customPositionen: MandantCustomPosition[] = [],
        public modules: KitadminModul[] = [],
        public summe: number | null = null,
        public kitaCount: number | null = null,
        public tageselternCount: number | null = null,
        public totalKitaCount: number | null = null,
        public totalTageselternCount: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): MandantRechnung {
        const answer = new MandantRechnung();

        answer.id = data.id;
        answer.subscriptionModel = data.subscriptionModel;
        answer.summe = parseFloat(data.summe);
        answer.kitaCount = parseFloat(data.kitaCount);
        answer.tageselternCount = parseFloat(data.tageselternCount);
        answer.totalKitaCount = parseFloat(data.totalKitaCount);
        answer.totalTageselternCount = parseFloat(data.totalTageselternCount);

        if (data.mandant) {
            answer.mandant = Mandant.apiResponseTransformer(data.mandant);
        }

        if (Array.isArray(data.rechnungsPositionen)) {
            answer.rechnungsPositionen = data.rechnungsPositionen.map(
                (rechnungsPosition: any) => MandantRechnungsPosition.apiResponseTransformer(rechnungsPosition));
        }

        if (Array.isArray(data.customPositionen)) {
            answer.customPositionen = data.customPositionen.map(
                (customPosition: any) => MandantCustomPosition.apiResponseTransformer(customPosition));
        }

        if (Array.isArray(data.modules)) {
            answer.modules = data.modules.map((module: any) => module);
        }

        return answer;
    }
}
