/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtType} from '../kinderort/KinderOrt';

export class MandantRechnungsPosition {

    public constructor(
        public id: string | null = null,
        public type: KinderOrtType | null = null,
        public count: number | null = null,
        public cost: number | null = null,
        public text: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): MandantRechnungsPosition {
        const answer = new MandantRechnungsPosition();

        answer.id = data.id;
        answer.type = data.type;
        answer.count = parseFloat(data.count);
        answer.cost = parseFloat(data.cost);
        answer.text = data.text;

        return answer;
    }
}
