/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsZeitraum, DayOfWeek, Persisted} from '@dv/shared/code';
import type {Wochenplan} from '../kinderort/Wochenplan';
import {ZeitraumUtil} from '../zeitraumUtil';
import type {BewerbungsplanError} from './BewerbungsplanError';
import type {ZeitraumFeld} from './ZeitraumFeld';

export class WochenplanBewerbung {

    public readonly enabledDays: DayOfWeek[] = [];
    public readonly distinctZeitraeume: BetreuungsZeitraum[] = [];
    public readonly zeitraumFelder: ZeitraumFeld[] = [];
    public hasInvalidBelegteEinheit = false;
    public invalidDay: DayOfWeek | null = null;

    public constructor(
        public readonly wochenplan: Persisted<Wochenplan>,
    ) {
        this.enabledDays = ZeitraumUtil.getWeekDaysFromWochenplan(wochenplan);
        this.distinctZeitraeume = wochenplan.distinctZeitraeume;
        this.zeitraumFelder = ZeitraumUtil.createZeitraumFelderWithWochenplan(wochenplan);
    }

    public toggleZeitraumFeld(zeitraumFeld: ZeitraumFeld): void {
        if (this.hasInvalidBelegteEinheit && zeitraumFeld.dayOfWeek !== this.invalidDay) {
            // Disable modification of other days
            zeitraumFeld.selected = !zeitraumFeld.selected;
        } else {
            this.clearBelegteEinheitenErrors(zeitraumFeld.dayOfWeek);
        }
    }

    public addBelegteEinheitenError(error: BewerbungsplanError): void {
        this.hasInvalidBelegteEinheit = true;
        this.invalidDay = error.args.dayOfWeek;
        this.toggleZeitraumFelderError(true, error.args.dayOfWeek);
    }

    public clearBelegteEinheitenErrors(dayOfWeek: DayOfWeek): void {
        this.hasInvalidBelegteEinheit = false;
        this.invalidDay = null;
        this.toggleZeitraumFelderError(false, dayOfWeek);
    }

    private toggleZeitraumFelderError(hasError: boolean, dayOfWeek: DayOfWeek): void {
        this.zeitraumFelder.filter(feld => feld.dayOfWeek === dayOfWeek)
            .forEach(zf => {
                zf.hasError = hasError;
            });
    }
}
