/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type {Kontingente} from '../kinderort/kontingente/KontingentTransformer';
import {PensumType} from './PensumType';
import type {PlatzTyp} from './Platz';

export class VertraglichesPensum implements IRestModel, IPersistable {

    public constructor(
        public id: string | null = null,
        public gruppenBelegungId: string | null = null,
        public kontingentId: string | null = null,
        public value: number | null = null,
        public type: PensumType = PensumType.HOURS,
    ) {
    }

    public static from(other: VertraglichesPensum): VertraglichesPensum {
        return new VertraglichesPensum(null, null, other.kontingentId, other.value, other.type);
    }

    public static createComparator(kontingente: Kontingente[]): (
        a: VertraglichesPensum,
        b: VertraglichesPensum,
    ) => number {
        const sortKeys = VertraglichesPensum.getSortKeys(kontingente);

        return (a, b): number =>
            checkPresent(sortKeys.get(a.kontingentId)).localeCompare(checkPresent(sortKeys.get(b.kontingentId)));
    }

    public static apiResponseTransformer(data: any): VertraglichesPensum {
        return new VertraglichesPensum(
            data.id,
            data.gruppenBelegungId,
            data.kontingentId,
            data.value,
            data.type,
        );
    }

    private static getSortKeys(kontingente: Kontingente[]): Map<PlatzTyp, string> {
        const result = new Map<PlatzTyp, string>();

        kontingente.forEach(k => {
            result.set(k.id, k.isSubventioniertesKontingent() ? '0' : `2${k.getDisplayName()}`);
        });

        result.set(null, '1');

        return result;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            gruppenBelegungId: this.gruppenBelegungId,
            kontingentId: this.kontingentId,
            value: this.value,
            type: this.type,
        };
    }
}
