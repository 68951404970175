/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {Translation} from '../i18n/Translation';
import {BetreuungsVereinbarungsKonfiguration} from './BetreuungsVereinbarungsKonfiguration';

export class BetreuungsVereinbarungsKonfigurationType implements IRestModel, IPersistable {

    public constructor(
        public id: string | null = null,
        public name: Translation = new Translation(),
        public konfigurationen: BetreuungsVereinbarungsKonfiguration[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): BetreuungsVereinbarungsKonfigurationType {

        const name = Translation.apiResponseTransformer(data.name);
        const result = new BetreuungsVereinbarungsKonfigurationType(data.id, name);

        if (Array.isArray(data.konfigurationen)) {
            result.konfigurationen = Object.values(data.konfigurationen)
                .map(value => BetreuungsVereinbarungsKonfiguration.apiResponseTransformer(value));
        }

        return result;
    }

    public toRestObject(): Record<string, unknown> {
        const konfigurationen = this.konfigurationen.map(v => v.toRestObject());

        return {
            id: this.id,
            name: this.name,
            konfigurationen,
        };
    }

    public isValid(): boolean {
        return this.name.hasTranslations();
    }
}
