/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable, IRestModel} from '@dv/shared/code';
import {MandantFeaturePermission} from './MandantFeaturePermission';

export class MandantFeaturePermissions implements IRestModel, IDisplayable {

    public constructor(
        public mandantId: string,
        public mandantName: string,
        public features: MandantFeaturePermission[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): MandantFeaturePermissions {
        return new MandantFeaturePermissions(
            data.mandantId,
            data.mandantName,
            data.features.map((f: any) => MandantFeaturePermission.apiResponseTransformer(f)),
        );
    }

    public getDisplayName(): string {
        return this.mandantName;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            mandantId: this.mandantId,
            mandantName: this.mandantName,
            features: this.features.map(f => f.toRestObject()),
        };
    }
}
