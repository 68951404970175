/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {UserRole} from '@dv/shared/roles';
import type {ApplicationRole} from '../benutzer/ApplicationRole';

/**
 * Order according to privilege weight. The higher the index in this array, the more privileged.
 */
const USER_ROLES_BY_WEIGHT: (UserRole | null)[] = [
    null,
    UserRole.USER,
    UserRole.ADMIN,
    UserRole.SUPPORT_ADMIN,
    UserRole.MANDANT_ADMIN,
    UserRole.SUPER_ADMIN,
];

export function getPrivilegedRoleWeight(role: ApplicationRole): number {
    return USER_ROLES_BY_WEIGHT.indexOf(role.userRole);
}
