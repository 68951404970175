/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek, IPersistable, IRestModel} from '@dv/shared/code';
import angular from 'angular';

export class Kapazitaet implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public dayOfWeek: DayOfWeek | null = null,
        public betreuungsZeitraumId: string | null = null,
        public maxPlaetze: number | null = null,
        public plaetze: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Kapazitaet {
        const answer = new Kapazitaet(data.id, data.dayOfWeek, data.betreuungsZeitraumId);

        if (angular.isNumber(data.maxPlaetze)) {
            answer.maxPlaetze = parseInt(data.maxPlaetze, 10);
        }

        if (angular.isNumber(data.plaetze)) {
            answer.plaetze = parseInt(data.plaetze, 10);
        }

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            maxPlaetze: this.maxPlaetze,
            plaetze: this.plaetze,
            betreuungsZeitraumId: this.betreuungsZeitraumId,
            dayOfWeek: this.dayOfWeek,
        };
    }

    /**
     * Value auf der entsprechenden Kapazitaet setzen. Wenn "isMax" soll das Attribut maxPlaetze gesetzt werden und
     * sonst plaetze. Wenn die Value keinen Wert hat ("") soll "null" gesetzt werden.
     */
    public setPlaetze(isMax: boolean, value: string | null): void {
        let parsedValue = value ? parseInt(value, 10) : null;
        parsedValue = angular.isNumber(parsedValue) && !isNaN(parsedValue) ? parsedValue : null;

        if (isMax) {
            this.maxPlaetze = parsedValue;
        } else {
            this.plaetze = parsedValue;
        }
    }

    public plaetzeToZeitraumFeldValue(): string {
        return this.plaetze === null ? '' : this.plaetze.toString();
    }

    public maxPlaetzeToZeitraumFeldValue(): string {
        return this.maxPlaetze === null ? '' : this.maxPlaetze.toString();
    }
}
