/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxKitaKonfigurationTexte} from '@dv/shared/backend/model/jax-kita-konfiguration-texte';
import type {IRestModel} from '@dv/shared/code';
import {Translation} from '../i18n/Translation';

export class KitaKonfigurationTexte implements IRestModel<JaxKitaKonfigurationTexte> {

    public constructor(
        public titel: string | null = null,
        public einfuehrung: Translation | null = null,
        public konditionen: Translation | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxKitaKonfigurationTexte): KitaKonfigurationTexte {
        const answer = new KitaKonfigurationTexte();

        answer.titel = data.titel ?? null;
        answer.einfuehrung =
            data.einfuehrung ? Translation.apiResponseTransformer(data.einfuehrung) : new Translation();
        answer.konditionen =
            data.konditionen ? Translation.apiResponseTransformer(data.konditionen) : new Translation();

        return answer;
    }

    public toRestObject(): JaxKitaKonfigurationTexte {
        return {
            titel: this.titel ?? undefined,
            einfuehrung: this.einfuehrung?.toRestObject(),
            konditionen: this.konditionen?.toRestObject(),
        };
    }

}
