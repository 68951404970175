/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AdresseContext} from '@dv/shared/backend/model/adresse-context';
import type {JaxContextualAdresse} from '@dv/shared/backend/model/jax-contextual-adresse';
import type {IRestModel} from '@dv/shared/code';
import {Adresse, checkPresent} from '@dv/shared/code';

export class ContextualAdresse implements IRestModel<JaxContextualAdresse> {

    public static readonly ADRESSE_CONTEXT: typeof AdresseContext = AdresseContext;

    public constructor(
        public context: AdresseContext | null = null,
        public adresseHeader: string = '',
        public adresseZusatz: string = '',
        public adresse: Adresse | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): ContextualAdresse {
        return new ContextualAdresse(
            data.context,
            data.adresseHeader,
            data.adresseZusatz,
            data.adresse && Adresse.apiResponseTransformer(data.adresse),
        );
    }

    public getTranslationKey(): string {
        const context = this.context;
        switch (context) {
            case AdresseContext.MANDANT:
                return 'COMMON.ADRESSE_MANDANT';
            case AdresseContext.KINDERORT:
                return 'COMMON.ADRESSE_KINDERORT';
            case AdresseContext.NONE:
                return 'COMMON.ADRESSE_NONE';
            case AdresseContext.CUSTOM:
                return 'COMMON.ADRESSE_CUSTOM';
            default:
                throw new Error(`No translation key defined for context ${context}`);
        }
    }

    public toRestObject(): JaxContextualAdresse {
        return {
            context: checkPresent(this.context),
            adresseHeader: this.adresseHeader,
            adresseZusatz: this.adresseZusatz,
            adresse: this.adresse?.toRestObject(),
        };
    }
}
