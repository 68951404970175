/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/**
 * The order is relevant. See authService.isRoleGreater()
 */
export type UserRole = 'SUPER_ADMIN' | 'MANDANT_ADMIN' | 'SUPPORT_ADMIN' | 'ADMIN' | 'USER';
// eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-redeclare
export const UserRole = {
    SUPER_ADMIN: 'SUPER_ADMIN' as UserRole,
    MANDANT_ADMIN: 'MANDANT_ADMIN' as UserRole,
    SUPPORT_ADMIN: 'SUPPORT_ADMIN' as UserRole,
    ADMIN: 'ADMIN' as UserRole,
    USER: 'USER' as UserRole,
} as const;
