/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {checkPresent} from '@dv/shared/code';
import {ServiceContainer} from '../../ServiceContainer';
import {KontingentType} from './AbstractKontingent';
import {FirmenKontingent} from './FirmenKontingent';
import {SubventioniertesKontingent} from './SubventioniertesKontingent';

export type Kontingente = FirmenKontingent | SubventioniertesKontingent;

export function isFirmenKontingent(kontingent: Kontingente): kontingent is FirmenKontingent {
    return kontingent.type === KontingentType.FIRMA;
}

export function isSubventioniertesKontingent(kontingent: Kontingente): kontingent is SubventioniertesKontingent {
    return kontingent.type === KontingentType.SUBVENTIONIERT;
}

export class KontingentTransformer {

    public static apiResponseTransformer(data: any): Kontingente {
        switch (data.type) {
            case KontingentType.FIRMA:
                return FirmenKontingent.apiResponseTransformer(data);
            case KontingentType.SUBVENTIONIERT:
                return SubventioniertesKontingent.apiResponseTransformer(data);
            default:
                throw new Error(`Kontingent type ${JSON.stringify(data.type)} unknown`);
        }
    }

    public static getKontingentName(kontingent: Kontingente | null): string {
        if (kontingent === null) {
            return ServiceContainer.$translate.instant('COMMON.PRIVATER_PLATZ');
        }

        if (isFirmenKontingent(kontingent)) {
            return kontingent.getDisplayName();
        }

        if (isSubventioniertesKontingent(kontingent)) {
            return ServiceContainer.$translate.instant('COMMON.SUBVENTIONIERTER_PLATZ');
        }

        throw new Error(`No name mapping defined for ${JSON.stringify(kontingent)}`);
    }

    public static getKontingentIcon(kontingent: Kontingente | null): string {
        if (kontingent === null) {
            return ServiceContainer.$translate.instant('COMMON.ZUWEISUNG_PRIVAT_ICON');
        }

        if (isFirmenKontingent(kontingent)) {
            return checkPresent(kontingent.firma).symbol!;
        }

        if (isSubventioniertesKontingent(kontingent)) {
            return ServiceContainer.$translate.instant('COMMON.ZUWEISUNG_SUBVENTIONIERT_ICON');
        }

        throw new Error(`No name mapping defined for ${JSON.stringify(kontingent)}`);
    }
}
