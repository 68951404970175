/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import type {VersandartRechnung} from '@dv/shared/backend/model/versandart-rechnung';
import type {IDisplayable, ILimited, IPersistable, Persisted} from '@dv/shared/code';
import {checkPersisted, DvbRestUtil, TypeUtil} from '@dv/shared/code';
import type moment from 'moment';
import {KontaktEmail} from '../email/KontaktEmail';
import {Kind} from '../kind/Kind';
import {Kontaktperson} from '../kontakte/Kontaktperson';
import {ServiceContainer} from '../ServiceContainer';
import {CustomRechnungsPosition} from './CustomRechnungsPosition';
import {KindRechnungsPosition} from './KindRechnungsPosition';
import {RechnungsStatus} from './status/RechnungsStatus';
import {StateHistory} from './status/StateHistory';

export enum InternalRechnungsStatus {
    ERFASST = 'ERFASST',
    UEBERTRAGEN = 'UEBERTRAGEN',
    STORNIERT = 'STORNIERT',
    UEBERTRAGUNGS_FEHLER = 'UEBERTRAGUNGS_FEHLER',
    STORNIERUNGS_FEHLER = 'STORNIERUNGS_FEHLER',
}

export type RechnungId = string;

export class Rechnung implements IPersistable, ILimited, IDisplayable {

    public static readonly STATUS: typeof InternalRechnungsStatus = InternalRechnungsStatus;

    public id: string | null = null;
    public dtype: RechnungsKonfigurationType | null = null;
    public rechnungsRevisionId: string | null = null;
    public rechnungsNummer: number | null = null;
    public revisionCount: number = 0;
    public bemerkung: string = '';

    public faelligAm: moment.Moment | null = null;
    public zahlungsFrist: moment.Moment | null = null;
    public rechnungsDatum: moment.Moment | null = null;
    public gueltigAb: moment.Moment | null = null;
    public gueltigBis: moment.Moment | null = null;

    public kitaId: string | null = null;
    public kitaTitel: string | null = null;
    public kitaName: string | null = null;

    public kontaktpersonDisplayName: string | null = null;
    public kontaktpersonEmail: string | null = null;
    public kontaktpersonId: string | null = null;
    public kontaktperson: Kontaktperson | null = null;

    public esrNummer: number | null = null;
    public esrGenerated: number | null = null;

    public isStornierbar: boolean = false;
    public hasExternal: boolean = false;

    public kinder: Kind[] = [];

    public kindPositionen: KindRechnungsPosition[] = [];
    public customPositionen: CustomRechnungsPosition[] = [];
    public summe: number = 0;
    public ausstehenderBetrag: number | null = null;

    public status: InternalRechnungsStatus | null = null;
    public externalStatus: Readonly<RechnungsStatus> | null = null;
    public stateTransitions: readonly RechnungsStatus[] = [];

    public rechnungsdifferenz: boolean = false;
    public rechnungsUeberschuss: boolean = false;
    public deliveryFailure: boolean = false;
    public disabledRechnungsKonfiguration: boolean = false;
    public isNewestRevision: boolean = false;

    public uebermittlungsTimestamp: moment.Moment | null = null;
    public stornierungsTimestamp: moment.Moment | null = null;

    public folgeRechnung: Rechnung | null = null;
    public vorgaengerRechnung: Rechnung | null = null;

    public stateHistory: StateHistory | null = null;
    public versandartRechnung: VersandartRechnung | null = null;
    public emails: KontaktEmail[] = [];

    public beforeRechnungsLaufLock: boolean | null = null;

    // noinspection FunctionTooLongJS
    public static apiResponseTransformer(data: any): Persisted<Rechnung> {
        const answer = new Rechnung();
        answer.id = data.id;
        answer.dtype = data.dtype;
        answer.rechnungsRevisionId = data.rechnungsRevisionId;
        answer.faelligAm = DvbRestUtil.localDateToMoment(data.faelligAm);
        answer.zahlungsFrist = DvbRestUtil.localDateToMoment(data.zahlungsFrist);
        answer.rechnungsDatum = DvbRestUtil.localDateToMoment(data.rechnungsDatum);
        answer.gueltigAb = DvbRestUtil.localDateToMoment(data.periode.gueltigAb);
        answer.gueltigBis = DvbRestUtil.localDateToMoment(data.periode.gueltigBis);
        answer.summe = data.summe;
        answer.kitaId = data.kitaId;
        answer.kitaTitel = data.kitaTitel;
        answer.kitaName = data.kitaName;
        answer.kontaktpersonDisplayName = data.kontaktpersonDisplayName;
        answer.kontaktpersonEmail = data.kontaktpersonEmail;
        answer.versandartRechnung = data.versandartRechnung;
        answer.rechnungsNummer = parseInt(data.rechnungsNummer, 10);
        answer.esrNummer = data.esrNummer ? parseInt(data.esrNummer, 10) : null;
        answer.esrGenerated = data.esrGenerated;
        answer.isStornierbar = data.isStornierbar;
        answer.hasExternal = data.hasExternal;
        answer.rechnungsdifferenz = data.rechnungsdifferenz;
        answer.rechnungsUeberschuss = data.rechnungsUeberschuss;
        answer.deliveryFailure = data.deliveryFailure;
        answer.disabledRechnungsKonfiguration = data.disabledRechnungsKonfiguration;
        answer.isNewestRevision = data.isNewestRevision;
        answer.kontaktpersonId = data.kontaktpersonId;
        answer.status = data.status;
        answer.revisionCount = data.revisionCount;
        answer.bemerkung = data.bemerkung;
        answer.beforeRechnungsLaufLock = data.beforeRechnungsLaufLock;

        Rechnung.convertIncluded(answer, data);

        return checkPersisted(answer);
    }

    // eslint-disable-next-line complexity
    private static convertIncluded(answer: Rechnung, data: any): void {
        if (data.folgeRechnung) {
            answer.folgeRechnung = Rechnung.apiResponseTransformer(data.folgeRechnung);
        }

        if (data.vorgaengerRechnung) {
            answer.vorgaengerRechnung = Rechnung.apiResponseTransformer(data.vorgaengerRechnung);
        }

        if (data.uebermittlungsTimestamp) {
            answer.uebermittlungsTimestamp = DvbRestUtil.localDateTimeToMoment(data.uebermittlungsTimestamp);
        }

        if (data.stornierungsTimestamp) {
            answer.stornierungsTimestamp = DvbRestUtil.localDateTimeToMoment(data.stornierungsTimestamp);
        }

        if (data.kontaktperson) {
            answer.kontaktperson = Kontaktperson.apiResponseTransformer(data.kontaktperson);
        }

        if (data.externalStatus) {
            answer.externalStatus = RechnungsStatus.apiResponseTransformer(data.externalStatus);
        }

        if (Array.isArray(data.stateTransitions)) {
            answer.stateTransitions = data.stateTransitions
                .map((state: any) => RechnungsStatus.apiResponseTransformer(state));
        }

        if (Array.isArray(data.kindPositionen)) {
            answer.kindPositionen = data.kindPositionen
                .map((p: any) => KindRechnungsPosition.apiResponseTransformer(p));
        }

        if (Array.isArray(data.customPositionen)) {
            answer.customPositionen = data.customPositionen
                .map((p: any) => CustomRechnungsPosition.apiResponseTransformer(p));
        }

        if (Array.isArray(data.kinder)) {
            answer.kinder = data.kinder.map((kind: any) => Kind.apiResponseTransformer(kind));
        }

        if (TypeUtil.isNumber(data.ausstehenderBetrag)) {
            answer.ausstehenderBetrag = parseFloat(data.ausstehenderBetrag);
        }

        if (data.stateHistory) {
            answer.stateHistory = StateHistory.apiResponseTransformer(data.stateHistory);
        }

        if (Array.isArray(data.emails)) {
            answer.emails = data.emails.map((entry: any) => KontaktEmail.apiResponseTransformer(entry));
        }
    }

    public getDisplayName(): string {
        return ServiceContainer.$translate.instant('FAKTURA.RECHNUNG_SEARCH_RESULT', {nummer: this.rechnungsNummer});
    }

    public getKitaDisplayName(): string {
        return [this.kitaTitel, this.kitaName].filter(Boolean).join(' ');
    }
}
