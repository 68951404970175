/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KindergartenBelegung} from '@dv/shared/code';
import {Firma} from '../firma/Firma';
import type {FirmenKontingent} from '../kinderort/kontingente/FirmenKontingent';
import type {Kontingente} from '../kinderort/kontingente/KontingentTransformer';
import type {SubventioniertesKontingent} from '../kinderort/kontingente/SubventioniertesKontingent';

/**
 * WARNING: This model has a dual-use: it's used to show the current PlatzKontingentType of a Platz, but also to show to
 * combination of all PlatzKontingentTypen of a Belgung (which can be any combination of privat, subventioniert &
 * FirmenKontingent).
 */
export class PlatzTypen {

    public constructor(
        public privat: boolean,
        public subventioniert: boolean,
        public kindergarten: KindergartenBelegung,
        public kontingentId: string | null,
        public firmen: Firma[] = [],
        public firmenKontingentIds: string[] = [],
    ) {
    }

    public static createPrivat(): PlatzTypen {
        return new PlatzTypen(true, false, KindergartenBelegung.KEINE, null, [], []);
    }

    public static fromKontingent(kontingent: Kontingente | null, firmen?: Firma[]): PlatzTypen {
        const platzTypen = new PlatzTypen(false, false, KindergartenBelegung.KEINE, null);
        platzTypen.setTypeByKontingent(kontingent, firmen);

        return platzTypen;
    }

    public setTypeByKontingent(kontingent: Kontingente | null, firmen?: Firma[]): void {
        if (!kontingent) {
            this.setPrivat();
        } else if (kontingent.isSubventioniertesKontingent()) {
            this.setSubventioniert(kontingent);
        } else if (kontingent.isFirmenKontingent()) {
            this.setFirma(kontingent, firmen);
        }
    }

    public from(other: PlatzTypen): void {
        Object.assign(this, other);
    }

    public setPrivat(): void {
        this.firmen = [];
        this.subventioniert = false;
        this.kontingentId = null;

        this.privat = true;
    }

    public setSubventioniert(subventioniertesKontingent: SubventioniertesKontingent): void {
        this.privat = false;
        this.firmen = [];

        this.subventioniert = true;
        this.kontingentId = subventioniertesKontingent.id;
    }

    // Firmen can be given as optional parameter to avoid creating a new Firma for every input field, instead an
    // already existing Firma can be re-used, which reduces the memory impact of Montasblatt a tiny bit
    public setFirma(firmenKontingent: FirmenKontingent, firmen?: Firma[]): void {
        this.privat = false;
        this.subventioniert = false;

        let firma = firmen?.find(f => f.id === firmenKontingent.firmaId);
        if (!firma) {
            firma = new Firma();
            firma.name = firmenKontingent.getDisplayName();
            firma.symbol = firmenKontingent.firma?.symbol ?? null;
        }

        this.firmen = [firma];
        this.kontingentId = firmenKontingent.id;
    }
}
