/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DvbError} from '@dv/shared/code';
import {DvbErrorUtil, ErrorLevel, ErrorType, HttpErrorUtil} from '@dv/shared/code';
import type {Translator} from '@dv/shared/translator';

export class FakturaMassenaktionError {

    public errorText: string | null = null;

    public constructor(
        public error: DvbError,
        public kontaktpersonDisplayName: string,
        public rechnungsNummer: number,
        public rechnungsId: string,
    ) {
    }

    public static apiResponseTransformer(data: any): FakturaMassenaktionError {
        return new FakturaMassenaktionError(
            HttpErrorUtil.toDvbError(ErrorType.INTERNAL, ErrorLevel.SEVERE, 'ERRORS.ERR_INTERNAL', data.error),
            data.kontaktpersonDisplayName,
            data.rechnungsNummer,
            data.rechnungsId,
        );
    }

    public initErrorText(translate: Translator): void {
        this.errorText = DvbErrorUtil.getErrorMessage(this.error, translate);
    }
}
