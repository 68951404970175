/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {AbrechnungTyp} from '../tarife/AbrechnungTyp';
import {Tarif} from '../tarife/Tarif';

export class KitaAbrechnungTarif implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public typ: Readonly<AbrechnungTyp>,
        public tarifId: string | null = null,
        public tarif: Tarif | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): KitaAbrechnungTarif {
        const typ = AbrechnungTyp.apiResponseTransformer(data.typ);
        const kitaAbrechnungTarif = new KitaAbrechnungTarif(data.id, typ, data.tarifId);

        if (data.tarif) {
            kitaAbrechnungTarif.tarif = Tarif.apiResponseTransformer(data.tarif);
        }

        return kitaAbrechnungTarif;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            typ: this.typ.name,
            tarifId: this.tarif ? this.tarif.id : this.tarifId,
        };
    }
}
