/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import type {IPersistable, IRestModel} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {BankStatementEntry} from '../bankstatement';
import {Rueckerstattung} from '../kontaktperson-zahlungen/Rueckerstattung';
import {Vorauszahlung} from '../kontaktperson-zahlungen/Vorauszahlung';

export enum ZahlungType {
    BANKZAHLUNG = 'BANKZAHLUNG',
    SKONTO = 'SKONTO',
    RUNDUNGSDIFFERENZ = 'RUNDUNGSDIFFERENZ',
    ABSCHREIBUNG = 'ABSCHREIBUNG',
    AUSGLEICHSZAHLUNG = 'AUSGLEICHSZAHLUNG',
}

export class Zahlung implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public dtype: RechnungsKonfigurationType | null = null,
        public betrag: number | null = null,
        public datum: moment.Moment | null = null,
        public type: ZahlungType | null = null,
        public bemerkung: string | null = null,
        public vorauszahlung: Vorauszahlung | null = null,
        public ausgleichsVorauszahlung: Vorauszahlung | null = null,
        public ausgleichsRueckerstattung: Rueckerstattung | null = null,
        public bankStatementEntry: BankStatementEntry | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Zahlung {
        const answer = new Zahlung();
        answer.id = data.id;
        answer.dtype = data.dtype;
        answer.betrag = parseFloat(data.betrag);
        answer.datum = DvbRestUtil.localDateToMoment(data.datum);
        answer.type = data.type;
        answer.bemerkung = data.bemerkung;

        if (data.vorauszahlung) {
            answer.vorauszahlung = Vorauszahlung.apiResponseTransformer(data.vorauszahlung);
        }

        if (data.ausgleichsVorauszahlung) {
            answer.ausgleichsVorauszahlung = Vorauszahlung.apiResponseTransformer(data.ausgleichsVorauszahlung);
        }

        if (data.ausgleichsRueckerstattung) {
            answer.ausgleichsRueckerstattung =
                Rueckerstattung.apiResponseTransformer(data.ausgleichsRueckerstattung);
        }

        if (data.bankStatementEntry) {
            answer.bankStatementEntry = BankStatementEntry.apiResponseTransformer(data.bankStatementEntry);
        }

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            dtype: this.dtype,
            betrag: this.betrag,
            datum: DvbRestUtil.momentToLocalDate(this.datum),
            type: this.type,
            bemerkung: this.bemerkung,
            vorauszahlung: this.vorauszahlung?.toRestObject() ?? null,
            ausgleichsVorauszahlung: this.ausgleichsVorauszahlung?.toRestObject() ?? null,
            ausgleichsRueckerstattung: this.ausgleichsRueckerstattung?.toRestObject() ?? null,
        };
    }
}
