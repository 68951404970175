/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {MatchingState} from '@dv/shared/backend/model/matching-state';
import type {IDisplayable, IPersistable, IRestModel} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import {AdresseWithoutGemeinde} from '../adressen/AdresseWithoutGemeinde';
import type {KinderOrt} from '../kinderort/KinderOrt';
import {KinderOrtTransformer} from '../kinderort/KinderOrtTransformer';

export function assignmentName(kinderOrt: string, mandant: string): string {
    return `${kinderOrt} (${mandant})`;
}

export class KibonExchangeInstitution implements IPersistable, IRestModel, IDisplayable {

    public constructor(
        public id: string | null = null,
        public externalId: string | null = null,
        public name: string | null = null,
        public traegerschaft: string | null = null,
        public adresse: AdresseWithoutGemeinde | null = null,
        public matchingState: MatchingState | null = null,
        public kinderOrt: KinderOrt | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): KibonExchangeInstitution {

        return new KibonExchangeInstitution(
            data.id,
            data.externalId,
            data.name,
            data.traegerschaft,
            AdresseWithoutGemeinde.apiResponseTransformer(data.adresse),
            data.matchingState,
            data.kinderOrt ? KinderOrtTransformer.create().apiResponseTransformer(data.kinderOrt) : null,
        );
    }

    public getDisplayName(): string {
        if (this.traegerschaft) {
            return `${this.name} (${this.traegerschaft})`;
        }

        return this.name ?? '';
    }

    public getKinderOrtAndMandantName(): string {
        const kinderOrt = checkPresent(this.kinderOrt);
        const mandant = checkPresent(kinderOrt.mandant?.name);

        return assignmentName(kinderOrt.getDisplayName(), mandant);
    }

    public toRestObject(): Record<string, unknown> {
        return {id: this.id};
    }
}
