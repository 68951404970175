/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {BetreuungsZeitraumBelegung} from './BetreuungsZeitraumBelegung';
import {GruppenWochenBelegung} from './GruppenWochenBelegung';
import {WochenPlaetze} from './WochenPlaetze';

export class KitaWochenBelegung {

    public constructor(
        public kitaId: string | null = null,
        public betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung[] = [],
        public plaetze: WochenPlaetze = new WochenPlaetze(),
        public gruppenWochenBelegungen: GruppenWochenBelegung[] = []) {
    }

    public static apiResponseTransformer(data: any): KitaWochenBelegung {
        const answer = new KitaWochenBelegung(data.kitaId);

        if (Array.isArray(data.betreuungsZeitraumBelegung)) {
            answer.betreuungsZeitraumBelegung = data.betreuungsZeitraumBelegung
                .map((bzb: any) => BetreuungsZeitraumBelegung.apiResponseTransformer(bzb));
        }

        if (data.wochenPlaetze) {
            answer.plaetze = WochenPlaetze.apiResponseTransformer(data.wochenPlaetze);
        }

        if (Array.isArray(data.gruppenWochenBelegungen)) {
            answer.gruppenWochenBelegungen = data.gruppenWochenBelegungen
                .map((gwb: any) => GruppenWochenBelegung.apiResponseTransformer(gwb));
        }

        return answer;
    }
}
