/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxApplicationRole} from '@dv/shared/backend/model/jax-application-role';
import type {IRestModel, IValidable} from '@dv/shared/code';
import {isPresent} from '@dv/shared/code';
import type {KitaPermissionType, PersonalPermissionType, UserRole} from '@dv/shared/roles';
import {isKitaPermissionType, isPersonalPermissionType} from '@dv/shared/roles';

export class ApplicationRole implements IRestModel<JaxApplicationRole>, IValidable {

    public constructor(
        public userRole: UserRole | null = null,
        public kitaPermission: KitaPermissionType | null = null,
        public personalPermission: PersonalPermissionType | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxApplicationRole): ApplicationRole {
        const kitaPermission = isKitaPermissionType(data.kitaPermission) ? data.kitaPermission : null;
        const personalPermission = isPersonalPermissionType(data.personalPermission) ? data.personalPermission : null;

        return new ApplicationRole(data.userRole, kitaPermission, personalPermission);
    }

    public isValid(): boolean {
        const hasKinderOrtRole = isPresent(this.kitaPermission) || isPresent(this.personalPermission);
        const hasUserRole = isPresent(this.userRole);

        return hasUserRole !== hasKinderOrtRole;
    }

    public toRestObject(): JaxApplicationRole {
        return {
            userRole: this.userRole ?? undefined,
            kitaPermission: this.kitaPermission ?? undefined,
            personalPermission: this.personalPermission ?? undefined,
        };
    }
}
