/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import type {IPersistable, IRestModel} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, END_OF_TIME} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {LeistungsTyp} from '../leistungen/LeistungsTyp';
import {TarifParameterValue} from './TarifParameterValue';

export class TarifParameterHistoryEntry implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public dtype: TarifParameterType | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment = END_OF_TIME,
        public values: TarifParameterValue[] = [],
        public fraktionId: string | null = null,
        public tarifId: string | null = null,
        public leistungsTyp: LeistungsTyp | null = null,
        public kontingentId: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): TarifParameterHistoryEntry {
        const values = data.values.map((v: any) => TarifParameterValue.apiResponseTransformer(v));

        return new TarifParameterHistoryEntry(
            data.id,
            data.dtype,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis)!,
            values,
            data.fraktionId,
            data.tarifId,
            data.leistungsTypDiscriminator,
            data.kontingentId);
    }

    public isValid(): boolean {
        if (!DvbDateUtil.isValidMoment(this.gueltigAb) || !Array.isArray(this.values)) {
            return false;
        }

        // only consider TarifParameterValues that are set
        return this.values
            .filter(v => v.value)
            .every(v => v.isValid());
    }

    /**
     * Create a copy where all invalid TarifParameterValues are removed
     */
    public getCleanCopy(): this {
        const copy = angular.copy(this);
        copy.values = copy.values.filter(v => v.isValid());

        return copy;
    }

    public toRestObject(): Record<string, unknown> {
        const restObject: any = {};

        restObject.id = this.id;
        restObject.dtype = this.dtype;
        restObject.gueltigAb = DvbRestUtil.momentToLocalDate(this.gueltigAb);
        restObject.gueltigBis = DvbRestUtil.momentToLocalDate(this.gueltigBis);
        restObject.values = this.values.map(v => v.toRestObject());
        restObject.fraktionId = this.fraktionId;
        restObject.tarifId = this.tarifId;
        restObject.leistungsTypDiscriminator = this.leistungsTyp;
        restObject.kontingentId = this.kontingentId;

        return restObject;
    }
}
