/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, ParamValueDisplayType} from '@dv/shared/code';
import {
    checkPresent,
    DvbDateUtil,
    DvbRestUtil,
    DvbUtil,
    NamedEntity,
    NamedEntityType,
    ParamValue,
} from '@dv/shared/code';
import type {Translator} from '@dv/shared/translator';
import type moment from 'moment';
import type {NameWithPrincipal} from './NameWithPrincipal';
import type {TaskCategory} from './TaskCategory';
import {TASK_CATEGORY} from './TaskCategory';
import type {TaskParam} from './TaskParam';
import type {TaskParamName} from './TaskParamName';

export class DisplayTask implements IPersistable {

    public constructor(
        public id: string | null = null,
        public category: Readonly<NameWithPrincipal> | null = null,
        public affectedEntities: NamedEntity[] = [],
        public principalEntity: NamedEntity | null = null,
        public principalType: NamedEntityType | null = null,
        public erledigenBis: moment.Moment | null = null,
        public erledigtAm: moment.Moment | null = null,
        public gueltigAb: moment.Moment | null = null,
        public taskPrio: number | null = null,
        public zeitraumVon: moment.Moment | null = null,
        public zeitraumBis: moment.Moment | null = null,
        public params: { [k in TaskParamName]?: TaskParam } = {},
    ) {
    }

    public static apiResponseTransformer(data: any): DisplayTask {
        Object.keys(data.params)
            .forEach(key => {
                data.params[key].paramValue = ParamValue.apiResponseTransformer(data.params[key].paramValue);
            });

        const answer = new DisplayTask(
            data.id,
            TASK_CATEGORY[data.category as TaskCategory],
            [],
            null,
            data.principalType,
            DvbRestUtil.localDateToMoment(data.erledigenBis),
            DvbRestUtil.localDateToMoment(data.erledigtAm),
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            data.taskPrio,
            DvbRestUtil.localDateToMoment(data.zeitraumVon),
            DvbRestUtil.localDateToMoment(data.zeitraumBis),
            data.params,
        );

        if (!answer.category) {
            throw new Error(`Task category for "${JSON.stringify(data.category)}" not yet implemented!`);
        }

        if (Array.isArray(data.affectedEntities)) {
            answer.affectedEntities = data.affectedEntities
                .map((entity: any[]) => NamedEntity.apiResponseTransformer(entity));

            answer.principalEntity = DisplayTask.findPrincipalEntity(answer);
        }

        return answer;
    }

    public static findPrincipalEntity(task: DisplayTask): NamedEntity {
        return task.findEntity(checkPresent(task.principalType));
    }

    public getParamDisplayValues(translator: Translator): { [index: string]: ParamValueDisplayType } {
        const result: { [index: string]: ParamValueDisplayType } = {};
        const params = this.params;

        DvbUtil.keys(params).forEach(name => {
            result[name] = checkPresent(params[name]).paramValue.getDisplayValue(translator);
        });

        if (DvbDateUtil.isValidMoment(this.zeitraumVon)) {
            if (DvbDateUtil.isValidMoment(this.zeitraumBis) && !DvbDateUtil.isEndOfTime(this.zeitraumBis)) {
                if (this.zeitraumVon.isSame(this.zeitraumBis)) {
                    result.ZEITRAUM = translator.instant('COMMON.TASKS.AUSTRITT_DATUM', {
                        AUSTRITT: checkPresent(this.zeitraumVon).format('D.M.YYYY'),
                    });
                } else {
                    result.ZEITRAUM = translator.instant('COMMON.TASKS.ZEITRAUM_VON_BIS', {
                        ZEITRAUM_VON: checkPresent(this.zeitraumVon).format('D.M.YYYY'),
                        ZEITRAUM_BIS: checkPresent(this.zeitraumBis).format('D.M.YYYY'),
                    });
                    result.PERIODE = translator.instant('COMMON.TASKS.PERIODE', {
                        VON: checkPresent(this.zeitraumVon).format('YYYY'),
                        BIS: checkPresent(this.zeitraumBis).format('YYYY'),
                    });
                }
            } else {
                result.ZEITRAUM = translator.instant('COMMON.TASKS.ZEITRAUM_AB', {
                    ZEITRAUM_VON: checkPresent(this.zeitraumVon).format('D.M.YYYY'),
                });
            }
        }

        return result;
    }

    public getTranslationParams(translator: Translator): { [index: string]: ParamValueDisplayType } {
        const result = this.getParamDisplayValues(translator);

        if (this.category === TASK_CATEGORY.RECHNUNG_ZAHLUNGEN) {
            result.KONTAKTPERSON = this.findEntity(NamedEntityType.KONTAKTPERSON).displayName!;
        }

        return result;
    }

    public findEntities(dtype: NamedEntityType | readonly NamedEntityType[]): NamedEntity[] {
        const dtypes = Array.isArray(dtype) ? dtype : [dtype];

        return this.affectedEntities.filter(entity => dtypes.includes(entity.dtype!));
    }

    public findEntity(dtype: NamedEntityType | readonly NamedEntityType[]): NamedEntity {
        const namedEntities = this.findEntities(dtype);

        if (namedEntities.length === 0) {
            throw new Error(`Task entity not found for task ${this.id} ${JSON.stringify(dtype)}`);
        }

        if (namedEntities.length > 1) {
            throw new Error(`Found more than one task entity for task
             ${this.id} ${this.affectedEntities.length} ${JSON.stringify(dtype)}`);
        }

        return namedEntities[0];
    }
}
