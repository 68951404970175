/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export type SchemaTyp = 'kiBon' | 'kiTax';

export class KiTaxSchema {

    public readonly version: string;
    public readonly isKiBon: boolean;
    public readonly type: SchemaTyp;

    public constructor(version: string, isKiBon: boolean, type: SchemaTyp) {
        this.version = version;
        this.isKiBon = isKiBon;
        this.type = type;
    }

    public static apiResponseTransformer(data: string): KiTaxSchema {
        if (data === '1.0' || data === '1.1') {
            return new KiTaxSchema(data, false, 'kiTax');
        }

        if (data === '2.0' || data === '2.1' || data === '2.2') {
            return new KiTaxSchema(data, true, 'kiBon');
        }

        throw new Error(`unknown KiTaxSchema Version ${data}`);
    }
}
