/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel, IValidable} from '@dv/shared/code';
import {NamedEntity, SEARCH_RESULT_TYPES, SearchResultEntry} from '@dv/shared/code';

export class Favorit implements IPersistable, IRestModel, IValidable {

    public constructor(
        public id: string | null = null,
        public position: number = 0,
        public title: string | null = null,
        public namedEntity: NamedEntity = new NamedEntity(),
    ) {
    }

    public static apiResponseTransformer(data: any): Favorit {
        const answer = new Favorit();

        answer.id = data.id;
        answer.position = data.position;
        answer.title = data.title;
        answer.namedEntity = NamedEntity.apiResponseTransformer(data.namedEntity);

        return answer;
    }

    public isValid(): boolean {
        return !!this.id && !!this.namedEntity.entityId && !!this.namedEntity.dtype;
    }

    public toSearchResultEntry(): SearchResultEntry {
        if (!this.title || !this.namedEntity.dtype || !this.namedEntity.entityId) {
            throw new Error('cannot convert favorit to SearchResultEntry without title, entity id or dtype');
        }

        return new SearchResultEntry(
            SEARCH_RESULT_TYPES.check(this.namedEntity.dtype),
            this.namedEntity.entityId,
            this.title,
            null,
            null);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            position: this.position,
            namedEntity: this.namedEntity.toRestObject(),
        };
    }
}
