/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel, IValidable} from '@dv/shared/code';
import {Adresse} from '@dv/shared/code';
import angular from 'angular';

export class FirmenKontakt implements IRestModel, IValidable {

    public constructor(
        public vorName: string = '',
        public familienName: string = '',
        public adresse: Adresse = new Adresse(),
        public telefon: string | null = null,
        public email: string | null = null,
        public website: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): FirmenKontakt {
        const answer = new FirmenKontakt();
        answer.vorName = data.vorName;
        answer.familienName = data.familienName;
        answer.adresse = Adresse.apiResponseTransformer(data.adresse);
        answer.telefon = data.telefon;
        answer.email = data.email;
        answer.website = data.website;

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            vorName: this.vorName,
            familienName: this.familienName,
            adresse: this.adresse ? this.adresse.toRestObject() : null,
            telefon: this.telefon,
            email: this.email,
            website: this.website,
        };
    }

    public isValid(): boolean {
        return this.hasValidVorName() && this.hasValidFamilienName() && this.hasValidAdresse();
    }

    public hasValidVorName(): boolean {
        return angular.isString(this.vorName);
    }

    public hasValidFamilienName(): boolean {
        return angular.isString(this.familienName);
    }

    public hasValidAdresse(): boolean {
        return angular.isObject(this.adresse) && this.adresse.isValid();
    }
}
