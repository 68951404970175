/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Kontaktperson} from '../../kontakte/Kontaktperson';
import {OffenerPosten} from './OffenerPosten';

export class KontaktpersonOffenePosten {

    public constructor(
        public kontaktperson: Kontaktperson | null = null,
        public offenePosten: OffenerPosten[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): KontaktpersonOffenePosten {
        const answer = new KontaktpersonOffenePosten();

        answer.kontaktperson = Kontaktperson.apiResponseTransformer(data.kontaktperson);

        if (Array.isArray(data.offenePosten)) {
            answer.offenePosten = data.offenePosten.map(
                (offenerPosten: any) => OffenerPosten.apiResponseTransformer(offenerPosten));
        }

        return answer;
    }
}
