/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AnwesenheitsZeitConstraintType} from '@dv/shared/backend/model/anwesenheits-zeit-constraint-type';
import type {DayOfWeek, IDisplayable} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class AnwesenheitsZeitConstraint implements IDisplayable  {

    public constructor(
        public type: AnwesenheitsZeitConstraintType,
        public dayOfWeek: DayOfWeek,
        public von: moment.Moment | null = null,
        public bis: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): AnwesenheitsZeitConstraint {
        return new AnwesenheitsZeitConstraint(
            data.type,
            data.dayOfWeek,
            DvbRestUtil.localeHHMMTimeToMoment(data.von),
            DvbRestUtil.localeHHMMTimeToMoment(data.bis),
        );
    }

    public getDisplayName(): string {
        if (DvbDateUtil.isMomentEquals(this.von, this.bis)) {
            return DvbRestUtil.momentTolocaleHHMMTime(this.von) ?? '';
        }

        return `${DvbRestUtil.momentTolocaleHHMMTime(this.von)}-${DvbRestUtil.momentTolocaleHHMMTime(this.bis)}`;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            type: this.type,
            dayOfWeek: this.dayOfWeek,
            von: DvbRestUtil.momentTolocaleHHMMTime(this.von),
            bis: DvbRestUtil.momentTolocaleHHMMTime(this.bis),
        };
    }
}
