/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KitadminModul} from '@dv/shared/backend/model/kitadmin-modul';
import type {IPersistable, IRestModel, SubscriptionModel, SupportedLanguage} from '@dv/shared/code';
import {Adresse, DvbDateUtil, DvbRestUtil, DvbUtil} from '@dv/shared/code';
import type moment from 'moment';
import {ContextualAdresse} from '../adressen/ContextualAdresse';
import {MandantRechnungConfig} from './MandantRechnungConfig';
import {ModulPreise} from './ModulPreise';

export class Mandant implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public adresse: Adresse = new Adresse(),
        public contextualAdresse: ContextualAdresse | null = null,
        public abgerechnetBis: moment.Moment | null = null,
        public timestampErstellt: moment.Moment | null = null,
        public abrechnungVorausZusatzMonate: number | null = null,
        public warningIntervalInDays: number | null = null,
        public subscriptionModel: SubscriptionModel | null = null,
        public resettable: boolean = false,
        public disabled: boolean = false,
        public preise: Map<KitadminModul, ModulPreise> =
            new Map<KitadminModul, ModulPreise>([[KitadminModul.BASIC, new ModulPreise(KitadminModul.BASIC)]]),
        public defaultPreise: Map<KitadminModul, ModulPreise> = new Map<KitadminModul, ModulPreise>(),
        public defaultLanguage: SupportedLanguage | null = null,
        public supportedLanguages: SupportedLanguage[] = [],
        public mandantRechnungConfig: MandantRechnungConfig | null = null,
        public idpHint: string | null = null,
        public oidcUserManagement: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): Mandant {
        const answer = new Mandant();
        answer.id = data.id;
        answer.name = data.name;
        answer.resettable = data.resettable;
        answer.disabled = data.disabled;
        answer.abgerechnetBis = DvbRestUtil.localDateToMoment(data.abgerechnetBis);
        answer.timestampErstellt = DvbRestUtil.localDateTimeToMoment(data.timestampErstellt);
        answer.abrechnungVorausZusatzMonate = parseInt(data.abrechnungVorausZusatzMonate, 10);
        answer.warningIntervalInDays = parseInt(data.warningIntervalInDays, 10);
        answer.subscriptionModel = data.subscriptionModel;
        answer.defaultLanguage = data.defaultLanguage;
        answer.idpHint = data.idpHint;
        answer.oidcUserManagement = data.oidcUserManagement;

        DvbRestUtil.transformArray(data.preise, ModulPreise)
            .forEach((preis: ModulPreise) => answer.preise.set(preis.modul, preis));

        DvbRestUtil.transformArray(data.defaultPreise, ModulPreise)
            .forEach((preis: ModulPreise) => answer.defaultPreise.set(preis.modul, preis));

        if (data.adresse) {
            answer.adresse = Adresse.apiResponseTransformer(data.adresse);
        }

        if (data.contextualAdresse) {
            answer.contextualAdresse = ContextualAdresse.apiResponseTransformer(data.contextualAdresse);
        }

        if (Array.isArray(data.supportedLanguages)) {
            data.supportedLanguages.forEach((language: SupportedLanguage) => {
                answer.supportedLanguages.push(language);
            });
        }

        if (data.mandantRechnungConfig) {
            answer.mandantRechnungConfig = MandantRechnungConfig.apiResponseTransformer(data.mandantRechnungConfig);
        }

        return answer;
    }

    private static isOrdinal(num: number | null): boolean {
        return !!num && DvbUtil.isInteger(num) && num >= 0;
    }

    public isValid(): boolean {
        return DvbUtil.isNotEmptyString(this.name) &&
            DvbUtil.isNotEmptyString(this.subscriptionModel) &&
            DvbDateUtil.isValidMoment(this.abgerechnetBis) &&
            Mandant.isOrdinal(this.abrechnungVorausZusatzMonate) &&
            Mandant.isOrdinal(this.warningIntervalInDays) &&
            this.adresse.isValid() &&
            DvbUtil.isNotEmptyString(this.defaultLanguage);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            name: this.name,
            adresse: this.adresse.toRestObject(),
            contextualAdresse: this.contextualAdresse ? this.contextualAdresse.toRestObject() : null,
            abgerechnetBis: DvbRestUtil.momentToLocalDate(this.abgerechnetBis),
            abrechnungVorausZusatzMonate: this.abrechnungVorausZusatzMonate,
            warningIntervalInDays: this.warningIntervalInDays,
            subscriptionModel: this.subscriptionModel,
            resettable: this.resettable,
            // defaultPreise must not be sent to backend
            preise: Array.from(this.preise.values(), value => value.toRestObject()),
            supportedLanguages: this.supportedLanguages,
            defaultLanguage: this.defaultLanguage,
            idpHint: this.idpHint,
            oidcUserManagement: this.oidcUserManagement,
        };
    }
}
