/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxPassword} from '@dv/shared/backend/model/jax-password';
import type {IRestModel} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';

export class Password implements IRestModel<JaxPassword> {

    public constructor(
        public currentPassword: string | null = null,
        public newPassword: string | null = null,
        public confirmedPassword: string | null = null,
    ) {
    }

    public static validate(password: string | null): boolean {
        if (!angular.isString(password)) {
            return false;
        }

        // Mindestens jeweils 1 Ziffer, Buchstabe, Sonderzeichen; Mindestens 8 Stellen
        const strongRegex = /^(?=.{8,})(?=.*[A-Za-z])(?=.*\d)(?=.*\W).*$/g;

        return strongRegex.test(password);
    }

    public toRestObject(): JaxPassword {
        return {
            currentPassword: checkPresent(this.currentPassword),
            newPassword: checkPresent(this.newPassword),
        };
    }
}
