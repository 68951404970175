/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbDateUtil, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {Belegung} from '../belegung/Belegung';
import type {Kind} from '../kind/Kind';
import type {KinderOrt} from '../kinderort/KinderOrt';
import {KinderOrtTransformer} from '../kinderort/KinderOrtTransformer';

export class Betreuungsfaktor {

    public constructor(
        public betreuungsfaktor: number,
        public kitas: KinderOrt[] = [],
        public spezifisch: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): Betreuungsfaktor {
        const kitas = Array.isArray(data.kitas) ?
            data.kitas.map((k: any) => KinderOrtTransformer.create().apiResponseTransformer(k)) :
            [];

        return new Betreuungsfaktor(data.betreuungsfaktor, kitas);
    }

    public static groupByFaktor(betreuungsfaktoren: Betreuungsfaktor[]): Betreuungsfaktor[] {
        return betreuungsfaktoren.reduce((acc: Betreuungsfaktor[], current) => {
            const found = acc.find(item => item.betreuungsfaktor === current.betreuungsfaktor);

            if (found) {
                found.kitas = DvbUtil.uniqueArray(found.kitas.concat(current.kitas), DvbUtil.mapToId);
            } else {
                acc.push(current);
            }

            return acc;
        }, []);
    }

    public setSpezifischFromKind(kind: Kind, stichtag: moment.Moment): void {
        this.setSpezifisch(DvbDateUtil.getEntityOn(kind.belegungen, stichtag));
    }

    public setSpezifisch(belegung: Belegung | null): void {
        this.spezifisch = belegung ? angular.isNumber(belegung.betreuungsfaktor) : false;
    }
}
