/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';

export class BelegungsEinheit implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public prio: number | null = null,
        public prozentPunkte: number | null = null,
        public zeitraumIds: string[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): BelegungsEinheit {
        const zeitraumIds = Array.isArray(data.zeitraumIds) ? data.zeitraumIds : [];

        return new BelegungsEinheit(
            data.id,
            data.name,
            parseInt(data.prio, 10),
            parseFloat(data.prozentPunkte),
            zeitraumIds,
        );
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            prio: this.prio,
            prozentPunkte: this.prozentPunkte,
            zeitraumIds: this.zeitraumIds,
        };
    }
}
