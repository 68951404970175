/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {isNullish, isPresent, TypeUtil} from '@dv/shared/code';
import {PENSUM_TYPE_SYMBOL, PensumType} from './PensumType';

export interface IPensum {
    von: number | null;
    bis: number | null;
}

export function isPensum(value: unknown): value is IPensum {
    return typeof value === 'object'
        && isPresent(value)
        && 'von' in value
        && 'bis' in value;
}

function vonIsLargerBis(von: number | null, bis: number | null): boolean {
    return TypeUtil.isNumber(von) && TypeUtil.isNumber(bis) && von > bis;
}

export class Pensum implements IPensum {

    public constructor(
        public von: number | null = null,
        public bis: number | null = null,
        public type: PensumType = PensumType.PERCENTAGE,
    ) {
        this.update();
    }

    public update(): void {
        const von = this.von;
        const bis = this.bis;
        if (isNullish(bis) && TypeUtil.isNumber(von) || vonIsLargerBis(von, bis)) {
            this.bis = von;
        }
    }

    public display(): string {
        const symbol = PENSUM_TYPE_SYMBOL[this.type];

        // eslint-disable-next-line prefer-template
        return `${this.von}${this.von === this.bis ? '' : '-' + String(this.bis)}${symbol}`;
    }
}
