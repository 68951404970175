/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel, RechnungFilterStateName} from '@dv/shared/code';
import {DvbUtil, RECHNUNG_FILTER_STATES} from '@dv/shared/code';

export type RechnungsStatusType = RechnungFilterStateName | 'NICHT_VERFUEGBAR';

const colorStatusOk = 'status-ok';
const colorStatusNormal = 'status-normal';

export class RechnungsStatus implements IRestModel {

    public static readonly EXTERNAL_STATUS: { [k in RechnungsStatusType]: Readonly<RechnungsStatus> } = {
        BEZAHLT: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_BEZAHLT',
            RECHNUNG_FILTER_STATES.BEZAHLT.name,
            colorStatusOk, 'custom-icon custom-icon-zugewiesen'),
        TEILBEZAHLT: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_TEILBEZAHLT',
            RECHNUNG_FILTER_STATES.TEILBEZAHLT.name,
            colorStatusNormal, 'custom-icon custom-icon-prov-zugewiesen'),

        STORNIERT: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_STORNIERT',
            RECHNUNG_FILTER_STATES.STORNIERT.name,
            colorStatusNormal, 'custom-icon custom-icon-storniert'),
        GESENDET: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_GESENDET',
            RECHNUNG_FILTER_STATES.GESENDET.name,
            colorStatusNormal, 'custom-icon custom-icon-gesendet'),

        ERINNERT: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_ERINNERT',
            RECHNUNG_FILTER_STATES.ERINNERT.name,
            colorStatusNormal, 'custom-icon custom-icon-zahlungserinnerung'),
        MAHNUNG1: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_MAHNUNG1',
            RECHNUNG_FILTER_STATES.MAHNUNG1.name,
            colorStatusNormal, 'custom-icon custom-icon-mahnung-1'),
        MAHNUNG2: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_MAHNUNG2',
            RECHNUNG_FILTER_STATES.MAHNUNG2.name,
            colorStatusNormal, 'custom-icon custom-icon-mahnung-2'),
        MAHNUNG3: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_MAHNUNG3',
            RECHNUNG_FILTER_STATES.MAHNUNG3.name,
            colorStatusNormal, 'custom-icon custom-icon-mahnung-3'),
        BETRIEBEN: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_BETRIEBEN',
            RECHNUNG_FILTER_STATES.BETRIEBEN.name,
            colorStatusNormal, 'custom-icon custom-icon-betreibung'),

        SONSTIGES: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_SONSTIGES',
            RECHNUNG_FILTER_STATES.SONSTIGES.name,
            colorStatusNormal, 'custom-icon custom-icon-sonstiges'),
        NICHT_VERFUEGBAR: new RechnungsStatus('FAKTURA.EXT_STATE.EXT_STATE_NICHT_VERFUEGBAR', null,
            'status-not-ok', 'fa fa-times-circle-o'),
    };

    public constructor(
        public msgKey: string,
        public filterState: RechnungFilterStateName | null,
        public color: string,
        public icon: string | null,
    ) {
    }

    public static getExternalStates(filterState: string): RechnungsStatusType[] {
        return DvbUtil.keys(RechnungsStatus.EXTERNAL_STATUS)
            .filter(state => RechnungsStatus.EXTERNAL_STATUS[state].filterState === filterState);
    }

    public static apiResponseTransformer(data: RechnungsStatusType): RechnungsStatus {
        return RechnungsStatus.EXTERNAL_STATUS[data] || new RechnungsStatus(data, null, 'status-not-ok', null);
    }

    public toRestObject(this: RechnungsStatus): { status: RechnungsStatusType } {
        return {status: DvbUtil.getKey(RechnungsStatus.EXTERNAL_STATUS, this)};
    }
}
