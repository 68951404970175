/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, END_OF_TIME} from '@dv/shared/code';
import type moment from 'moment';
import {TarifTemplate} from './TarifTemplate';

export class TarifValue implements IRestModel, IPersistable, ILimited {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = END_OF_TIME,
        public template: TarifTemplate | null = null,
        public bemerkung: string = '',
        public manualPositionsTotal: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): TarifValue {
        const value = new TarifValue();
        value.id = data.id;
        value.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        value.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis);
        value.bemerkung = data.bemerkung;
        value.manualPositionsTotal = data.manualPositionsTotal;

        if (data.template) {
            value.template = TarifTemplate.apiResponseTransformer(data.template);
        }

        return value;
    }

    public isValid(): boolean {
        return DvbDateUtil.isValidMoment(this.gueltigAb) && !!this.template?.id;
    }

    public toRestObject(): Record<string, unknown> {
        const restObject: any = {};

        restObject.id = this.id;
        restObject.gueltigAb = DvbRestUtil.momentToLocalDate(this.gueltigAb);
        restObject.gueltigBis = DvbRestUtil.momentToLocalDate(this.gueltigBis);
        restObject.templateBlobId = this.template ? this.template.id : null;
        restObject.bemerkung = this.bemerkung;
        restObject.manualPositionsTotal = this.manualPositionsTotal;

        return restObject;
    }
}
