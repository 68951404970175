/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    Country,
    INameGeburtsdatumGeschlecht,
    IPersistable,
    IRestModel,
    IValidable,
    Persisted,
} from '@dv/shared/code';
import {
    checkPersisted,
    checkPresent,
    DvbDateUtil,
    DvbRestUtil,
    DvbUtil,
    END_OF_TIME,
    Geschlecht,
} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import {LimitedAdresse} from '../adressen/LimitedAdresse';
import {Belegung} from '../belegung/Belegung';
import {Bewerbung} from '../belegung/Bewerbung';
import {ExtraPlatz} from '../belegung/ExtraPlatz';
import {UploadTempBlob} from '../blob/UploadTempBlob';
import {CustomFieldValue} from '../customfield/CustomFieldValue';
import {IsoLanguagesAndCountriesService} from '../isoLanguagesAndCountriesService';
import type {Abholberechtigt} from '../kontakte/Abholberechtigt';
import type {Erziehungsberechtigter} from '../kontakte/Erziehungsberechtigter';
import {KindKontakteUtil} from '../kontakte/kindKontakteUtil';
import {Kontaktperson} from '../kontakte/Kontaktperson';
import type {Rechnungsempfaenger} from '../kontakte/Rechnungsempfaenger';
import {RelationshipWithKontaktperson} from '../kontakte/RelationshipWithKontaktperson';
import type {Language} from '../locale/Language';
import {ServiceContainer} from '../ServiceContainer';
import {ConfidentialityLevel} from './ConfidentialityLevel';

export class Kind implements IPersistable,
    IValidable,
    INameGeburtsdatumGeschlecht,
    IRestModel<Record<string, unknown>, [boolean]> {

    public constructor(
        public id: string | null = null,
        public vorName: string = '',
        public familienName: string | null = null,
        public geburtsTag: moment.Moment | null = null,
        public geburtsTermin: moment.Moment | null = null,
        public geschlecht: Geschlecht = Geschlecht.UNBEKANNT,
        public bemerkung: string = '',
        public nationalitaet: Country | null = null,
        public muttersprache: Language | null = null,
        public sozialversicherungsNummer: string | null = null,
        public anmeldeDatum: moment.Moment | null = null,
        public platzbestaetigungDeactivated: boolean = false,
        public geschwister: Kind[] = [],
        public kontakte: RelationshipWithKontaktperson[] = [],
        public adressen: LimitedAdresse[] = [],
        public belegungen: Belegung[] = [],
        public kindWochenBelegungen: Belegung[] = [],
        public bewerbung: Bewerbung | null = null,
        public betreuungsGruendeIds: string[] = [],
        public anhaengeAllgemein: UploadTempBlob[] = [],
        public anhaengeVertraulich: UploadTempBlob[] = [],
        public customFieldValues: CustomFieldValue[] = [],
        public extraPlaetze: ExtraPlatz[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): Persisted<Kind> {
        const answer = new Kind();
        Kind.transformBasisData(answer, data);
        Kind.transformAnhangData(answer, data);

        if (Array.isArray(data.adressen)) {
            answer.adressen = data.adressen.map((a: any) => LimitedAdresse.apiResponseTransformer(a));
        }
        if (Array.isArray(data.geschwister)) {
            answer.geschwister = data.geschwister.map((g: any) => Kind.apiResponseTransformer(g));
        }
        if (Array.isArray(data.kontakte)) {
            answer.kontakte = data.kontakte.map((k: any) => RelationshipWithKontaktperson.apiResponseTransformer(k));
        }
        if (Array.isArray(data.belegungen)) {
            answer.belegungen = data.belegungen.map((b: any) => Belegung.apiResponseTransformer(b));
        }
        if (Array.isArray(data.kindWochenBelegungen)) {
            answer.kindWochenBelegungen = data.kindWochenBelegungen.map((b: any) => {
                // Wir ignorieren die referencedBelegungenIds momentan, weil es die noch nicht braucht.
                return Belegung.apiResponseTransformer(b.belegung);
            });
        }
        if (Array.isArray(data.betreuungsGruendeIds)) {
            answer.betreuungsGruendeIds = data.betreuungsGruendeIds;
        }
        if (data.bewerbung) {
            answer.bewerbung = Bewerbung.apiResponseTransformer(data.bewerbung);
        }
        if (Array.isArray(data.customFieldValues)) {
            answer.customFieldValues = data.customFieldValues.map((customFieldValue: any) =>
                CustomFieldValue.apiResponseTransformer(customFieldValue));
        }
        if (Array.isArray(data.extraPlaetze)) {
            answer.extraPlaetze = data.extraPlaetze.map((extraPlatz: any) =>
                ExtraPlatz.apiResponseTransformer(extraPlatz));
        }

        return checkPersisted(answer);
    }

    private static transformAnhangData(kind: Kind, data: any): void {
        if (Array.isArray(data.anhaengeAllgemein)) {
            kind.anhaengeAllgemein = data.anhaengeAllgemein.map((a: any) => UploadTempBlob.apiResponseTransformer(a));
        }
        if (Array.isArray(data.anhaengeVertraulich)) {
            kind.anhaengeVertraulich =
                data.anhaengeVertraulich.map((a: any) => UploadTempBlob.apiResponseTransformer(a));
        }
    }

    private static transformBasisData(kind: Kind, data: any): void {
        kind.id = data.id;
        kind.vorName = data.vorName;
        kind.familienName = data.familienName;
        kind.geburtsTag = DvbRestUtil.localDateToMoment(data.geburtsTag);
        kind.geburtsTermin = DvbRestUtil.localDateToMoment(data.geburtsTermin);
        kind.geschlecht = data.geschlecht;
        kind.anmeldeDatum = DvbRestUtil.localDateToMoment(data.anmeldeDatum);
        kind.bemerkung = data.bemerkung;
        kind.sozialversicherungsNummer = data.sozialversicherungsNummer;
        kind.platzbestaetigungDeactivated = data.platzbestaetigungDeactivated;

        if (data.muttersprache) {
            IsoLanguagesAndCountriesService.getLanguage(data.muttersprache).then((sprache: Language | null) => {
                kind.muttersprache = sprache;
            });
        }

        if (!data.nationalitaet) {
            return;
        }

        IsoLanguagesAndCountriesService.getCountry(data.nationalitaet).then((land: Country | null) => {
            kind.nationalitaet = land;
        });
    }

    public removeAnhang(anhang: UploadTempBlob): void {
        DvbUtil.removeFromArray(anhang, this.anhaengeAllgemein);
        DvbUtil.removeFromArray(anhang, this.anhaengeVertraulich);
    }

    public addAnhang(anhang: UploadTempBlob, confLevel: ConfidentialityLevel): void {
        if (confLevel === ConfidentialityLevel.VERTRAULICH) {
            this.anhaengeVertraulich.push(anhang);
        } else if (confLevel === ConfidentialityLevel.ALLGEMEIN) {
            this.anhaengeAllgemein.push(anhang);
        } else {
            throw new Error(`addAnhang not implemented for ConfidentialityLevel ${JSON.stringify(confLevel)}`);
        }
    }

    /**
     * @return TRUE when added, FALSE when already included
     */
    public addGeschwister(geschwister: Kind): boolean {
        if (this.id === geschwister.id) {
            return false;
        }

        const isNew = this.geschwister.every(g => g.id !== geschwister.id);

        if (isNew) {
            this.geschwister.push(geschwister);
        }

        return isNew;
    }

    /**
     * @return TRUE when Kontakt already exists, FALSE otherwise
     */
    public hasKontakt(relationshipWithKontaktperson: unknown): boolean {
        if (!(relationshipWithKontaktperson instanceof RelationshipWithKontaktperson)) {
            throw new Error('Expected parameter of type RelationshipWithKontaktperson');
        }
        // noinspection SuspiciousInstanceOfGuard
        if (!(relationshipWithKontaktperson.kontaktperson instanceof Kontaktperson) &&
            !relationshipWithKontaktperson.kontaktpersonId) {

            throw new Error('kontaktperson or kontaktpersonId must be set');
        }
        if (!relationshipWithKontaktperson.relationship) {
            throw new Error('relationship must be set');
        }

        if (this.kontakte.includes(relationshipWithKontaktperson)) {
            return true;
        }

        let existingKontakt: RelationshipWithKontaktperson[] = [];
        const kontakpersonId = relationshipWithKontaktperson.kontaktperson ?
            relationshipWithKontaktperson.kontaktperson.id :
            relationshipWithKontaktperson.kontaktpersonId;

        if (kontakpersonId) {
            existingKontakt = this.kontakte.filter(({kontaktperson, kontaktpersonId}) => {
                return (kontaktperson !== null && kontaktperson === relationshipWithKontaktperson.kontaktperson)
                    || kontaktperson?.id === kontakpersonId
                    || kontaktpersonId === kontakpersonId;
            });
        }

        return existingKontakt.length > 0;
    }

    /**
     * @return TRUE when added, FALSE when already included
     */
    public addKontakt(relationshipWithKontaktperson: RelationshipWithKontaktperson): boolean {
        if (this.hasKontakt(relationshipWithKontaktperson)) {
            return false;
        }

        if (this.hasValidHauptkontakt()) {
            // Prevent adding a second Hauptkontakt. Add it (at least) as Sonstiger Kontakt
            relationshipWithKontaktperson.relationship.deleteHauptkontakt();
            relationshipWithKontaktperson.relationship.createSonstigerKontakt();
        }

        this.kontakte.push(relationshipWithKontaktperson);

        return true;
    }

    public addErziehungsberechtigtForFirstTwoKontakte(
        relationshipWithKontaktperson: RelationshipWithKontaktperson): void {

        if (!relationshipWithKontaktperson.hasAdresse()) {
            return;
        }

        if (this.hasKontakt(relationshipWithKontaktperson)) {
            return;
        }

        const anzahlErziehungsberechtigte = this.kontakte.filter(
            kontakt => kontakt.relationship.erziehungsberechtigter !== null).length;

        if (anzahlErziehungsberechtigte < 2) {
            relationshipWithKontaktperson.relationship.createErziehungsberechtigter();
        }
    }

    public getDisplayName(): string {
        return ServiceContainer.displayNameService.displayKindName(this.familienName, this.vorName);
    }

    public getDisplayGeburtsdatum(): string {
        const geburtsTagOrTermin = this.getGeburtsTagOrTermin();

        return DvbDateUtil.isValidMoment(geburtsTagOrTermin) ? geburtsTagOrTermin.format('D. MMMM YYYY') : '';
    }

    public getGeburtsTagOrTermin(): moment.Moment | null {
        return this.geburtsTag ? this.geburtsTag : this.geburtsTermin;
    }

    public getGeburtsdatum(): moment.Moment | null {
        return this.getGeburtsTagOrTermin();
    }

    public isGeburtsDatumTerminSet(): boolean {
        return !!this.geburtsTermin || !!this.geburtsTag;
    }

    public hasGeschwister(): boolean {
        return this.geschwister.length > 0;
    }

    public hasValidHauptkontakt(): boolean {
        const relationships = KindKontakteUtil.findHauptkontaktRelationshipsWithKontaktperson(this.kontakte);

        // TODO HauptKontakt Validierung implementieren, falls sie von den anderen Personen abweicht.

        return relationships.length === 1;
    }

    public findAnyHauptkontaktRelationship(): RelationshipWithKontaktperson | null {
        const relationships = KindKontakteUtil.findHauptkontaktRelationshipsWithKontaktperson(this.kontakte);

        return relationships.length > 0 ? relationships[0] : null;
    }

    public findRechnungsempfaenger(): Rechnungsempfaenger[] {
        return KindKontakteUtil.findRechnungsempfaenger(this.kontakte);
    }

    public findErziehungsberechtigte(): Erziehungsberechtigter[] {
        return KindKontakteUtil.findErziehungsberechtigte(this.kontakte);
    }

    public findAbholberechtigt(): Abholberechtigt[] {
        return KindKontakteUtil.findAbholberechtigt(this.kontakte);
    }

    public removeKontakt(kontakt: RelationshipWithKontaktperson): void {
        DvbUtil.removeFromArray(kontakt, this.kontakte);
    }

    public removeGeschwister(geschwister: Kind): void {
        DvbUtil.removeFromArray(geschwister, this.geschwister);
    }

    public isAnhangValid(): boolean {
        return this.anhaengeAllgemein.every(anhang => anhang.errorMessage === null)
            && this.anhaengeVertraulich.every(anhang => anhang.errorMessage === null);
    }

    public hasFamilienName(): boolean {
        return angular.isString(this.familienName) && this.familienName.length > 0;
    }

    public isValid(): boolean {
        return this.hasFamilienName() && this.isGeburtsDatumTerminSet() &&
            this.hasValidHauptkontakt() && this.isAnhangValid();
    }

    public hasValidBasisData(): boolean {
        return this.hasFamilienName() && this.isGeburtsDatumTerminSet() && this.isAnhangValid();
    }

    public removeBelegung(belegung: Belegung): void {
        // GueltigBis der vorherigen Belegung anpassen
        const orderedBelegungen = DvbDateUtil.sortLimitedEntitiesByGueltigAbAsc(this.belegungen);

        const indexOrdered = orderedBelegungen.indexOf(belegung);
        if (indexOrdered > 0) {
            const vorherigeBelegung = orderedBelegungen[indexOrdered - 1];

            // gueltigBis wird nur angepasst, wenn die Gültigkeiten angrenzend sind und es keine MonatsBelegung ist.
            if (!vorherigeBelegung.monatsBelegungId &&
                DvbDateUtil.isGueltigBisDayBefore(vorherigeBelegung, belegung)) {

                vorherigeBelegung.gueltigBis = belegung.gueltigBis;
                vorherigeBelegung.austrittProvisorisch = belegung.austrittProvisorisch;
            }
        }
        // Belegung entfernen
        DvbUtil.removeFromArray(belegung, this.belegungen);
    }

    /**
     * @param belegungen all Belegungen belonging to the same MonatsBelegung
     */
    public removeMonatsBelegung(belegungen: Belegung[]): void {
        const orderedMonatsBelegungen = DvbDateUtil.sortLimitedEntitiesByGueltigAbAsc(belegungen);
        const beforeMonth = moment(orderedMonatsBelegungen[0].gueltigAb).subtract(1, 'day');

        const vorherigeBelegung = DvbDateUtil.getEntityOn(this.belegungen, beforeMonth);
        if (vorherigeBelegung && !vorherigeBelegung.monatsBelegungId) {
            // extend Gueltigkeit
            DvbDateUtil.endOfMonth(checkPresent(vorherigeBelegung.gueltigBis).add(1, 'day'));
        }

        // Belegung entfernen
        const allBelegungen = this.belegungen;

        belegungen.forEach(belegung => {
            DvbUtil.removeFromArray(belegung, allBelegungen);
        });
    }

    public isAusgetreten(stichtag?: moment.Moment): boolean {
        if (this.belegungen.length === 0) {
            return false;
        }

        const gueltigOn = stichtag ?? END_OF_TIME;

        return !this.belegungen.some(b => DvbDateUtil.isGueltigOn(b, gueltigOn));
    }

    public toRestObject(withRelations: boolean): Record<string, unknown> {
        const restObject: Record<string, unknown> = {
            id: this.id,
            vorName: this.vorName,
            familienName: this.familienName,
            bemerkung: this.bemerkung,
            geburtsTag: DvbRestUtil.momentToLocalDate(this.geburtsTag),
            geburtsTermin: DvbRestUtil.momentToLocalDate(this.geburtsTermin),
            geschlecht: this.geschlecht,
            anmeldeDatum: DvbRestUtil.momentToLocalDate(this.anmeldeDatum),
            platzbestaetigungDeactivated: this.platzbestaetigungDeactivated,
            sozialversicherungsNummer: this.sozialversicherungsNummer,
            muttersprache: this.muttersprache ? this.muttersprache.toRestObject() : null,
            nationalitaet: this.nationalitaet ? this.nationalitaet.toRestObject() : null,
        };

        if (withRelations) {
            restObject.adressen = this.adressen.map(a => a.toRestObject());

            restObject.kontakte = this.kontakte.map(k => k.toRestObject());
            restObject.betreuungsGruendeIds = this.betreuungsGruendeIds;
            restObject.geschwisterIds = this.geschwister.map(g => g.id);
            restObject.anhaengeAllgemeinTempBlobIds = UploadTempBlob.getTempBlobIds(this.anhaengeAllgemein);
            restObject.anhaengeVertraulichTempBlobIds = UploadTempBlob.getTempBlobIds(this.anhaengeVertraulich);

            if (this.bewerbung) {
                restObject.bewerbung = this.bewerbung.toRestObject(true);
            }
        }

        return restObject;
    }

}
