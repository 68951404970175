/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {BetreuungsZeitraum, DvbRestUtil, isPresent} from '@dv/shared/code';
import type moment from 'moment';
import {CustomFieldValueNotNamed} from '../../customfield/CustomFieldValueNotNamed';
import {AnwesenheitCustomField} from './AnwesenheitCustomField';

export class AnwesenheitsZeit implements IRestModel, IPersistable {
    public constructor(
        public id: string | null = null,
        public datum: moment.Moment | null = null,
        public betreuungsZeitraumId: string | null = null,
        public von: moment.Moment | null = null,
        public bis: moment.Moment | null = null,
        public betreuungsZeitraum: BetreuungsZeitraum | null = null,
        public customFieldValues: CustomFieldValueNotNamed[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): AnwesenheitsZeit {
        const betreuungsZeitraum = data.betreuungsZeitraum ?
            BetreuungsZeitraum.apiResponseTransformer(data.betreuungsZeitraum) :
            null;

        return new AnwesenheitsZeit(
            data.id,
            DvbRestUtil.localDateToMoment(data.datum),
            data.betreuungsZeitraumId,
            DvbRestUtil.localeHHMMTimeToMoment(data.von),
            DvbRestUtil.localeHHMMTimeToMoment(data.bis),
            betreuungsZeitraum,
            CustomFieldValueNotNamed.apiResponseTransformer(data.customFieldValues, AnwesenheitCustomField),
        );
    }

    public toRestObject(): Record<string, unknown> {

        const customFieldValues = this.customFieldValues.filter(val => isPresent(val.value.value))
            .map(val => val.toRestObject());

        return {
            id: this.id,
            datum: DvbRestUtil.momentToLocalDate(this.datum),
            betreuungsZeitraumId: this.betreuungsZeitraumId,
            von: DvbRestUtil.momentTolocaleHHMMTime(this.von),
            bis: DvbRestUtil.momentTolocaleHHMMTime(this.bis),
            customFieldValues,
        };
    }
}
