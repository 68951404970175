/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxEmailVersandProperties} from '@dv/shared/backend/model/jax-email-versand-properties';
import type {IRestModel} from '@dv/shared/code';

export class EmailVersandProperties implements IRestModel<JaxEmailVersandProperties, [boolean]> {

    public constructor(
        public replyToAddress: string | null = null,
        public bccAddress: string | null = null,
        public sendEmails: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: JaxEmailVersandProperties): EmailVersandProperties {
        return new EmailVersandProperties(data.replyToAddress, data.bccAddress, !!data.sendEmails);
    }

    public toRestObject(replyToAsBcc: boolean = true): JaxEmailVersandProperties {
        return {
            replyToAddress: this.replyToAddress ?? undefined,
            bccAddress: (replyToAsBcc ? this.bccAddress ?? this.replyToAddress : this.bccAddress) ?? undefined,
            sendEmails: this.sendEmails,
        };
    }
}
