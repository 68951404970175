/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {BetreuungsGutschein} from './BetreuungsGutschein';
import type {IKibonRefNrInfo} from './IKibonRefNrInfo';
import {KiTaxSchema} from './KiTaxSchema';

export class BetreuungsGutscheinGroup implements IPersistable, ILimited, IKibonRefNrInfo {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public kinderOrtId: string | null = null,
        public schemaVersion: KiTaxSchema | null = null,
        public kiTaxId: string | null = null,
        public referenzNummer: string | null = null,
        public verfuegtAm: moment.Moment | null = null,
        public timestampErstellt: moment.Moment | null = null,
        public betreuungsGutscheine: BetreuungsGutschein[] = [],
        public auszahlungAnEltern: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): BetreuungsGutscheinGroup {
        const answer = new BetreuungsGutscheinGroup();
        answer.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        answer.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis);
        answer.kinderOrtId = data.kinderOrtId;
        answer.schemaVersion = data.schemaVersion ? KiTaxSchema.apiResponseTransformer(data.schemaVersion) : null;
        answer.kiTaxId = data.kiTaxId;
        answer.referenzNummer = data.referenzNummer;

        answer.verfuegtAm = DvbRestUtil.localDateTimeToMoment(data.verfuegtAm);
        answer.timestampErstellt = DvbRestUtil.localDateTimeToMoment(data.timestampErstellt);

        answer.auszahlungAnEltern = data.auszahlungAnEltern;

        if (Array.isArray(data.betreuungsGutscheine)) {
            answer.betreuungsGutscheine = data.betreuungsGutscheine.map(
                (betreuungsGutschein: any) => BetreuungsGutschein.apiResponseTransformer(betreuungsGutschein));
        }

        return answer;
    }

    public getRefNr(): string {
        return checkPresent(this.referenzNummer);
    }
}
