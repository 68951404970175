/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IEntityList, IPersistable, IRestModel, ISearchable, IValidable} from '@dv/shared/code';
import {checkPresent, DvbUtil, SearchResultEntry} from '@dv/shared/code';
import {Translation} from '../i18n/Translation';
import {DokumentLayoutLogo} from './DokumentLayoutLogo';
import {DokumentLayoutPageMargin} from './DokumentLayoutPageMargin';
import {DokumentLayoutTextBlock} from './DokumentLayoutTextBlock';

export interface DokumentLayoutDefaults {
    PAGE_MARGIN: { TOP: number; BOTTOM: number; LEFT: number; RIGHT: number };
    RECEIVER_ADDRESS: { LEFT: number; TOP: number };
    SENDER_ADDRESS: { LEFT: number; TOP: number };
    FOOTER: { LEFT: number; TOP: number };
}

export class DokumentLayout implements IPersistable, IEntityList, ISearchable, IRestModel, IValidable {

    public static defaultValues: DokumentLayoutDefaults = {
        PAGE_MARGIN: {
            TOP: 100,
            BOTTOM: 15,
            LEFT: 20,
            RIGHT: 20,
        },
        RECEIVER_ADDRESS: {
            LEFT: 140,
            TOP: 50,
        },
        SENDER_ADDRESS: {
            LEFT: 140,
            TOP: 20,
        },
        FOOTER: {
            LEFT: 34,
            TOP: 285,
        },
    };

    public static defaultCompactValues: DokumentLayoutDefaults = {
        ...DokumentLayout.defaultValues,
        ...{
            PAGE_MARGIN: {
                TOP: 90,
                BOTTOM: 15,
                LEFT: 20,
                RIGHT: 20,
            },
        },
    };

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public pageMargin: DokumentLayoutPageMargin | null = null,
        public logo: DokumentLayoutLogo | null = null,
        public receiverAddress: DokumentLayoutTextBlock | null = null,
        public senderAddress: DokumentLayoutTextBlock | null = null,
        public footer: DokumentLayoutTextBlock | null = null,
        public isKompakt: boolean = false,
        public steuerbescheinigungAlternativ: Translation | null = null,
        public steuerbescheinigungBemerkung: Translation | null = null,
        public massenversandText: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): DokumentLayout {
        const answer = new DokumentLayout();

        answer.id = data.id;
        answer.name = data.name;
        answer.massenversandText = data.massenversandText;

        if (data.pageMargin) {
            answer.pageMargin = DokumentLayoutPageMargin.apiResponseTransformer(data.pageMargin);
        }
        if (data.logo) {
            answer.logo = DokumentLayoutLogo.apiResponseTransformer(data.logo);
        }
        if (data.receiverAddress) {
            answer.receiverAddress = DokumentLayoutTextBlock.apiResponseTransformer(data.receiverAddress);
        }
        if (data.senderAddress) {
            answer.senderAddress = DokumentLayoutTextBlock.apiResponseTransformer(data.senderAddress);
        }
        if (data.footer) {
            answer.footer = DokumentLayoutTextBlock.apiResponseTransformer(data.footer);
        }
        if (data.isKompakt) {
            answer.isKompakt = data.isKompakt;
        }

        answer.steuerbescheinigungAlternativ = data.steuerbescheinigungAlternativ ?
            Translation.apiResponseTransformer(data.steuerbescheinigungAlternativ) :
            new Translation();

        answer.steuerbescheinigungBemerkung = data.steuerbescheinigungBemerkung ?
            Translation.apiResponseTransformer(data.steuerbescheinigungBemerkung) :
            new Translation();

        return answer;
    }

    private static isNullOrValid(value: IValidable | null): boolean {
        return value === null || value.isValid();
    }

    private static applyDefaultIfNull(object: { [index: string]: any }, key: string, defaultValue: any): void {
        object[key] = DokumentLayout.valueOrDefault(object[key], defaultValue);
    }

    /**
     * @return returns the value when it is not null, otherwise returns the defaultValue
     */
    private static valueOrDefault(value: any, defaultValue: any): any {
        return value === null ? defaultValue : value;
    }

    public getDisplayName(): string {
        return this.name!;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            pageMargin: this.pageMargin ? this.pageMargin.toRestObject() : null,
            logo: this.logo ? this.logo.toRestObject() : null,
            receiverAddress: this.receiverAddress ? this.receiverAddress.toRestObject() : null,
            senderAddress: this.senderAddress ? this.senderAddress.toRestObject() : null,
            footer: this.footer ? this.footer.toRestObject() : null,
            isKompakt: this.isKompakt,
            steuerbescheinigungAlternativ: this.steuerbescheinigungAlternativ?.hasTranslations() ?
                this.steuerbescheinigungAlternativ.toRestObject() :
                null,
            steuerbescheinigungBemerkung: this.steuerbescheinigungBemerkung?.hasTranslations() ?
                this.steuerbescheinigungBemerkung.toRestObject() :
                null,
            massenversandText: this.massenversandText,
        };
    }

    public toSearchResultEntry(): SearchResultEntry {
        return new SearchResultEntry('DOKUMENT_LAYOUT', this.id!, this.getDisplayName());
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public badgeText(): string | undefined {
        return undefined;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public hasOnlyDefaults(): boolean {
        return this.pageMargin === null &&
            this.logo === null &&
            this.receiverAddress === null &&
            this.senderAddress === null &&
            this.footer === null;
    }

    public applyDefaults(): void {
        const defaultValues = this.isKompakt ? DokumentLayout.defaultCompactValues : DokumentLayout.defaultValues;

        if (this.pageMargin) {
            DokumentLayout.applyDefaultIfNull(this.pageMargin, 'top', defaultValues.PAGE_MARGIN.TOP);
            DokumentLayout.applyDefaultIfNull(this.pageMargin, 'bottom', defaultValues.PAGE_MARGIN.BOTTOM);
            DokumentLayout.applyDefaultIfNull(this.pageMargin, 'left', defaultValues.PAGE_MARGIN.LEFT);
            DokumentLayout.applyDefaultIfNull(this.pageMargin, 'right', defaultValues.PAGE_MARGIN.RIGHT);
        }

        if (this.receiverAddress) {
            DokumentLayout.applyDefaultIfNull(this.receiverAddress, 'top', defaultValues.RECEIVER_ADDRESS.TOP);
            DokumentLayout.applyDefaultIfNull(this.receiverAddress, 'left', defaultValues.RECEIVER_ADDRESS.LEFT);
        }

        if (this.senderAddress) {
            DokumentLayout.applyDefaultIfNull(this.senderAddress, 'top', defaultValues.SENDER_ADDRESS.TOP);
            DokumentLayout.applyDefaultIfNull(this.senderAddress, 'left', defaultValues.SENDER_ADDRESS.LEFT);
        }

        if (this.footer) {
            DokumentLayout.applyDefaultIfNull(this.footer, 'top', defaultValues.FOOTER.TOP);
            DokumentLayout.applyDefaultIfNull(this.footer, 'left', defaultValues.FOOTER.LEFT);
        }
    }

    public isValid(): boolean {
        return DvbUtil.isNotEmptyString(this.name) &&
            DokumentLayout.isNullOrValid(this.pageMargin) &&
            DokumentLayout.isNullOrValid(this.logo) &&
            DokumentLayout.isNullOrValid(this.receiverAddress) &&
            DokumentLayout.isNullOrValid(this.senderAddress) &&
            DokumentLayout.isNullOrValid(this.footer);
    }
}
