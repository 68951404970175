/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, SupportedLanguage} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';

export class Translation implements IPersistable {

    public constructor(
        public id: string | null = null,
        public texts: { [language in SupportedLanguage]?: string } = {},
    ) {
    }

    public static apiResponseTransformer(data: any): Translation {
        const texts: any = {};
        Object.entries(data.texts).forEach(([lang, text]) => {
            texts[lang] = text;
        });

        return new Translation(data.id, texts);
    }

    public hasTranslations(): boolean {
        return Object.values(this.texts).some(text => !DvbUtil.isEmptyString(text));
    }

    public toRestObject(): Record<string, unknown> {
        const texts: any = {};
        Object.entries(this.texts).forEach(([lang, text]) => {
            texts[lang] = text;
        });

        return {
            id: this.id,
            texts,
        };
    }
}
