/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import {Translation} from '../i18n/Translation';
import {WochenplanExternalAnmeldung} from './WochenplanExternalAnmeldung';

export class ExternalAnmeldungConfig implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public googleTagManagerContainerId: string | null = null,
        public redirectUrl: string | null = null,
        public agbUrl: string | null = null,
        public customStyle: string | null = null,
        public replyTo: string | null = null,
        public infoEmail: string | null = null,
        public showPensum: boolean = false,
        public showPrivaterPlatz: boolean = true,
        public showSubventionierterPlatz: boolean = true,
        public privaterPlatzTranslation: Translation | null = null,
        public subventionierterPlatzTranslation: Translation | null = null,
        public bemerkungTranslation: Translation | null = null,
        public wochenplaene: WochenplanExternalAnmeldung[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): ExternalAnmeldungConfig {
        return new ExternalAnmeldungConfig(
            data.id,
            data.googleTagManagerContainerId,
            data.redirectUrl,
            data.agbUrl,
            data.customStyle,
            data.replyTo,
            data.infoEmail,
            data.showPensum,
            data.showPrivaterPlatz,
            data.showSubventionierterPlatz,
            data.privaterPlatzTranslation ?
                Translation.apiResponseTransformer(data.privaterPlatzTranslation) :
                new Translation(),
            data.subventionierterPlatzTranslation ?
                Translation.apiResponseTransformer(data.subventionierterPlatzTranslation) :
                new Translation(),
            data.bemerkungTranslation ?
                Translation.apiResponseTransformer(data.bemerkungTranslation) :
                new Translation(),
            DvbRestUtil.transformArray(data.wochenplaene, WochenplanExternalAnmeldung));
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            googleTagManagerContainerId: this.googleTagManagerContainerId,
            redirectUrl: this.redirectUrl,
            agbUrl: this.agbUrl,
            customStyle: this.customStyle,
            replyTo: this.replyTo,
            infoEmail: this.infoEmail,
            showPensum: this.showPensum,
            showPrivaterPlatz: this.showPrivaterPlatz,
            showSubventionierterPlatz: this.showSubventionierterPlatz,
            privaterPlatzTranslation: this.privaterPlatzTranslation?.toRestObject(),
            subventionierterPlatzTranslation: this.subventionierterPlatzTranslation?.toRestObject(),
            bemerkungTranslation: this.bemerkungTranslation?.toRestObject(),
            wochenplaene: this.wochenplaene.map(plan => plan.toRestObject()),
        };
    }
}
