/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ICustomFieldValue, INameGeburtsdatumGeschlecht} from '@dv/shared/code';
import {DvbUtil, Geschlecht} from '@dv/shared/code';
import type moment from 'moment';
import type {AdresseWithoutGemeinde} from '../adressen/AdresseWithoutGemeinde';

export class AnmeldungKindKontaktperson implements INameGeburtsdatumGeschlecht {

    public constructor(
        public vorName: string,
        public familienName: string,
        public geburtsdatum: moment.Moment | null,
        public geschlecht: Geschlecht = Geschlecht.UNBEKANNT,
        public email: string | null = null,
        public telefon: string | null = null,
        public mobile: string | null = null,
        public geschaeft: string | null = null,
        public organisation: string = '',
        public isHauptKontakt: boolean = false,
        public address: AdresseWithoutGemeinde | null = null,
        public customFieldValues: ICustomFieldValue[] = [],
    ) {
    }

    public getDisplayName(): string {
        return [this.organisation, `${this.familienName} ${this.vorName}`]
            .filter(s => DvbUtil.isNotEmptyString(s.trim()))
            .join(', ');
    }

    public getGeburtsdatum(): moment.Moment | null {
        return this.geburtsdatum;
    }
}
