/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type TarifParameterType = 'GLOBAL_PARAM' | 'MONATS_BASIERT';

export const TarifParameterType = {
    GLOBAL_PARAM: 'GLOBAL_PARAM' as TarifParameterType,
    MONATS_BASIERT: 'MONATS_BASIERT' as TarifParameterType
};

