/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {RechnungsStatus} from './RechnungsStatus';

export enum RechnungsStatusUpdateTrigger {
    RECHNUNGSLAUF = 'RECHNUNGSLAUF',
    ZAHLUNGS_EINGANG = 'ZAHLUNGS_EINGANG',
    ZAHLUNGS_STORNIERUNG = 'ZAHLUNGS_STORNIERUNG',
    EXTERN = 'EXTERN',
    USER_UPDATE = 'USER_UPDATE',
    KONFIGURATION_DEAKTIVIERT = 'KONFIGURATION_DEAKTIVIERT',
}

export class RechnungsStatusUpdate {

    public static readonly TRIGGER: typeof RechnungsStatusUpdateTrigger = RechnungsStatusUpdateTrigger;

    public constructor(
        public readonly id: string,
        public readonly externalStatus: Readonly<RechnungsStatus>,
        public readonly trigger: RechnungsStatusUpdateTrigger,
        public readonly rechnungsRevisionId: string,
        public readonly benutzerDisplayName: string,
        public readonly username: string,
        public readonly time: moment.Moment) {
    }

    public static apiResponseTransformer(data: any): RechnungsStatusUpdate {
        return new RechnungsStatusUpdate(
            data.id,
            RechnungsStatus.apiResponseTransformer(data.externalStatus),
            data.trigger,
            data.rechnungsRevisionId,
            data.benutzerDisplayName,
            data.username,
            DvbRestUtil.localDateTimeToMoment(data.time)!);
    }

}
