/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable, IRestModel, IValidable} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class StundenKontingent implements IRestModel, IPersistable, ILimited, IValidable {

    public constructor(
        public id: string | null = null,
        public kindId: string | null = null,
        public kinderOrtId: string | null = null,
        public kontingentId: string | null = null,
        public stunden: number | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): StundenKontingent {
        return new StundenKontingent(data.id,
            data.kindId,
            data.kinderOrtId,
            data.kontingentId,
            data.stunden,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
        );
    }

    public toRestObject(): Record<string, unknown> {
        return {
            kindId: this.kindId,
            kinderOrtId: this.kinderOrtId,
            kontingentId: this.kontingentId,
            stunden: this.stunden,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
        };
    }

    public isValid(): boolean {
        return !!this.kindId &&
            !!this.kinderOrtId &&
            DvbDateUtil.isValidMoment(this.gueltigAb) &&
            DvbDateUtil.isValidMoment(this.gueltigBis) &&
            !!this.stunden;
    }
}
