/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek, IPersistable, IRestModel} from '@dv/shared/code';
import {BelegungsEinheit} from '../kinderort/BelegungsEinheit';
import type {KontingentId} from '../kinderort/kontingente/AbstractKontingent';
import type {Kontingente} from '../kinderort/kontingente/KontingentTransformer';
import {KontingentTransformer} from '../kinderort/kontingente/KontingentTransformer';

export type PlatzTyp = KontingentId | null;

export class Platz implements IRestModel, IPersistable {

    public constructor(
        public id: string | null = null,
        public wochentag: DayOfWeek | null = null,
        public belegungsEinheit: BelegungsEinheit | null = null,
        public belegungsEinheitId: string | null = null,
        public kontingent: Kontingente | null = null,
        public kontingentId: PlatzTyp = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Platz {
        const answer = new Platz(
            data.id,
            data.wochentag,
        );

        if (data.belegungsEinheit) {
            answer.belegungsEinheit = BelegungsEinheit.apiResponseTransformer(data.belegungsEinheit);
            answer.belegungsEinheitId = data.belegungsEinheit.id;
        } else {
            answer.belegungsEinheitId = data.belegungsEinheitId;
        }

        if (data.kontingent) {
            answer.kontingent = KontingentTransformer.apiResponseTransformer(data.kontingent);
            answer.kontingentId = answer.kontingent.id;
        } else {
            answer.kontingentId = data.kontingentId;
        }

        return answer;
    }

    public hasKontingent(): boolean {
        return !!(this.kontingentId ?? this.kontingent);
    }

    public toRestObject(): Record<string, unknown> {
        const restObject: {
            id: string | null;
            wochentag: DayOfWeek | null;
            belegungsEinheitId: null | string;
            kontingentId: null | string;
        } = {
            id: this.id,
            wochentag: this.wochentag,
            belegungsEinheitId: null,
            kontingentId: null,
        };

        if (this.belegungsEinheitId) {
            restObject.belegungsEinheitId = this.belegungsEinheitId;
        } else if (this.belegungsEinheit) {
            restObject.belegungsEinheitId = this.belegungsEinheit.id;
        }

        if (this.kontingentId) {
            restObject.kontingentId = this.kontingentId;
        } else if (this.kontingent) {
            restObject.kontingentId = this.kontingent.id;
        }

        return restObject;
    }
}
