/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxRueckerstattung} from '@dv/shared/backend/model/jax-rueckerstattung';
import type {IRestModel} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {AbstractKontaktpersonZahlung} from './AbstractKontaktpersonZahlung';

export class Rueckerstattung extends AbstractKontaktpersonZahlung implements IRestModel<JaxRueckerstattung> {

    public constructor(
        public auszahlungsDatum: moment.Moment | null = null,
        public gebuehrId: string | null = null,
    ) {
        super();
    }

    public static apiResponseTransformer(data: JaxRueckerstattung): Rueckerstattung {
        const auszahlungsDatum = DvbRestUtil.localDateToMoment(data.auszahlungsDatum);
        const gebuehrId = data.gebuehrId ? data.gebuehrId : null;

        return super.baseTransformer(data, new Rueckerstattung(auszahlungsDatum, gebuehrId));
    }

    public override toRestObject(): JaxRueckerstattung {
        return {
            ...super.toRestObject(),
            gebuehrId: this.gebuehrId ?? undefined,
        };
    }
}
