/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Betreuungsfaktor} from '../betreuungsfaktor/Betreuungsfaktor';

export type BetreuungsfaktorByKindId = { [kindId: string]: Betreuungsfaktor };

export class KinderBetreuungsfaktoren {

    public constructor(public faktoren: BetreuungsfaktorByKindId = {}) {
    }

    public static apiResponseTransformer(data: any): KinderBetreuungsfaktoren {
        const answer = new KinderBetreuungsfaktoren();

        Object.keys(data.faktoren).forEach(id => {
            answer.faktoren[id] = Betreuungsfaktor.apiResponseTransformer(data.faktoren[id]);
        });

        return answer;
    }
}
