/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class BankStatement implements IPersistable, ILimited {
    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public einleseDatum: moment.Moment | null = null,
        public filename: string | null = null,
        public benutzerDisplayName: string | null = null,
        public username: string | null = null,
        public entryCount: number = 0,
        public assignedCount: number = 0,
    ) {
    }

    public static apiResponseTransformer(data: any): BankStatement {
        const answer = new BankStatement();
        answer.id = data.id;
        answer.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        answer.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis);
        answer.einleseDatum = DvbRestUtil.localDateTimeToMoment(data.einleseDatum);
        answer.filename = data.filename;
        answer.benutzerDisplayName = data.benutzerDisplayName;
        answer.username = data.username;
        answer.entryCount = parseInt(data.entryCount, 10);
        answer.assignedCount = parseInt(data.assignedCount, 10);

        return answer;
    }
}
