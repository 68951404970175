/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {MatchingState} from '@dv/shared/backend/model/matching-state';
import type {IPersistable} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {BetreuungsGutschein} from './BetreuungsGutschein';
import type {IKibonRefNrInfo} from './IKibonRefNrInfo';
import type {KibonExchangeEntity} from './KibonExchangeEntity';
import {KibonExchangeGesuchsteller} from './KibonExchangeGesuchsteller';
import {KibonExchangeInstitution} from './KibonExchangeInstitution';
import {KibonExchangeKind} from './KibonExchangeKind';
import {KiTaxSchema} from './KiTaxSchema';

export class KibonExchangeVerfuegung implements IPersistable, KibonExchangeEntity, IKibonRefNrInfo {

    public constructor(
        public id: string,
        public kiTaxId: string | null,
        public institution: KibonExchangeInstitution,
        public gueltigAb: moment.Moment,
        public gueltigBis: moment.Moment,
        public verfuegungVersion: number,
        public verfuegtAm: moment.Moment,
        public timestampErstellt: moment.Moment,
        public state: MatchingState,
        public kind: KibonExchangeKind,
        public gesuchsteller: KibonExchangeGesuchsteller,
        public betreuungsGutscheine: BetreuungsGutschein[] = [],
        public schemaVersion: KiTaxSchema,
        public refNr: string,
        public assignedKindId: string | null = null,
        public auszahlungAnEltern: boolean = false,
        public assignable: boolean = false,
        public unassignable: boolean = false,
        public ignorable: boolean = false,
        public unignorable: boolean = false,
    ) {
    }

    public get kibonExportAm(): moment.Moment {
        return this.verfuegtAm;
    }

    public static apiResponseTransformer(data: any): KibonExchangeVerfuegung {
        let betreuungsGutscheine = [];

        if (Array.isArray(data.betreuungsGutscheine)) {
            betreuungsGutscheine = data.betreuungsGutscheine.map((zeitabschnitt: any) =>
                BetreuungsGutschein.apiResponseTransformer(zeitabschnitt));
        }

        return new KibonExchangeVerfuegung(
            data.id,
            data.kiTaxId,
            KibonExchangeInstitution.apiResponseTransformer(data.institution),
            checkPresent(DvbRestUtil.localDateTimeToMoment(data.von)),
            checkPresent(DvbRestUtil.localDateTimeToMoment(data.bis)),
            parseInt(data.verfuegungVersion, 10),
            checkPresent(DvbRestUtil.localDateTimeToMoment(data.verfuegtAm)),
            checkPresent(DvbRestUtil.localDateTimeToMoment(data.timestampErstellt)),
            data.matchingState,
            KibonExchangeKind.apiResponseTransformer(data.kind),
            KibonExchangeGesuchsteller.apiResponseTransformer(data.gesuchsteller),
            betreuungsGutscheine,
            KiTaxSchema.apiResponseTransformer(data.schemaVersion),
            data.refNr,
            data.assignedKindId,
            data.auszahlungAnEltern,
            data.assignable,
            data.unassignable,
            data.ignorable,
            data.unignorable,
        );
    }

    public canAssign(): boolean {
        return this.assignable;
    }

    public canUnAssign(): boolean {
        return this.unassignable;
    }

    public canIgnore(): boolean {
        return this.ignorable;
    }

    public canUnIgnore(): boolean {
        return this.unignorable;
    }

    public getRefNr(): string {
        return this.refNr;
    }

    public canDecline(): boolean {
        return false;
    }

    public canAnmelden(): boolean {
        return false;
    }
}
