/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IEntityList, IPersistable, IRestModel, ISearchable} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbUtil, SearchResultEntry} from '@dv/shared/code';
import type moment from 'moment';
import {ServiceContainer} from '../ServiceContainer';
import {TarifParameter} from './TarifParameter';
import {TarifType} from './TarifType';
import {TarifValue} from './TarifValue';

export class Tarif implements IPersistable, IRestModel, ISearchable, IEntityList {

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public dtype: TarifType = TarifType.BETREUUNGS_ABHAENGIG,
        public bemerkung: string = '',
        public values: TarifValue[] = [],
        public parameters: TarifParameter[] = [],
        public currentSchemaVersion: number | null = null,
        public timestampGeloescht: moment.Moment | null = null,
        public nullTarif: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): Tarif {
        const tarif = new Tarif(data.id, data.name, data.dtype, data.bemerkung);
        tarif.currentSchemaVersion = data.currentSchemaVersion;
        tarif.timestampGeloescht = data.timestampGeloescht;
        tarif.nullTarif = data.nullTarif;

        if (Array.isArray(data.values)) {
            tarif.values = data.values.map((v: any) => TarifValue.apiResponseTransformer(v));
        }

        if (Array.isArray(data.parameters)) {
            tarif.parameters = data.parameters.map((p: any) => TarifParameter.apiResponseTransformer(p));
        }

        return tarif;
    }

    public removeTarifValue(tarifValue: TarifValue): void {
        DvbUtil.removeFromArray(tarifValue, this.values);
    }

    public addParameter(parameter: TarifParameter): void {
        if (!this.parameters.includes(parameter)) {
            this.parameters.push(parameter);
        }
    }

    public removeParameter(parameter: TarifParameter): void {
        DvbUtil.removeFromArray(parameter, this.parameters);
    }

    public getValueOn(aMoment: moment.Moment): TarifValue | null {
        return DvbDateUtil.getEntityOn(this.values, aMoment);
    }

    public isValid(): boolean {
        return typeof this.name === 'string' && this.name.length > 0;
    }

    /**
     * @return TRUE when Tarif has gueltige TarifValue at stichtag, FALSE otherwise
     */
    public isGueltigOn(aMomemt: moment.Moment): boolean {
        return DvbDateUtil.getEntitiesOn(this.values, aMomemt).length > 0;
    }

    public toRestObject(withRelations: boolean = false): any {
        const restObject: any = {
            id: this.id,
            name: this.name,
            bemerkung: this.bemerkung,
            dtype: this.dtype,
            nullTarif: this.nullTarif,
        };

        if (withRelations) {
            restObject.values = this.values.map(v => v.toRestObject());
            restObject.parameters = this.parameters.map(p => p.toRestObject());
        }

        restObject.timestampGeloescht = this.timestampGeloescht;

        return restObject;
    }

    public toSearchResultEntry(): SearchResultEntry {
        let additionalInfo = ServiceContainer.$translate.instant(`TARIF.TYPE.${this.dtype}`);
        if (this.currentSchemaVersion) {
            const params = {version: this.currentSchemaVersion};
            additionalInfo += `, ${ServiceContainer.$translate.instant('TARIF.TARIF_VALUE_VERSION', params)}`;
        }

        return new SearchResultEntry('TARIF', this.id!, this.name!, null, additionalInfo);
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public badgeText(): string | undefined {
        const additionalInfo = ServiceContainer.$translate.instant(`TARIF.TYPE.${this.dtype}`);
        if (this.currentSchemaVersion) {
            const params = {version: this.currentSchemaVersion};

            return `${additionalInfo}, ${ServiceContainer.$translate.instant('TARIF.TARIF_VALUE_VERSION', params)}`;
        }

        return additionalInfo;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public getDisplayName(): string {
        return this.name ?? '';
    }
}
