/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type JaxBetreuungInFerienzeit = 'KEINE_ANGABE' | 'FERIENZEIT' | 'SCHULZEIT';

export const JaxBetreuungInFerienzeit = {
    KEINE_ANGABE: 'KEINE_ANGABE' as JaxBetreuungInFerienzeit,
    FERIENZEIT: 'FERIENZEIT' as JaxBetreuungInFerienzeit,
    SCHULZEIT: 'SCHULZEIT' as JaxBetreuungInFerienzeit
};

