/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';

export abstract class AbstractKontaktperson implements IPersistable {

    protected constructor(
        public id: string | null,
        public vorName: string = '',
        public familienName: string = '',
        public organisation: string,
        public isOrganisation: boolean,
    ) {
    }

    public updateOrganisation(): void {
        if (!this.isOrganisation) {
            this.organisation = '';
        }
    }

    public isOrganisationRequired(): boolean {
        if (!this.isOrganisation) {
            return false;
        }

        return DvbUtil.isEmptyString(this.vorName) ||
            DvbUtil.isEmptyString(this.familienName);
    }

    public isNameRequired(): boolean {
        if (this.isOrganisation) {
            return DvbUtil.isEmptyString(this.organisation);
        }

        return true;
    }
}

