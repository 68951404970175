/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {OffenerPostenType} from '@dv/shared/backend/model/offener-posten-type';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {Gebuehr} from '../kontaktperson-zahlungen/Gebuehr';
import {Rueckerstattung} from '../kontaktperson-zahlungen/Rueckerstattung';
import {Vorauszahlung} from '../kontaktperson-zahlungen/Vorauszahlung';
import {Rechnung} from '../Rechnung';
import {Zahlung} from './Zahlung';

export class OffenerPosten {

    public constructor(
        public dtype: OffenerPostenType | null = null,
        public datum: moment.Moment | null = null,
        public haben: number | null = null,
        public soll: number | null = null,
        public saldo: number | null = null,
        public kitaId: string | null = null,
        public kitaName: string | null = null,
        public rechnung: Rechnung | null = null,
        public vorauszahlung: Vorauszahlung | null = null,
        public rueckerstattung: Rueckerstattung | null = null,
        public gebuehr: Gebuehr | null = null,
        public zahlung: Zahlung | null = null,
        public bemerkung: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): OffenerPosten {
        const answer = new OffenerPosten();
        answer.dtype = data.dtype;
        answer.datum = DvbRestUtil.localDateToMoment(data.datum);
        answer.haben = data.haben ? parseFloat(data.haben) : null;
        answer.soll = data.soll ? parseFloat(data.soll) : null;
        answer.saldo = parseFloat(data.saldo);
        answer.kitaId = data.kitaId;
        answer.kitaName = data.kitaName;
        answer.bemerkung = data.bemerkung;

        if (data.rechnung) {
            answer.rechnung = Rechnung.apiResponseTransformer(data.rechnung);
        }
        if (data.vorauszahlung) {
            answer.vorauszahlung = Vorauszahlung.apiResponseTransformer(data.vorauszahlung);
        }
        if (data.rueckerstattung) {
            answer.rueckerstattung = Rueckerstattung.apiResponseTransformer(data.rueckerstattung);
        }
        if (data.gebuehr) {
            answer.gebuehr = Gebuehr.apiResponseTransformer(data.gebuehr);
        }
        if (data.zahlung) {
            answer.zahlung = Zahlung.apiResponseTransformer(data.zahlung);
        }

        return answer;
    }

    public isVorauszahlung(): boolean {
        return this.dtype === OffenerPostenType.VORAUSZAHLUNG;
    }

    public isRueckerstattung(): boolean {
        return this.dtype === OffenerPostenType.RUECKERSTATTUNG;
    }

    public isGebuehr(): boolean {
        return this.dtype === OffenerPostenType.GEBUEHR;
    }

    public isRueckerstattbareGebuehr(): boolean {
        return this.isGebuehr() && checkPresent(this.gebuehr).isRueckerstattbareGebuehr();
    }

    public isDeletableGebuehr(): boolean {
        return this.isGebuehr() && checkPresent(this.gebuehr).isDeletableGebuehr();
    }

    public isEditableGebuehr(): boolean {
        return this.isGebuehr() && checkPresent(this.gebuehr).isEditableGebuehr();
    }
}
