/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';
import {Frequency} from 'rrule';
import {RRuleBisType} from './RRuleBisType';

export class RRuleFormModel {

    public static readonly MAX_REPETITIONS = 100;

    /**
     * @param frequenzTyp daily, weekly or monthly
     * @param interval the frequence of the repetition, e.g. every second week
     * @param bisType how the recurrence should end (fixed date, never, number of repetitions)
     * @param startUTC start date & time of the recurrence in "floating-time" / fake UTC
     * @param endUTC required date & time in "floating-time" when bisType is date dependent
     * @param repetitions the amount of repetitions; required when bisType is ANZAHL
     * @param endDateLocal convenience paramter in local-time, from which endUTC is derived if needed
     */
    public constructor(
        public frequenzTyp: Frequency = Frequency.DAILY,
        public interval: number = 1,
        public bisType: RRuleBisType = RRuleBisType.ANZAHL,
        public startUTC?: moment.Moment,
        public endUTC?: moment.Moment,
        public repetitions?: number,
        public endDateLocal?: moment.Moment,
    ) {
    }
}
