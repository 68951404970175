/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek} from '@dv/shared/code';
import {BelegungsEinheit} from '../kinderort/BelegungsEinheit';

export class BelegteEinheit {

    public constructor(
        public id: string | null = null,
        public wochentag: DayOfWeek | null = null,
        public belegungsEinheiten: BelegteEinheit[] = [],
        public belegungsEinheitenIds: string[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): BelegteEinheit {
        const answer = new BelegteEinheit(data.id, data.wochentag);

        if (Array.isArray(data.belegungsEinheiten) && data.belegungsEinheiten.length > 0) {
            answer.belegungsEinheiten = data.belegungsEinheiten.map((b: any) => {
                answer.belegungsEinheitenIds.push(b.id);

                return BelegungsEinheit.apiResponseTransformer(b);
            });
        } else if (Array.isArray(data.belegungsEinheitenIds)) {
            answer.belegungsEinheitenIds = data.belegungsEinheitenIds;
        }

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            wochentag: this.wochentag,
            belegungsEinheitenIds: this.belegungsEinheitenIds,
        };
    }
}
