/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, END_OF_TIME} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {AbrechnungTyp} from '../tarife/AbrechnungTyp';
import type {KinderOrt} from './KinderOrt';
import {KinderOrtTransformer} from './KinderOrtTransformer';
import {KitaAbrechnungTarif} from './KitaAbrechnungTarif';

export class KitaTarifeZeitraum implements IPersistable, ILimited, IRestModel {

    public constructor(
        public id: string | null = null,
        public kita: KinderOrt | null = null,
        public kitaId: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment = END_OF_TIME,
        public abrechnungTarife: KitaAbrechnungTarif[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): KitaTarifeZeitraum {
        const kitaTarifeZeitraum = new KitaTarifeZeitraum();
        kitaTarifeZeitraum.id = data.id;
        kitaTarifeZeitraum.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        kitaTarifeZeitraum.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis)!;
        kitaTarifeZeitraum.kitaId = data.kitaId;

        if (data.kita) {
            kitaTarifeZeitraum.kita = KinderOrtTransformer.create().apiResponseTransformer(data.kita);
            kitaTarifeZeitraum.kitaId = kitaTarifeZeitraum.kita.id;
        }

        if (Array.isArray(data.abrechnungTarife)) {
            kitaTarifeZeitraum.abrechnungTarife = data.abrechnungTarife
                .map((t: any) => KitaAbrechnungTarif.apiResponseTransformer(t));
        }

        return kitaTarifeZeitraum;
    }

    public isValid(): boolean {
        return DvbDateUtil.isValidMoment(this.gueltigAb);
    }

    public toRestObject(): Record<string, unknown> {
        const restObject: any = {};

        restObject.id = this.id;
        restObject.gueltigAb = DvbRestUtil.momentToLocalDate(this.gueltigAb);
        restObject.gueltigBis = DvbRestUtil.momentToLocalDate(this.gueltigBis);
        restObject.kitaId = this.kitaId;

        if (Array.isArray(this.abrechnungTarife)) {
            restObject.abrechnungTarife = this.abrechnungTarife.map(t => t.toRestObject());
        }

        return restObject;
    }

    public initMissingAbrechnungsTarife(): void {
        Object.values<Readonly<AbrechnungTyp>>(AbrechnungTyp.TYPES).forEach(abrechnungsTyp => {
            const found = this.abrechnungTarife
                .find(abrechnungsTarif => abrechnungsTarif.typ.name === abrechnungsTyp.name);

            if (!found) {
                const kitaAbrechnungTarif = new KitaAbrechnungTarif(null, abrechnungsTyp);
                this.abrechnungTarife.push(kitaAbrechnungTarif);
            }
        });
    }

    /**
     * Removes entries from abrechnungTarife with no Tarif.
     */
    public getCleanCopy(): this {
        const cleanTarifeZeitraum: KitaAbrechnungTarif[] = [];
        this.abrechnungTarife
            .filter(t => t.tarif)
            .forEach(t => cleanTarifeZeitraum.push(t));

        const me = angular.copy(this);
        me.abrechnungTarife = cleanTarifeZeitraum;

        return me;
    }
}
