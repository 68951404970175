/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TarifType} from './TarifType';

export type AbrechnungTypes =
    'PRIVAT'
    | 'SUBVENTIONIERT'
    | 'BETREUUNGSGUTSCHEIN'
    | 'BETREUUNGS_UNABHAENGIG'
    | 'PROVISORISCHER_BG';

export class AbrechnungTyp {

    public static readonly TYPES: { [k in AbrechnungTypes]: Readonly<AbrechnungTyp> } = {
        /* eslint-disable @typescript-eslint/no-magic-numbers */
        PRIVAT: new AbrechnungTyp('PRIVAT',
            0,
            TarifType.BETREUUNGS_ABHAENGIG,
            TarifType.BETREUUNGS_STUNDEN),
        SUBVENTIONIERT: new AbrechnungTyp('SUBVENTIONIERT',
            1,
            TarifType.BETREUUNGS_ABHAENGIG,
            TarifType.BETREUUNGS_STUNDEN),
        BETREUUNGSGUTSCHEIN: new AbrechnungTyp('BETREUUNGSGUTSCHEIN',
            2,
            TarifType.BETREUUNGS_ABHAENGIG,
            TarifType.BETREUUNGS_STUNDEN),
        BETREUUNGS_UNABHAENGIG: new AbrechnungTyp('BETREUUNGS_UNABHAENGIG',
            3,
            TarifType.BETREUUNGS_UNABHAENGIG),
        PROVISORISCHER_BG: new AbrechnungTyp('PROVISORISCHER_BG',
            4,
            TarifType.PROVISORISCHER_BG),
        /* eslint-enable @typescript-eslint/no-magic-numbers */
    };

    public tarifTypes: TarifType[];

    public constructor(
        public name: string,
        public index: number,
        ...tarifTypes: TarifType[]
    ) {
        this.tarifTypes = tarifTypes;
    }

    public static apiResponseTransformer(data: any): Readonly<AbrechnungTyp> {
        return AbrechnungTyp.TYPES[data as AbrechnungTypes];
    }
}
