/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxRechnungsempfaenger} from '@dv/shared/backend/model/jax-rechnungsempfaenger';
import {AbstractKindKontakt} from './AbstractKindKontakt';

export enum RechnungsAufteilungType {
    FIXBETRAG = 'FIXBETRAG',
    PROZENT = 'PROZENT',
}

export class Rechnungsempfaenger extends AbstractKindKontakt {

    public static readonly RECHNUNG_AUFTEILUNG_TYPE: typeof RechnungsAufteilungType = RechnungsAufteilungType;

    // TODO evtl. GUI anpassen, so dass anteilProzent, anteilFixbetrag genutzt wird statt value & type
    public constructor(
        public type: RechnungsAufteilungType,
        public value: number | null = null,
    ) {
        super();
    }

    public static apiResponseTransformer(data: JaxRechnungsempfaenger): Rechnungsempfaenger {
        let answer: Rechnungsempfaenger;

        if (data.anteilProzent) {
            answer = new Rechnungsempfaenger(RechnungsAufteilungType.PROZENT, data.anteilProzent);
        } else if (data.anteilFixbetrag) {
            answer = new Rechnungsempfaenger(RechnungsAufteilungType.FIXBETRAG, data.anteilFixbetrag);
        } else {
            throw new Error('Either anteilProzent or anteilFixbetrag has to be true');
        }

        return super.baseTransformer(data, answer);
    }

    public setFixbetrag(): void {
        this.type = RechnungsAufteilungType.FIXBETRAG;
    }

    public isFixbetrag(): boolean {
        return this.type === RechnungsAufteilungType.FIXBETRAG;
    }

    public setProzent(): void {
        this.type = RechnungsAufteilungType.PROZENT;
    }

    public isProzent(): boolean {
        return this.type === RechnungsAufteilungType.PROZENT;
    }

    public override toRestObject(): JaxRechnungsempfaenger {
        return {
            ...super.toRestObject(),
            anteilFixbetrag: this.isFixbetrag() ? this.value ?? undefined : undefined,
            anteilProzent: this.isProzent() ? this.value ?? undefined : undefined,
        };
    }
}
