/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export class KitaRechnungsKonfigurationEditStats {

    public constructor(
        public totalRechnungenAffected: number = 0,
        public excludedRechnungen: number = 0,
    ) {
    }

    public static apiResponseTransformer(data: any): KitaRechnungsKonfigurationEditStats {
        const answer = new KitaRechnungsKonfigurationEditStats();
        answer.totalRechnungenAffected = parseInt(data.totalRechnungenAffected, 10);
        answer.excludedRechnungen = parseInt(data.excludedRechnungen, 10);

        return answer;
    }
}
