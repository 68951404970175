/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AddressMode} from '@dv/shared/backend/model/address-mode';
import type {JaxKontaktperson} from '@dv/shared/backend/model/jax-kontaktperson';
import type {VersandartRechnung} from '@dv/shared/backend/model/versandart-rechnung';
import type {Geschlecht, IEntityList, IPersistable, IRestModel, ISearchable, Persisted} from '@dv/shared/code';
import {checkPersisted, checkPresent, DvbDateUtil, DvbUtil, isPresent, SearchResultEntry} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import {LimitedAdresse} from '../adressen/LimitedAdresse';
import {CustomFieldValue} from '../customfield/CustomFieldValue';
import {IsoLanguagesAndCountriesService} from '../isoLanguagesAndCountriesService';
import type {Language} from '../locale/Language';
import {AbstractKontaktperson} from './AbstractKontaktperson';
import type {KindKontaktRelationship} from './KindKontaktRelationship';
import {RelationshipWithKind} from './RelationshipWithKind';

export class Kontaktperson extends AbstractKontaktperson
    implements IPersistable, IRestModel<JaxKontaktperson>, IEntityList, ISearchable {

    public constructor(
        id: string | null = null,
        public geschlecht: Geschlecht | null = null,
        public versandartRechnung: VersandartRechnung | null = null,
        vorName: string = '',
        familienName: string = '',
        public email: string | null = null,
        public telefon: string | null = null,
        public mobile: string | null = null,
        public geschaeft: string | null = null,
        organisation: string = '',
        public korrespondenzsprache: Language | null = null,
        public kundenNummer: number | null = null,
        public isFirma: boolean = false,
        public addressMode: AddressMode = AddressMode.HAUPTKONTAKT,
        public adressen: LimitedAdresse[] = [],
        public kontakte: RelationshipWithKind[] = [],
        public customFieldValues: CustomFieldValue[] = [],
        public mahnsperre: boolean = false,
        public externalKontaktId: string | null = null,
        public iban: string | null = null,
        public bic: string | null = null,
    ) {
        super(id, vorName, familienName, organisation, isFirma);
    }

    public static apiResponseTransformer(data: any): Persisted<Kontaktperson> {
        const answer = new Kontaktperson();
        answer.id = data.id;
        answer.vorName = data.vorName;
        answer.familienName = data.familienName;
        answer.telefon = data.telefon;
        answer.mobile = data.mobile;
        answer.geschaeft = data.geschaeft;
        answer.email = data.email;
        answer.organisation = data.organisation;
        answer.geschlecht = data.geschlecht;
        answer.versandartRechnung = data.versandartRechnung;
        answer.isFirma = data.isFirma;
        answer.kundenNummer = parseInt(data.kundenNummer, 10);
        answer.addressMode = data.addressMode;
        answer.mahnsperre = data.mahnsperre;
        answer.iban = data.iban;
        answer.bic = data.bic;

        if (data.korrespondenzsprache) {
            IsoLanguagesAndCountriesService.getLanguage(data.korrespondenzsprache).then((sprache: Language | null) => {
                answer.korrespondenzsprache = sprache;
            });
        }

        if (Array.isArray(data.adressen)) {
            answer.adressen = data.adressen
                .map((a: any) => LimitedAdresse.apiResponseTransformer(a));
        }

        if (Array.isArray(data.kontakte)) {
            answer.kontakte = data.kontakte
                .map((k: any) => RelationshipWithKind.apiResponseTransformer(k));
        }

        if (Array.isArray(data.customFieldValues)) {
            answer.customFieldValues = data.customFieldValues
                .map((customFieldValue: any) => CustomFieldValue.apiResponseTransformer(customFieldValue));
        }

        return checkPersisted(answer);
    }

    public isValid(): boolean {
        const hasNames = this.hasVorName() && this.hasFamilienName();

        return (this.isFirma && (hasNames || DvbUtil.isNotEmptyString(this.organisation))) ||
            hasNames;
    }

    public getAdresseOn(atMoment?: moment.Moment): LimitedAdresse | null {
        const referenceMoment = DvbDateUtil.isValidMoment(atMoment) ? atMoment : moment();

        return DvbDateUtil.getEntityOn(this.adressen, referenceMoment);
    }

    public toSearchResultEntry(): SearchResultEntry {
        return new SearchResultEntry('KONTAKTPERSON', this.entityId(), this.getDisplayName());
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public badgeText(): string | undefined {
        return undefined;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public getDisplayName(): string {
        return [this.organisation, `${this.familienName} ${this.vorName}`]
            .filter(s => DvbUtil.isNotEmptyString(s.trim()))
            .join(', ');
    }

    public toRestObject(): JaxKontaktperson {
        return {
            id: this.id ?? undefined,
            vorName: this.vorName,
            familienName: this.familienName,
            telefon: this.telefon ?? undefined,
            mobile: this.mobile ?? undefined,
            geschaeft: this.geschaeft ?? undefined,
            email: this.email ?? undefined,
            organisation: this.organisation,
            geschlecht: this.geschlecht ?? undefined,
            versandartRechnung: checkPresent(this.versandartRechnung),
            isFirma: this.isFirma,
            korrespondenzsprache: this.korrespondenzsprache?.toRestObject(),
            addressMode: this.addressMode,
            mahnsperre: this.mahnsperre,
            externalKontaktId: this.externalKontaktId ?? undefined,
            iban: this.iban ?? undefined,
            bic: this.bic ?? undefined,
        };
    }

    public hasVorName(): boolean {
        return angular.isString(this.vorName) && this.vorName.length > 0;
    }

    public hasFamilienName(): boolean {
        return angular.isString(this.familienName) && this.familienName.length > 0;
    }

    public hasPhoneNumber(): boolean {
        return isPresent(this.telefon) || isPresent(this.mobile);
    }

    public isHauptkontakt(): boolean {
        return this.kontakte.some(k => k.relationship ? k.relationship.isHauptkontakt() : false);
    }

    public findRelationshipWithKindId(kindId: string): KindKontaktRelationship | null {
        const kontakte = this.kontakte.filter(k => k.kindId === kindId);

        return kontakte.length > 0 ? kontakte[0].relationship : null;
    }
}
