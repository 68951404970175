/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/**
 * The order is relevant. See AuthorisationService.isKitaPermissionGreater()
 */
export enum KitaPermissionType {
    BETREUUNG_ADMIN = 'BETREUUNG_ADMIN',
    ADMINISTRATE = 'ADMINISTRATE',
    MANAGE = 'MANAGE',
    CONTROL = 'CONTROL',
    VIEW_PLUS = 'VIEW_PLUS',
    VIEW = 'VIEW',
}

export function isKitaPermissionType(value: unknown): value is KitaPermissionType {
    return Object.values(KitaPermissionType).includes(value as KitaPermissionType);
}
