/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted} from '@dv/shared/code';
import {Kind} from '../kind/Kind';
import {Kontaktperson} from '../kontakte/Kontaktperson';

export interface KindMitzuegler {
    person: Persisted<Kind>;
    wohntAnSelberAdresse: boolean;
}

export interface KontaktpersonMitzuegler {
    person: Persisted<Kontaktperson>;
    wohntAnSelberAdresse: boolean;
}

export class Mitzuegler {

    public constructor(
        public kinder: KindMitzuegler[] = [],
        public kontaktpersonen: KontaktpersonMitzuegler[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): Mitzuegler {
        const answer = new Mitzuegler();

        if (Array.isArray(data.kinder)) {
            answer.kinder = data.kinder.map((k: any) => ({
                person: Kind.apiResponseTransformer(k.person),
                wohntAnSelberAdresse: k.wohntAnSelberAdresse,
            }));
        }

        if (Array.isArray(data.kontaktpersonen)) {
            answer.kontaktpersonen = data.kontaktpersonen.map((k: any) => ({
                person: Kontaktperson.apiResponseTransformer(k.person),
                wohntAnSelberAdresse: k.wohntAnSelberAdresse,
            }));
        }

        return answer;
    }
}
