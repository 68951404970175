/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AbstractGesuchsteller} from './AbstractGesuchsteller';

export class KibonExchangeGesuchsteller extends AbstractGesuchsteller {

    public constructor(
        id: string | null,
        vorName: string,
        familienName: string,
        email: string,
    ) {
        super(id, vorName, familienName, email);
    }

    public static apiResponseTransformer(data: any): KibonExchangeGesuchsteller {
        return new KibonExchangeGesuchsteller(data.id, data.vorName, data.familienName, data.email);
    }
}
