/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {ServiceContainer} from '../ServiceContainer';

export enum ControllingChangeType {
    // TODO
}

export class ControllingChange {

    public constructor(
        public aenderungsDatum: moment.Moment | null = null,
        public aenderungsTyp: ControllingChangeType | null = null,
        public kindId: string | null = null,
        public kindFamilienName: string | null = null,
        public kindVorName: string | null = null,
        public kindGeburtsTagTermin: moment.Moment | null = null,
        public kindChangeType: string | null = null,
        public translateArgs: string | null = null,
        public changeSubventioniert: number | null = null,
        public changePrivat: number | null = null,
        public changeFirmen: number | null = null,
        public belegteGruppen: string[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): ControllingChange {
        const answer = new ControllingChange();

        answer.aenderungsDatum = DvbRestUtil.localDateToMoment(data.aenderungsDatum);
        answer.aenderungsTyp = data.aenderungsTyp;

        answer.kindId = data.kindId;
        answer.kindFamilienName = data.kindFamilienName;
        answer.kindVorName = data.kindVorName;
        answer.kindGeburtsTagTermin = DvbRestUtil.localDateToMoment(data.kindGeburtsTagTermin);
        answer.kindChangeType = data.kindChangeType;
        answer.translateArgs = data.translateArgs;

        answer.changeSubventioniert = parseFloat(data.changeSubventioniert);
        answer.changePrivat = parseFloat(data.changePrivat);
        answer.changeFirmen = parseFloat(data.changeFirmen);
        answer.belegteGruppen = data.belegteGruppen;

        return answer;
    }

    public getKindDisplayName(): string {
        return ServiceContainer.displayNameService.displayKindName(this.kindFamilienName, this.kindVorName);
    }
}
