/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AppConfigKey} from '@dv/shared/backend/model/app-config-key';
import type {IRestModel} from '@dv/shared/code';

export class AppConfigEntry implements IRestModel {

    public constructor(
        public key: AppConfigKey,
        public value: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): AppConfigEntry {
        const appConfigEntry = new AppConfigEntry(data.key);
        appConfigEntry.value = data.value;

        return appConfigEntry;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            key: this.key,
            value: this.value,
        };
    }
}
