/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IEntityList, IPersistable, IRestModel, ISearchable, IValidable} from '@dv/shared/code';
import {checkPresent, DvbUtil, SearchResultEntry} from '@dv/shared/code';
import angular from 'angular';
import {AltersBedingterBetreuungsfaktor} from './AltersBedingterBetreuungsfaktor';

export class BetreuungsfaktorRegel implements IPersistable, IRestModel, IEntityList, ISearchable, IValidable {

    public constructor(
        public id: string | null = null,
        public name: string = '',
        public kindergarten1Betreuungsfaktor: number | null = null,
        public kindergarten2Betreuungsfaktor: number | null = null,
        public schuleBetreuungsfaktor: number | null = null,
        public altersBedingteBetreuungsfaktoren: AltersBedingterBetreuungsfaktor[] = [],
        public kibonAbzugProTag: number | null = null,
        public nachmittagsKibonAbzug: boolean = false,
        public kibonInternKgPauschalAbzug: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): BetreuungsfaktorRegel {
        const answer = new BetreuungsfaktorRegel();
        answer.id = data.id;
        answer.name = data.name;
        answer.nachmittagsKibonAbzug = data.nachmittagsKibonAbzug;

        if (data.kindergarten1Betreuungsfaktor) {
            answer.kindergarten1Betreuungsfaktor = parseFloat(data.kindergarten1Betreuungsfaktor);
        }

        if (data.kindergarten2Betreuungsfaktor) {
            answer.kindergarten2Betreuungsfaktor = parseFloat(data.kindergarten2Betreuungsfaktor);
        }

        if (data.schuleBetreuungsfaktor) {
            answer.schuleBetreuungsfaktor = parseFloat(data.schuleBetreuungsfaktor);
        }

        if (Array.isArray(data.altersBedingteBetreuungsfaktoren)) {
            answer.altersBedingteBetreuungsfaktoren = data.altersBedingteBetreuungsfaktoren.map(
                (a: any) => AltersBedingterBetreuungsfaktor.apiResponseTransformer(a));
        }

        if (data.kibonAbzugProTag) {
            answer.kibonAbzugProTag = parseFloat(data.kibonAbzugProTag);
        }

        if (data.kibonInternKgPauschalAbzug) {
            answer.kibonInternKgPauschalAbzug = parseFloat(data.kibonInternKgPauschalAbzug);
        }

        return answer;
    }

    private static validKindergartenBetreuungsfaktor(kindergartenBetreuungsfaktor: number | null): boolean {
        return !kindergartenBetreuungsfaktor || (
            angular.isNumber(kindergartenBetreuungsfaktor) &&
            kindergartenBetreuungsfaktor >= 0
        );
    }

    public addAltersBedingterBetreuungsfaktor(altersBedingterBetreuungsfaktor: AltersBedingterBetreuungsfaktor): void {
        if (!this.altersBedingteBetreuungsfaktoren.includes(altersBedingterBetreuungsfaktor)) {
            this.altersBedingteBetreuungsfaktoren.push(altersBedingterBetreuungsfaktor);
        }
    }

    public removeAltersBedingterBetreuungsfaktor(faktor: AltersBedingterBetreuungsfaktor): void {
        DvbUtil.removeFromArray(faktor, this.altersBedingteBetreuungsfaktoren);
    }

    public isValid(): boolean {
        return DvbUtil.isNotEmptyString(this.name)
            && BetreuungsfaktorRegel.validKindergartenBetreuungsfaktor(this.kindergarten1Betreuungsfaktor)
            && BetreuungsfaktorRegel.validKindergartenBetreuungsfaktor(this.kindergarten2Betreuungsfaktor)
            && BetreuungsfaktorRegel.validKindergartenBetreuungsfaktor(this.schuleBetreuungsfaktor);
    }

    public getDisplayName(): string {
        return this.name;
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public badgeText(): string | undefined {
        return undefined;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public toSearchResultEntry(): SearchResultEntry {
        return new SearchResultEntry('BETREUUNGSFAKTOR_REGEL', this.entityId(), this.getDisplayName());
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            kindergarten1Betreuungsfaktor: this.kindergarten1Betreuungsfaktor,
            kindergarten2Betreuungsfaktor: this.kindergarten2Betreuungsfaktor,
            schuleBetreuungsfaktor: this.schuleBetreuungsfaktor,
            altersBedingteBetreuungsfaktoren: this.altersBedingteBetreuungsfaktoren.map(a => a.toRestObject()),
            kibonAbzugProTag: this.kibonAbzugProTag,
            nachmittagsKibonAbzug: this.nachmittagsKibonAbzug,
            kibonInternKgPauschalAbzug: this.kibonInternKgPauschalAbzug,
        };
    }
}
