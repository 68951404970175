/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

// eslint-disable-next-line max-classes-per-file
import type {ApiResponseTransformer, Country} from '@dv/shared/code';
import {Adresse, DvbRestUtil, END_OF_TIME} from '@dv/shared/code';
import {LimitedAdresse} from '../adressen/LimitedAdresse';
import {GruppenWochenBelegung} from '../belegung/GruppenWochenBelegung';
import {AnwesenheitsZeitConstraint} from '../belegung/monat/AnwesenheitsZeitConstraint';
import {CustomFieldValue} from '../customfield/CustomFieldValue';
import {IsoLanguagesAndCountriesService} from '../isoLanguagesAndCountriesService';
import type {Language} from '../locale/Language';
import {Mandant} from '../mandant/Mandant';
import {BewilligtePlaetze} from './BewilligtePlaetze';
import type {KinderOrt} from './KinderOrt';
import {KinderOrtType} from './KinderOrt';
import type {KinderOrtFraktion} from './KinderOrtFraktion';
import {KinderOrtFraktionType} from './KinderOrtFraktion';
import {Gruppe} from './kita/Gruppe';
import {Kita} from './kita/Kita';
import {KitaBetreuungsfaktorRegel} from './KitaBetreuungsfaktorRegel';
import {FirmenKontingent} from './kontingente/FirmenKontingent';
import {SubventioniertesKontingent} from './kontingente/SubventioniertesKontingent';
import {BetreuungsPerson} from './tageseltern/BetreuungsPerson';
import {TagesEltern} from './tageseltern/TagesEltern';
import {WochenKapazitaet} from './WochenKapazitaet';
import {Wochenplan} from './Wochenplan';

// Due to a circular dependency problem, we write both classes in the same file

export class KinderOrtTransformer<T extends KinderOrt> implements ApiResponseTransformer<T> {
    public static create<T extends KinderOrt>(): KinderOrtTransformer<T> {
        return new KinderOrtTransformer<T>();
    }

    public apiResponseTransformer(data: any): T {
        switch (data.dtype) {
            case KinderOrtType.KITA:
                return (this.kitaApiResponseTransformer(data) as any) as T;
            case KinderOrtType.TAGES_ELTERN:
                return (this.tagesElternApiResponseTransformer(data) as any) as T;
            default:
                throw new Error(`DType transformer not implemented: ${String(data.dtype)}`);
        }
    }

    private apiResponseTransformerBase<TKinderOrt extends KinderOrt>(data: any, result: TKinderOrt): TKinderOrt {
        // dtype is already set in result

        result.id = data.id;
        result.titel = data.titel;
        result.name = data.name;
        result.email = data.email;
        result.telefon = data.telefon;
        result.website = data.website;
        result.hasMonatsBlatt = data.hasMonatsBlatt;
        result.maxDailyHours = data.maxDailyHours;
        result.externalAccountingId = data.externalAccountingId;
        result.rechnungsLaufLockedBefore = DvbRestUtil.localDateToMoment(data.rechnungsLaufLockedBefore);

        if (data.adresse) {
            result.adresse = Adresse.apiResponseTransformer(data.adresse);
        }

        if (Array.isArray(data.bewilligtePlaetze)) {
            result.bewilligtePlaetze = data.bewilligtePlaetze
                .map((b: any) => BewilligtePlaetze.apiResponseTransformer(b));
        }

        if (data.subventioniertesKontingent) {
            result.subventioniertesKontingent = SubventioniertesKontingent.apiResponseTransformer(
                data.subventioniertesKontingent);
        }

        if (Array.isArray(data.firmenKontingente)) {
            result.firmenKontingente = data.firmenKontingente
                .map((k: any) => FirmenKontingent.apiResponseTransformer(k));
        }

        if (Array.isArray(data.gruppen)) {
            // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
            const transformer = KinderOrtFraktionTransformer.create();
            result.gruppen = data.gruppen.map((g: any) => transformer.apiResponseTransformer(g));
        }

        if (Array.isArray(data.betreuungsfaktorRegeln)) {
            result.betreuungsfaktorRegeln = data.betreuungsfaktorRegeln
                .map((r: any) => KitaBetreuungsfaktorRegel.apiResponseTransformer(r));
        }

        if (data.mandant) {
            result.mandant = Mandant.apiResponseTransformer(data.mandant);
        }

        if (Array.isArray(data.anwesenheitsZeitConstraints)) {
            result.anwesenheitsZeitConstraints =
                data.anwesenheitsZeitConstraints.map((g: any) =>
                    AnwesenheitsZeitConstraint.apiResponseTransformer(g));
        }

        return result;
    }

    private kitaApiResponseTransformer(data: any): Kita {
        return this.apiResponseTransformerBase(data, new Kita());
    }

    private tagesElternApiResponseTransformer(data: any): TagesEltern {
        return this.apiResponseTransformerBase(data, new TagesEltern());
    }
}

export class KinderOrtFraktionTransformer<T extends KinderOrtFraktion> implements ApiResponseTransformer<T> {
    public static create<T extends KinderOrtFraktion>(): KinderOrtFraktionTransformer<T> {
        return new KinderOrtFraktionTransformer<T>();
    }

    public apiResponseTransformer(data: any): T {
        switch (data.dtype) {
            case KinderOrtFraktionType.GRUPPE:
                return this.gruppeApiResponseTransformer(data) as unknown as T;
            case KinderOrtFraktionType.BETREUUNGS_PERSON:
                return this.betreuungsPersonApiResponseTransformer(data) as unknown as T;
            default:
                throw new Error(`DType transformer not implemented: ${JSON.stringify(data.dtype)}`);
        }
    }

    private gruppeApiResponseTransformer(data: any): Gruppe {
        const transformer = KinderOrtTransformer.create();
        const gruppe = this.apiResponseTransformerBase(data, new Gruppe(), transformer);

        gruppe.name = data.attributes.name;
        gruppe.betreuungsAngebotType = data.attributes.betreuungsAngebotType;

        return gruppe;
    }

    private betreuungsPersonApiResponseTransformer(data: any): BetreuungsPerson {
        const transformer = KinderOrtTransformer.create();
        const betreuungsPerson = this.apiResponseTransformerBase(data, new BetreuungsPerson(), transformer);

        betreuungsPerson.vorName = data.attributes.vorName;
        betreuungsPerson.familienName = data.attributes.familienName;
        betreuungsPerson.geburtsTag = DvbRestUtil.localDateToMoment(data.attributes.geburtsTag);

        if (data.attributes.adressen) {
            betreuungsPerson.adressen = data.attributes.adressen
                .map((adresse: any) => LimitedAdresse.apiResponseTransformer(adresse));
        }

        betreuungsPerson.telefon = data.attributes.telefon;
        betreuungsPerson.mobile = data.attributes.mobile;
        betreuungsPerson.geschaeft = data.attributes.geschaeft;
        betreuungsPerson.email = data.attributes.email;
        betreuungsPerson.bemerkung = data.attributes.bemerkung;
        betreuungsPerson.geschlecht = data.attributes.geschlecht;
        betreuungsPerson.angestellteId = data.attributes.angestellteId;

        IsoLanguagesAndCountriesService.getCountry(data.attributes.nationalitaet).then((land: Country | null) => {
            betreuungsPerson.nationalitaet = land;
        });

        IsoLanguagesAndCountriesService.getLanguage(data.attributes.language).then((language: Language | null) => {
            betreuungsPerson.language = language;
        });

        return betreuungsPerson;
    }

    private apiResponseTransformerBase<TFraktion extends KinderOrtFraktion>(
        data: any,
        result: TFraktion,
        kinderOrtTransformer: KinderOrtTransformer<KinderOrt>,
    ): TFraktion {

        // dtype is already set in result

        result.id = data.id;
        result.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        result.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis) ?? END_OF_TIME;
        result.kinderOrtId = data.kinderOrtId;

        if (data.wochenplan) {
            result.wochenplan = Wochenplan.apiResponseTransformer(data.wochenplan);
            result.wochenplanId = result.wochenplan.id;
        } else if (data.wochenplanId) {
            result.wochenplanId = data.wochenplanId;
        }

        if (Array.isArray(data.wochenKapazitaeten)) {
            result.wochenKapazitaeten = data.wochenKapazitaeten
                .map((k: any) => WochenKapazitaet.apiResponseTransformer(k));
        }

        if (Array.isArray(data.gruppenWochenBelegungen)) {
            result.gruppenWochenBelegungen = data.gruppenWochenBelegungen
                .map((wb: any) => GruppenWochenBelegung.apiResponseTransformer(wb));
        }

        if (data.kita) {
            result.kita = kinderOrtTransformer.apiResponseTransformer(data.kita);
        }

        if (Array.isArray(data.customFieldValues)) {
            result.customFieldValues = data.customFieldValues
                .map((customFieldValue: any[]) => CustomFieldValue.apiResponseTransformer(customFieldValue));
        }

        return result;
    }
}

export class OrtTransformer<T extends KinderOrtFraktion | KinderOrt> implements ApiResponseTransformer<T> {
    public static create<T extends KinderOrtFraktion>(): OrtTransformer<T> {
        return new OrtTransformer<T>();
    }

    public apiResponseTransformer(data: any): T {
        switch (data.dtype) {
            case KinderOrtType.KITA:
            case KinderOrtType.TAGES_ELTERN:
                return KinderOrtTransformer.create().apiResponseTransformer(data) as unknown as T;
            case KinderOrtFraktionType.GRUPPE:
            case KinderOrtFraktionType.BETREUUNGS_PERSON:
                return KinderOrtFraktionTransformer.create().apiResponseTransformer(data) as unknown as T;
            default:
                throw new Error(`OrtTransformer not implemented for dtype: ${JSON.stringify(data.dtype)}`);
        }
    }
}
