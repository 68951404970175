/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {AbstractLeistungsPosition} from './AbstractLeistungsPosition';
import type {FixPositionTyp} from './FixPositionTyp';

export class FixPosition extends AbstractLeistungsPosition {
    public static override $inject: readonly string[] = [];

    public constructor(
        public typ: FixPositionTyp | null = null,
        public leistungsDatum: moment.Moment | null = null,
        public kindId: string | null = null,
        public kitaId: string | null = null,
    ) {
        super();
    }

    public static apiResponseTransformer(data: any): FixPosition {
        const answer = this.baseTransformer(data, new FixPosition());
        answer.typ = data.typ;
        answer.leistungsDatum = DvbRestUtil.localDateToMoment(data.leistungsDatum);
        answer.kindId = data.kindId;
        answer.kitaId = data.kitaId;

        return answer;
    }

    public override toRestObject(): Record<string, unknown> {
        return {
            typ: this.typ,
            leistungsDatum: DvbRestUtil.momentToLocalDate(this.leistungsDatum),
            kindId: this.kindId,
            kitaId: this.kitaId,
            ...super.toRestObject(),
        };
    }
}
