/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ApiResponseTransformer, IEquals, IPersistable, IRestModel} from '@dv/shared/code';
import {DvbDateUtil, DvbUtil, isNullish} from '@dv/shared/code';
import type moment from 'moment';
import type {AbstractKontingentValue} from '../AbstractKontingentValue';
import type {FirmenKontingent} from './FirmenKontingent';
import type {SubventioniertesKontingent} from './SubventioniertesKontingent';

export enum KontingentType {
    FIRMA = 'FIRMA',
    SUBVENTIONIERT = 'SUBVENTIONIERT',
}

export type KontingentId = string;

export abstract class AbstractKontingent<T extends KontingentType, TValue extends AbstractKontingentValue<T>>
    implements IPersistable, IRestModel, IEquals<AbstractKontingent<any, any>> {

    public id: string | null = null;
    public values: TValue[] = [];
    public pensumFaktor: number | null = null;

    protected constructor(public type: KontingentType) {
    }

    protected static baseTransformer<T1 extends KontingentType,
        T2 extends AbstractKontingentValue<T1>,
        T3 extends AbstractKontingent<T1, T2>>(
        data: any,
        kontingent: T3,
        valueTransformer: ApiResponseTransformer<T2>,
    ): T3 {

        kontingent.id = data.id;
        kontingent.type = data.type;
        kontingent.pensumFaktor = data.pensumFaktor;

        if (Array.isArray(data.values)) {
            kontingent.values = data.values.map((v: any) => valueTransformer.apiResponseTransformer(v));
        }

        return kontingent;
    }

    public isFirmenKontingent(): this is FirmenKontingent {
        return this.type === KontingentType.FIRMA;
    }

    public isSubventioniertesKontingent(): this is SubventioniertesKontingent {
        return this.type === KontingentType.SUBVENTIONIERT;
    }

    public removeKontingentValue(kontingentValue: AbstractKontingentValue<T>): void {
        DvbUtil.removeFromArray(kontingentValue, this.values);
    }

    public getValueOn(aMoment: moment.Moment): AbstractKontingentValue<T> | null {
        return DvbDateUtil.getEntityOn(this.values, aMoment);
    }

    public getPlaetzeOn(aMoment: moment.Moment): number {
        const value = this.getValueOn(aMoment);
        if (isNullish(value) || value.plaetze === null) {
            return 0;
        }

        return value.plaetze;
    }

    /**
     * @return TRUE when Kontingent has gueltige KontingentValue at stichtag, FALSE otherwise
     */
    public isGueltigOn(aMomemt: moment.Moment): boolean {
        return DvbDateUtil.getEntitiesOn(this.values, aMomemt).length > 0;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            type: this.type,
            pensumFaktor: this.pensumFaktor,
            values: this.values.map(v => v.toRestObject()),
        };
    }

    public equals(other: AbstractKontingent<any, any> | null): boolean {
        if (other === null) {
            return false;
        }

        return this.id === other.id && this.type === other.type;
    }
}
