/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {Firma} from '../../firma/Firma';
import {AbstractKontingent, KontingentType} from './AbstractKontingent';
import {FirmenKontingentValue} from './FirmenKontingentValue';

export class FirmenKontingent extends AbstractKontingent<KontingentType.FIRMA, FirmenKontingentValue>
    implements IDisplayable {

    public firma: Firma | null = null;
    public firmaId: string | null = null;
    public name: string | null = null;
    public weeklyPlatzFrequency: number | null = null;

    public constructor() {
        super(KontingentType.FIRMA);
    }

    public static apiResponseTransformer(data: any): FirmenKontingent {
        const answer = super.baseTransformer(data, new FirmenKontingent(), FirmenKontingentValue);

        if (data.firma) {
            answer.firma = Firma.apiResponseTransformer(data.firma);
        }

        if (data.firmaId) {
            answer.firmaId = data.firmaId;
        }

        answer.name = data.name;
        answer.weeklyPlatzFrequency = data.weeklyPlatzFrequency;

        return answer;
    }

    public override toRestObject(): Record<string, unknown> {
        return {
            ...super.toRestObject(),
            name: this.name,
            weeklyPlatzFrequency: this.weeklyPlatzFrequency,
        };
    }

    public isValid(): boolean {
        return this.hasName() && this.isPensumFaktorValid();
    }

    public isPensumFaktorValid(): boolean {
        return this.pensumFaktor === null || angular.isNumber(this.pensumFaktor) && this.pensumFaktor >= 0;
    }

    public hasName(): boolean {
        return angular.isString(this.name) && this.name.length > 0;
    }

    public getValuesWithKitaId(kitaId: string): FirmenKontingentValue[] {
        return this.values.filter(firmenKontingentValue => firmenKontingentValue.kitasIds.includes(kitaId));
    }

    /**
     * @return TRUE when Kontingent has gueltige FirmenKontingentValue at stichtag, FALSE otherwise
     */
    public isGueltigOnWithKitaId(aMomemt: moment.Moment, kitaId: string): boolean {
        return DvbDateUtil.getEntitiesOn(this.getValuesWithKitaId(kitaId), aMomemt).length > 0;
    }

    public getDisplayName(): string {
        if (this.firma) {
            return `${this.firma.name} / ${this.name}`;
        }

        return this.name ?? '';
    }
}
