/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {GebuehrType} from '@dv/shared/backend/model/gebuehr-type';
import type {JaxGebuehr} from '@dv/shared/backend/model/jax-gebuehr';
import type {IRestModel} from '@dv/shared/code';
import {checkPresent, isNullish, isPresent} from '@dv/shared/code';
import {AbstractKontaktpersonZahlung} from './AbstractKontaktpersonZahlung';

export class Gebuehr extends AbstractKontaktpersonZahlung implements IRestModel<JaxGebuehr> {

    public constructor(
        public gebuehrType: GebuehrType | null = null,
        public depotBereitsEingefordert: boolean = false,
        public bezeichnung: string | null = null,
        public depotErstattet: boolean = false,
        public rechnungsRevisionId: string | null = null,
        public rechnungsId: string | null = null,
        public rechnungsNummer: number | null = null,
        public childGebuehr: boolean = false,
    ) {
        super();
    }

    public static apiResponseTransformer(data: JaxGebuehr): Gebuehr {
        return super.baseTransformer(data, new Gebuehr(
            data.gebuehrType,
            data.depotBereitsEingefordert,
            data.bezeichnung,
            data.depotErstattet,
            data.rechnungsRevisionId,
            data.rechnungsId,
            data.rechnungsNummer,
            data.childGebuehr,
        ));
    }

    public override toRestObject(): JaxGebuehr {
        return {
            ...super.toRestObject(),
            gebuehrType: checkPresent(this.gebuehrType),
            depotBereitsEingefordert: this.depotBereitsEingefordert,
            bezeichnung: checkPresent(this.bezeichnung),
            depotErstattet: this.depotErstattet,
            rechnungsRevisionId: this.rechnungsRevisionId ?? undefined,
            rechnungsId: this.rechnungsId ?? undefined,
            childGebuehr: this.childGebuehr,
        };
    }

    public isDepot(): boolean {
        return this.gebuehrType === GebuehrType.DEPOT;
    }

    public isRueckerstattbareGebuehr(): boolean {
        return this.isDepot()
            && (isPresent(this.rechnungsRevisionId) || this.depotBereitsEingefordert)
            && !this.depotErstattet;
    }

    public isDeletableGebuehr(): boolean {
        return isNullish(this.rechnungsRevisionId)
            && !this.depotErstattet;
    }

    public isEditableGebuehr(): boolean {
        return this.isDeletableGebuehr() ||
            (this.isDepot() && !this.depotErstattet && !this.childGebuehr);
    }

    public isAssignedDepot(): boolean {
        return this.isDepot() && isPresent(this.rechnungsRevisionId);
    }
}
