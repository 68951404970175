/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel} from '@dv/shared/code';
import type {LimitedAdresse} from './LimitedAdresse';

export class Umzug implements IRestModel {

    public constructor(
        public limitedAdresse: LimitedAdresse | null = null,
        public kindIds: string[] = [],
        public kontaktpersonIds: string[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): Umzug {
        return new Umzug(data.isoCode, data.displayName);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            limitedAdresse: this.limitedAdresse?.toRestObject() ?? null,
            kindIds: this.kindIds,
            kontaktpersonIds: this.kontaktpersonIds,
        };
    }
}
