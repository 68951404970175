/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbRestUtil, END_OF_TIME} from '@dv/shared/code';
import type moment from 'moment';
import {BetreuungsfaktorRegel} from '../betreuungsfaktor/BetreuungsfaktorRegel';

export class KitaBetreuungsfaktorRegel implements IPersistable, IRestModel, ILimited {

    public constructor(
        public id: string | null = null,
        public kitaId: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment = END_OF_TIME,
        public regelId: string | null = null,
        public regel: BetreuungsfaktorRegel | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): KitaBetreuungsfaktorRegel {
        const gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis);

        const kitaBetreuungsfaktorRegel = new KitaBetreuungsfaktorRegel();
        kitaBetreuungsfaktorRegel.id = data.id;
        kitaBetreuungsfaktorRegel.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);

        kitaBetreuungsfaktorRegel.gueltigBis = checkPresent(gueltigBis);
        kitaBetreuungsfaktorRegel.kitaId = data.kitaId;
        kitaBetreuungsfaktorRegel.regelId = data.regelId;

        if (data.regel) {
            kitaBetreuungsfaktorRegel.regel = BetreuungsfaktorRegel.apiResponseTransformer(data.regel);
            kitaBetreuungsfaktorRegel.regelId = kitaBetreuungsfaktorRegel.regel.id;
        }

        return kitaBetreuungsfaktorRegel;
    }

    public setRegel(regel: BetreuungsfaktorRegel): void {
        if (regel) {
            this.regel = regel;
            this.regelId = regel.id;
        } else {
            this.resetRegel();
        }
    }

    public resetRegel(): void {
        this.regel = null;
        this.regelId = null;
    }

    public isValid(): boolean {
        return this.kitaId !== null &&
            DvbDateUtil.isValidMoment(this.gueltigAb) &&
            DvbDateUtil.isValidMoment(this.gueltigBis);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            kitaId: this.kitaId,
            regelId: this.regelId,
        };
    }
}
