/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {stringUnion} from '@dv/shared/code';

export const KONTAKT_EMAIL_TYPE = stringUnion(
    'BRING_ABHOLZEITEN',
    'BRING_ABHOLZEITEN_CONFIRM',
    'STEUERBESCHEINIGUNG',
    'BULK_EMAIL',
    'RECHNUNGS_REVISION',
);

export type KontaktEmailType = typeof KONTAKT_EMAIL_TYPE.type;
