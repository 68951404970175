/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbUtil} from '@dv/shared/code';
import type {EinzahlungsscheinType} from './Einzahlungsschein';

const ESR_PATTERN = /01-\d{3,6}-\d/;
const QR_IBAN_PATTERN = /(LI|CH)\d{2}3[01]\d{3}\w{12}/;

export function isEsrKontoLike(value: unknown): boolean {
    return DvbUtil.isNotEmptyString(value) && ESR_PATTERN.test(value);
}

export function isQrIbanLike(value: unknown): boolean {
    return DvbUtil.isNotEmptyString(value) && QR_IBAN_PATTERN.test(stripWhiteSpaces(value));
}

export function getType(kontoNummer: unknown): EinzahlungsscheinType {
    if (isQrIbanLike(kontoNummer)) {
        return 'QR';
    }

    if (isEsrKontoLike(kontoNummer)) {
        return 'ESR';
    }

    throw new Error(`Cannot determine EinzahlungsscheinType for kontoNummer ${JSON.stringify(kontoNummer)}`);
}

function stripWhiteSpaces(value: string): string {
    return value.replace(/\s/g, '');
}
