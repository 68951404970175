/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FeatureType} from '@dv/shared/backend/model/feature-type';
import type {IRestModel} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import type {KinderOrt} from '../kinderort/KinderOrt';
import {KinderOrtTransformer} from '../kinderort/KinderOrtTransformer';

export class MandantFeaturePermission implements IRestModel {

    public constructor(
        public featureType: FeatureType,
        public allEnabled: boolean = false,
        public kinderOrte: KinderOrt[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): MandantFeaturePermission {
        const kinderOrte = Array.isArray(data.kinderOrte) ?
            data.kinderOrte.map((k: any) => KinderOrtTransformer.create().apiResponseTransformer(k)) :
            [];

        return new MandantFeaturePermission(data.featureType, data.allEnabled, kinderOrte);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            featureType: this.featureType,
            allEnabled: this.allEnabled,
            kinderOrtIds: this.allEnabled ? [] : this.kinderOrte.map(DvbUtil.mapToId),
        };
    }
}
