/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxKonto} from '@dv/shared/backend/model/jax-konto';
import {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import type {IPersistable, IRestModel} from '@dv/shared/code';
import {DvbRestUtil, END_OF_TIME, isPresent} from '@dv/shared/code';
import type moment from 'moment';
import {DvbKitaRechnungsKonfiguration} from '../dvb/DvbKitaRechnungsKonfiguration';
import {SmallInvoiceKitaKonfiguration} from '../smallinvoice/SmallInvoiceKitaKonfiguration';
import type {IKitaVendorKonfiguration} from './IKitaVendorKonfiguration';
import type {IRechnungsKonfiguration} from './IRechnungsKonfiguration';
import type {RechnungsKonfiguration} from './RechnungsKonfiguration';

export class KitaRechnungsKonfiguration<T extends IRechnungsKonfiguration = IRechnungsKonfiguration>
    implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public kitaId: string | null = null,
        public rechnungsKonfigurationId: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public dtype: RechnungsKonfigurationType | null = null,
        public mandantKonfiguration: RechnungsKonfiguration<T> | null = null,
        public vendorKonfiguration: IKitaVendorKonfiguration | null = null,
        public bccActive: boolean = false,
        public hideTarifPosFraktionSuffix: boolean = false,
        public konto: JaxKonto | null = null,
    ) {
    }

    public static apiResponseTransformer<T extends IRechnungsKonfiguration>(data: any): KitaRechnungsKonfiguration<T> {
        const answer = new KitaRechnungsKonfiguration<T>();
        answer.id = data.id;
        answer.kitaId = data.kitaId;
        answer.rechnungsKonfigurationId = data.rechnungsKonfigurationId;
        answer.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        answer.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis);
        answer.dtype = data.dtype;
        answer.bccActive = data.bccActive;
        answer.hideTarifPosFraktionSuffix = data.hideTarifPosFraktionSuffix;
        answer.konto = data.konto;

        if (!data.konfiguration) {
            return answer;
        }

        switch (answer.dtype) {
            case RechnungsKonfigurationType.SMALLINVOICE:
                answer.vendorKonfiguration = SmallInvoiceKitaKonfiguration.apiResponseTransformer(
                    data.konfiguration);
                break;
            case RechnungsKonfigurationType.DVB:
                answer.vendorKonfiguration = DvbKitaRechnungsKonfiguration.apiResponseTransformer(
                    data.konfiguration);
                break;
            default:
                throw new Error(`Type not implemented: ${String(answer.dtype)}`);
        }

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            kitaId: this.kitaId,
            rechnungsKonfigurationId: this.rechnungsKonfigurationId,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            dtype: this.dtype,
            bccActive: this.bccActive,
            hideTarifPosFraktionSuffix: this.hideTarifPosFraktionSuffix,
            konfiguration: this.vendorKonfiguration ? this.vendorKonfiguration.toRestObject() : undefined,
            kontoId: this.konto?.id,
        };
    }

    public isValid(): boolean {
        return isPresent(this.kitaId) &&
            isPresent(this.rechnungsKonfigurationId) &&
            isPresent(this.gueltigAb) &&
            (this.gueltigBis ?? END_OF_TIME).isAfter(this.gueltigAb) &&
            isPresent(this.dtype) &&
            isPresent(this.vendorKonfiguration);
    }
}
