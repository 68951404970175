/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type AdresseContext = 'MANDANT' | 'KINDERORT' | 'NONE' | 'CUSTOM';

export const AdresseContext = {
    MANDANT: 'MANDANT' as AdresseContext,
    KINDERORT: 'KINDERORT' as AdresseContext,
    NONE: 'NONE' as AdresseContext,
    CUSTOM: 'CUSTOM' as AdresseContext
};

