/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxRelationshipWithKind} from '@dv/shared/backend/model/jax-relationship-with-kind';
import type {IRestModel} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import {KindKontaktRelationship} from './KindKontaktRelationship';

export class RelationshipWithKind implements IRestModel<JaxRelationshipWithKind> {

    public constructor(
        public kindId: string | null = null,
        public relationship: KindKontaktRelationship | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxRelationshipWithKind): RelationshipWithKind {
        const answer = new RelationshipWithKind(data.kindId);

        if (data.relationship) {
            answer.relationship = KindKontaktRelationship.apiResponseTransformer(data.relationship);
        }

        return answer;
    }

    public toRestObject(): JaxRelationshipWithKind {
        return {
            relationship: checkPresent(this.relationship).toRestObject(),
            kindId: this.kindId ?? undefined,
        };
    }
}
