/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Translation} from './Translation';

export class Comments {

    public constructor(
        public key: string,
        public translation: Translation,
    ) {
    }

    public static apiResponseTransformer(data: any): Comments {
        return new Comments(data.key, Translation.apiResponseTransformer(data.translation));
    }
}
