/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {DECIMAL_PCT_FACTOR, DvbDateUtil, DvbRestUtil, END_OF_TIME} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {Kapazitaet} from './Kapazitaet';

export class WochenKapazitaet implements IPersistable, ILimited, IRestModel {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment = END_OF_TIME,
        public anzahlPlaetze: number | null = null,
        public maxPlaetze: number | null = null,
        public kapazitaeten: Kapazitaet[] = [],
        public hasError: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): WochenKapazitaet {
        const answer = new WochenKapazitaet();
        answer.id = data.id;
        answer.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        answer.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis)!;
        if (angular.isNumber(data.plaetzeProzent)) {
            answer.anzahlPlaetze = parseFloat(data.plaetzeProzent) / DECIMAL_PCT_FACTOR;
        }
        if (angular.isNumber(data.maxPlaetzeProzent)) {
            answer.maxPlaetze = parseFloat(data.maxPlaetzeProzent) / DECIMAL_PCT_FACTOR;
        }
        if (Array.isArray(data.kapazitaeten)) {
            answer.kapazitaeten = data.kapazitaeten.map((k: any) => Kapazitaet.apiResponseTransformer(k));
        }

        return answer;
    }

    public isValid(): boolean {
        return !this.hasError &&
            this.kapazitaeten.length > 0 &&
            DvbDateUtil.isValidMoment(this.gueltigAb) &&
            DvbDateUtil.isValidMoment(this.gueltigBis);
    }

    public toRestObject(): Record<string, unknown> {
        const restObject: any = {
            id: this.id,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
        };
        if (angular.isNumber(this.anzahlPlaetze)) {
            restObject.plaetzeProzent = this.anzahlPlaetze * DECIMAL_PCT_FACTOR;
        }
        if (angular.isNumber(this.maxPlaetze)) {
            restObject.maxPlaetzeProzent = this.maxPlaetze * DECIMAL_PCT_FACTOR;
        }
        if (Array.isArray(this.kapazitaeten)) {
            restObject.kapazitaeten = this.kapazitaeten.map(v => v.toRestObject());
        }

        return restObject;
    }

    public isBeendet(): boolean {
        return !DvbDateUtil.isEndOfTime(this.gueltigBis);
    }
}
