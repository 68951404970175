/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {LeistungsTyp} from './LeistungsTyp';
import {TarifrechnungPosition} from './TarifrechnungPosition';

export class TarifrechnungResult implements IPersistable, ILimited {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment,
        public gueltigBis: moment.Moment,
        public tarifId: string,
        public titel: string,
        public kinderOrtFraktion: string,
        public leistungsTyp: LeistungsTyp,
        public summePositionen: number,
        public kontingentId: string | null = null,
        public tarifPositionType: string | null = null,
        public tarifrechnungPositionen: TarifrechnungPosition[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): TarifrechnungResult {
        const answer = new TarifrechnungResult(
            data.id,
            DvbRestUtil.localDateToMoment(data.gueltigAb)!,
            DvbRestUtil.localDateToMoment(data.gueltigBis)!,
            data.tarifId,
            data.titel,
            data.kinderOrtFraktion,
            data.leistungsTyp,
            parseFloat(data.summePositionen),
            data.kontingentId,
            data.tarifPositionType,
        );

        if (Array.isArray(data.tarifrechnungPositionen)) {
            answer.tarifrechnungPositionen = data.tarifrechnungPositionen.map(
                (r: any) => TarifrechnungPosition.apiResponseTransformer(r));
        }

        return answer;
    }
}
