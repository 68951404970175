/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel, IValidable} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import angular from 'angular';

export class AltersBedingterBetreuungsfaktor implements IPersistable, IRestModel, IValidable {

    public constructor(
        public id: string | null = null,
        public vonMonate: number | null = null,
        public betreuungsfaktor: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): AltersBedingterBetreuungsfaktor {
        const answer = new AltersBedingterBetreuungsfaktor();
        answer.id = data.id;
        answer.vonMonate = parseInt(data.vonMonate, 10);
        answer.betreuungsfaktor = parseFloat(data.betreuungsfaktor);

        return answer;
    }

    public isValid(): boolean {
        return DvbUtil.isInteger(this.vonMonate) &&
            angular.isNumber(this.betreuungsfaktor) &&
            this.betreuungsfaktor >= 0;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            vonMonate: this.vonMonate,
            betreuungsfaktor: this.betreuungsfaktor,
        };
    }
}
