/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {BaseRechnungsPosition} from './BaseRechnungsPosition';

export class MandantRechnungConfigPosition extends BaseRechnungsPosition implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        text: string | null = null,
        count: number | null = null,
        cost: number | null = null,
    ) {
        super(text, count, cost);
    }

    public static apiResponseTransformer(data: any): MandantRechnungConfigPosition {
        return new MandantRechnungConfigPosition(data.id, data.text, data.count, data.cost);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            text: this.text,
            count: this.count,
            cost: this.cost,
        };
    }
}
