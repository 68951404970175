/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Country, Geschlecht} from '@dv/shared/code';
import {DvbRestUtil, SearchResultEntry} from '@dv/shared/code';
import type moment from 'moment';
import type {LimitedAdresse} from '../../adressen/LimitedAdresse';
import type {Language} from '../../locale/Language';
import {KinderOrtFraktion, KinderOrtFraktionType} from '../KinderOrtFraktion';

export class BetreuungsPerson extends KinderOrtFraktion {
    public static override $inject: readonly string[] = [];

    public vorName: string | null = null;
    public familienName: string | null = null;
    public geburtsTag: moment.Moment | null = null;
    public adressen: LimitedAdresse[] = [];
    public telefon: string | null = null;
    public mobile: string | null = null;
    public geschaeft: string | null = null;
    public email: string | null = null;
    public nationalitaet: Country | null = null;
    public bemerkung: string | null = null;
    public geschlecht: Geschlecht | null = null;
    public language: Language | null = null;
    public angestellteId: string | null = null;

    public constructor() {
        super(KinderOrtFraktionType.BETREUUNGS_PERSON);
    }

    public toSearchResultEntry(): SearchResultEntry {
        return new SearchResultEntry('BETREUUNGS_PERSON', this.entityId(), this.getDisplayName());
    }

    public isValid(): boolean {
        return !!this.vorName
            && !!this.familienName
            && this.adressen.length > 0
            && this.adressen.every(a => a.adresse.isValid());
    }

    public override toRestObject(): { dtype: string; id: string | null; attributes: Record<string, unknown> } {
        const data = super.toRestObject();
        data.attributes.vorName = this.vorName;
        data.attributes.familienName = this.familienName;
        data.attributes.geburtsTag = DvbRestUtil.momentToLocalDate(this.geburtsTag);
        data.attributes.adressen = this.adressen.map(adresse => adresse.toRestObject());
        data.attributes.telefon = this.telefon;
        data.attributes.mobile = this.mobile;
        data.attributes.geschaeft = this.geschaeft;
        data.attributes.email = this.email;
        data.attributes.nationalitaet = this.nationalitaet ? this.nationalitaet.toRestObject() : null;
        data.attributes.bemerkung = this.bemerkung;
        data.attributes.geschlecht = this.geschlecht;
        data.attributes.language = this.language ? this.language.toRestObject() : null;

        return data;
    }

    public getDisplayName(): string {
        return `${this.familienName} ${this.vorName}`;
    }
}
