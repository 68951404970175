/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import type {IDisplayable, IPersistable, IRestModel, ISortable} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type {Tarif} from './Tarif';

export class TarifParameter implements IPersistable, IRestModel, ISortable, IDisplayable {

    public constructor(
        public id: string | null = null,
        public dtype: TarifParameterType = TarifParameterType.GLOBAL_PARAM,
        public name: string | null = null,
        public orderValue: number | null = null,
        public tarife: Tarif[] = [],
        public kibonParam: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): TarifParameter {
        const tarifParam = new TarifParameter(data.id, data.dtype, data.name, data.orderValue);
        tarifParam.kibonParam = data.kibonParam;

        return tarifParam;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            dtype: this.dtype,
            name: this.name,
            orderValue: this.orderValue,
        };
    }

    public getDisplayName(): string {
        return checkPresent(this.name);
    }
}
