/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {JaxBetreuungInFerienzeit} from '@dv/shared/backend/model/jax-betreuung-in-ferienzeit';
import type {BelegungsZustand, DayOfWeek, ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, isPresent, KindergartenBelegung} from '@dv/shared/code';
import type moment from 'moment';
import {CustomFieldValue} from '../customfield/CustomFieldValue';
import type {Kontingente} from '../kinderort/kontingente/KontingentTransformer';
import {GruppenBelegung} from './GruppenBelegung';
import {PlatzTypen} from './PlatzTypen';

export class Belegung implements ILimited, IRestModel, IPersistable {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public belegungsZustand: BelegungsZustand | null = null,
        public kind: any = null, // FIXME kann Kind entfernt werden? Es ist nur JSON
        public kindId: string | null = null,
        public gruppenBelegungen: GruppenBelegung[] = [],
        public pensum: number | null = null,
        public kitaPensen: { [index: string]: number } = {},
        public kitaVertraglichePensen: { [index: string]: number } = {},
        public austrittProvisorisch: boolean = false,
        public kindergartenBelegung: KindergartenBelegung = KindergartenBelegung.KEINE,
        public betreuungInFerienzeit: JaxBetreuungInFerienzeit = JaxBetreuungInFerienzeit.KEINE_ANGABE,
        public schulStufe: number | null = null,
        public betreuungsfaktor: number | null = null,
        public monatsBelegungId: string | null = null,
        public bemerkung: string | null = null,
        public bemerkungInBetreuungsvereinbarung: boolean = false,
        public customFieldValues: CustomFieldValue[] = [],
        public noFlexiblePlaetze: boolean | null = null,
        public bezugsPersonIds: string[] = [],
        public eingewoehnungPhase: boolean | null = null,
        public eingewoehnungVon: moment.Moment | null = null,
        public eingewoehnungBis: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Belegung {
        const gruppenBelegungen = Array.isArray(data.gruppenBelegungen) ?
            data.gruppenBelegungen.map((g: any[]) => GruppenBelegung.apiResponseTransformer(g)) :
            [];

        const customFieldValues = Array.isArray(data.customFieldValues) ?
            data.customFieldValues.map((cfv: any[]) => CustomFieldValue.apiResponseTransformer(cfv)) :
            [];

        const bezugsPersonIds = Array.isArray(data.bezugsPersonIds) ? data.bezugsPersonIds : [];

        return new Belegung(
            data.id,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
            data.belegungsZustand,
            data.kind,
            data.kind ? data.kind.id : data.kindId,
            gruppenBelegungen,
            parseFloat(data.pensum),
            data.kitaPensen,
            data.kitaVertraglichePensen,
            data.austrittProvisorisch,
            data.kindergartenBelegung ? data.kindergartenBelegung : KindergartenBelegung.KEINE,
            data.betreuungInFerienzeit ? data.betreuungInFerienzeit : JaxBetreuungInFerienzeit.KEINE_ANGABE,
            data.schulStufe,
            data.betreuungsfaktor,
            data.monatsBelegungId,
            data.bemerkung ? data.bemerkung : null,
            data.bemerkungInBetreuungsvereinbarung,
            customFieldValues,
            data.noFlexiblePlaetze,
            bezugsPersonIds,
            data.eingewoehnungPhase,
            DvbRestUtil.localDateToMoment(data.eingewoehnungVon),
            DvbRestUtil.localDateToMoment(data.eingewoehnungBis),
        );
    }

    private static getKontingentById(kontingente: Kontingente[], id: string | null): Kontingente | null {
        const filtered = kontingente.filter(k => k.id === id);

        if (filtered.length > 0) {
            return filtered[0];
        }

        return null;
    }

    public toRestObject(): Record<string, unknown> {
        const restObject: any = {};
        restObject.id = this.id;
        restObject.gueltigAb = DvbRestUtil.momentToLocalDate(this.gueltigAb);
        restObject.gueltigBis = DvbRestUtil.momentToLocalDate(this.gueltigBis);
        restObject.belegungsZustand = this.belegungsZustand;
        if (this.kindId) {
            restObject.kindId = this.kindId;
        } else if (this.kind) {
            restObject.kindId = this.kind.id;
        }
        restObject.pensum = this.pensum;
        restObject.kitaPensen = this.kitaPensen;
        restObject.austrittProvisorisch = this.austrittProvisorisch;
        restObject.kindergartenBelegung = this.kindergartenBelegung;
        restObject.betreuungInFerienzeit = this.betreuungInFerienzeit;
        restObject.schulStufe = this.schulStufe;
        restObject.betreuungsfaktor = this.betreuungsfaktor;
        restObject.monatsBelegungId = this.monatsBelegungId;
        restObject.gruppenBelegungen = this.gruppenBelegungen.map(g => g.toRestObject());
        restObject.bemerkung = this.bemerkung;
        restObject.bemerkungInBetreuungsvereinbarung = this.bemerkungInBetreuungsvereinbarung;
        // since we cannot update the customFieldValues from this model, we don't send them to the backend
        restObject.customFieldValues = null;
        restObject.noFlexiblePlaetze = this.noFlexiblePlaetze;
        restObject.bezugsPersonIds = this.bezugsPersonIds;
        restObject.eingewoehnungPhase = this.eingewoehnungPhase;
        restObject.eingewoehnungVon = DvbRestUtil.momentToLocalDate(this.eingewoehnungVon);
        restObject.eingewoehnungBis = DvbRestUtil.momentToLocalDate(this.eingewoehnungBis);

        return restObject;
    }

    public platzTypen(kontingente: Kontingente[], gruppenIds: string[]): PlatzTypen {
        const model = new PlatzTypen(false, false, this.kindergartenBelegung, null, []);

        this.gruppenBelegungen
            .filter(gruppenBelegung => gruppenIds.includes(gruppenBelegung.gruppeId!))
            .forEach(gruppenBelegung => {
                gruppenBelegung.plaetze.forEach(platz => {
                    if (!platz.hasKontingent()) {
                        model.privat = true;
                    }

                    const kontingent = Belegung.getKontingentById(kontingente, platz.kontingentId);

                    if (!kontingent) {
                        return;
                    }

                    // noinspection TypeScriptValidateJSTypes
                    if (kontingent.isSubventioniertesKontingent()) {
                        model.subventioniert = true;
                    } else if (kontingent.isFirmenKontingent() &&
                        !model.firmen.includes(kontingent.firma!) &&
                        kontingent.firma !== null) {

                        model.firmen.push(kontingent.firma);
                        model.firmenKontingentIds.push(checkPresent(kontingent.id));
                    }
                });
            });

        return model;
    }

    public getKontingenteFromPlaetze(): Kontingente[] {
        const kontingente: Kontingente[] = [];

        this.gruppenBelegungen.forEach(gruppenBelegung => gruppenBelegung.plaetze
            .map(p => p.kontingent)
            .filter(isPresent)
            .filter(kontingent => !kontingente.includes(kontingent))
            .forEach(kontingent => kontingente.push(kontingent)));

        return kontingente;
    }

    public isSelectedTag(dayOfWeek: DayOfWeek, gruppenIds: string[]): boolean {
        return this.gruppenBelegungen
            .some(gruppenBelegung => gruppenIds.includes(gruppenBelegung.gruppeId!) &&
                gruppenBelegung.plaetze.some(gb => gb.wochentag === dayOfWeek));
    }
}
