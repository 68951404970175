/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxVorauszahlung} from '@dv/shared/backend/model/jax-vorauszahlung';
import type {IRestModel} from '@dv/shared/code';
import {Rechnung} from '../Rechnung';
import {AbstractKontaktpersonZahlung} from './AbstractKontaktpersonZahlung';

export class Vorauszahlung extends AbstractKontaktpersonZahlung implements IRestModel<JaxVorauszahlung> {

    public rechnung: Rechnung | null = null;
    public gebuehrId: string | null = null;

    public static apiResponseTransformer(data: any): Vorauszahlung {
        const answer = super.baseTransformer<Vorauszahlung>(data, new Vorauszahlung());

        if (data.rechnung) {
            answer.rechnung = Rechnung.apiResponseTransformer(data.rechnung);
        }

        answer.gebuehrId = data.gebuehrId ?? null;

        return answer;
    }

    public override toRestObject(): JaxVorauszahlung {
        return {
            ...super.toRestObject(),
            gebuehrId: this.gebuehrId ?? undefined,
        };
    }
}
