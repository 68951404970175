/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek, IPersistable} from '@dv/shared/code';
import {BelegungsEinheit} from './BelegungsEinheit';

export class Tagesplan implements IPersistable {

    public constructor(
        public id: string | null = null,
        public wochentag: DayOfWeek,
        public belegungsEinheiten: BelegungsEinheit[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): Tagesplan {
        const belegungsEinheiten = Array.isArray(data.belegungsEinheiten) ?
            data.belegungsEinheiten.map((be: any[]) => BelegungsEinheit.apiResponseTransformer(be)) :
            [];

        return new Tagesplan(data.id, data.wochentag, belegungsEinheiten);
    }

}
