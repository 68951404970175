/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export enum MandantCustomPositionType {
    MANUAL = 'MANUAL',
    BEFORE_VERRECHNUNG_AB = 'BEFORE_VERRECHNUNG_AB',
    ANZAHL_RABATT = 'ANZAHL_RABATT',
    RABATT_PCT = 'RABATT_PCT',
    PAUSCHALPREIS = 'PAUSCHALPREIS',
    CONFIGURED = 'CONFIGURED',
}
