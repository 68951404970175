/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {MandantRechnungConfigPosition} from './MandantRechnungConfigPosition';

export class MandantRechnungConfig implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public verrechnungAb: moment.Moment | null = null,
        public anzahlRabatt: number | null = null,
        public rabattPct: number | null = null,
        public pauschalPreis: number | null = null,
        public pauschalPreisBis: moment.Moment | null = null,
        public positions: MandantRechnungConfigPosition [] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): MandantRechnungConfig {
        const answer = new MandantRechnungConfig(
            data.id,
            DvbRestUtil.localDateToMoment(data.verrechnungAb),
            data.anzahlRabatt,
            data.rabattPct,
            data.pauschalPreis,
            DvbRestUtil.localDateToMoment(data.pauschalPreisBis),
        );

        answer.positions = DvbRestUtil.transformArray(data.positions, MandantRechnungConfigPosition);

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            verrechnungAb: DvbRestUtil.momentToLocalDate(this.verrechnungAb),
            anzahlRabatt: this.anzahlRabatt,
            rabattPct: this.rabattPct,
            pauschalPreis: this.pauschalPreis,
            pauschalPreisBis: DvbRestUtil.momentToLocalDate(this.pauschalPreisBis),
            positions: this.positions.map(pos => pos.toRestObject()),
        };
    }
}

