/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import type {KitaPermissionType, PersonalPermissionType} from '@dv/shared/roles';

export class KitaPermission implements IRestModel, IPersistable {

    public constructor(
        public id: string | null = null,
        public benutzerId: string | null = null,
        public kitaId: string | null = null,
        public kitaName: string | null = null,
        public kitaPermission: KitaPermissionType | null = null,
        public personalPermission: PersonalPermissionType | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): KitaPermission {
        return new KitaPermission(
            data.id,
            data.benutzerId,
            data.kitaId,
            data.kitaName,
            data.kitaPermission,
            data.personalPermission,
        );
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            benutzerId: this.benutzerId,
            kitaId: this.kitaId,
            kitaPermission: this.kitaPermission,
            personalPermission: this.personalPermission,
        };
    }
}
