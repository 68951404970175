/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {BankStatementEntry} from './BankStatementEntry';

export class BankStatementImport implements ILimited {

    public constructor(
        public bankStatementId: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public totalBetrag: number | null = null,
        public duplicates: BankStatementEntry[] = [],
        public totalEntries: number | null = null,
        public successfulAssignedImports: number | null = null,
        public notAssignedImports: number | null = null,
        public ignoredImports: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): BankStatementImport {
        const answer = new BankStatementImport();
        answer.bankStatementId = data.bankStatementId;
        answer.gueltigAb = DvbRestUtil.localDateToMoment(data.gueltigAb);
        answer.gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis);
        answer.totalBetrag = parseFloat(data.totalBetrag);
        answer.totalEntries = parseInt(data.totalEntries, 10);
        answer.successfulAssignedImports = parseInt(data.successfulAssignedImports, 10);
        answer.notAssignedImports = parseInt(data.notAssignedImports, 10);
        answer.ignoredImports = parseInt(data.ignoredImports, 10);

        if (Array.isArray(data.duplicates)) {
            answer.duplicates = data.duplicates
                .map((entry: any) => BankStatementEntry.apiResponseTransformer(entry));
        }

        return answer;
    }
}
