/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxSmallInvoiceLayout} from '@dv/shared/backend/model/jax-small-invoice-layout';

export class SmallInvoiceLayout {

    public constructor(
        public id: number | null = null,
        public name: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxSmallInvoiceLayout): SmallInvoiceLayout {
        return new SmallInvoiceLayout(data.id, data.name);
    }
}
