/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IRestModel, IValidable} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {RechnungsLaufEntry} from './RechnungsLaufEntry';

export class RechnungsLauf implements IRestModel, ILimited, IValidable {

    public constructor(
        public kitaId: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public rechnungsDatum: moment.Moment | null = null,
        public faelligAm: moment.Moment | null = null,
        public valutaDatum: moment.Moment | null = null,
        public entries: RechnungsLaufEntry[] = [],
    ) {
    }

    public hasEntryWithAbweichungToPrevious(): boolean {
        return this.entries.some(entry => entry.hasAbweichungToPrevious());
    }

    public isValid(): boolean {
        return DvbDateUtil.isValidMoment(this.gueltigAb) &&
            DvbDateUtil.isValidMoment(this.gueltigBis) &&
            DvbDateUtil.isValidMoment(this.rechnungsDatum) &&
            DvbDateUtil.isValidMoment(this.faelligAm) &&
            this.gueltigBis.isAfter(this.gueltigAb) &&
            this.faelligAm.isAfter(this.rechnungsDatum);
    }

    public toRestObject(withRelations: boolean = false): any {
        return {
            kitaId: this.kitaId,
            periode: {
                gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
                gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            },
            rechnungsDatum: DvbRestUtil.momentToLocalDate(this.rechnungsDatum),
            faelligAm: DvbRestUtil.momentToLocalDate(this.faelligAm),
            valutaDatum: DvbRestUtil.momentToLocalDate(this.valutaDatum),
            entries: withRelations ? this.entries.map(b => b.toRestObject()) : undefined,
        };
    }
}
