/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayNameService} from '@dv/shared/config';
import type {Translator} from '@dv/shared/translator';
import type {StateService} from '@uirouter/core';
import type angular from 'angular';
import type {IAppConfigService} from './IAppConfigService';
import type {IErrorService} from './IErrorService';
import type {IWochenplanService} from './IWochenplanService';

/**
 * The ServiceContainer is a util to reference widely used services from AngularJS as well as from kitAdmin itself.
 * The services get injected into the ServiceContainer in <code>init.ts</code> and can be statically accessed in
 * Typescript without having to inject the service each time.
 *
 * <b>Keep in mind:</b>
 * <ul>
 * <li>Services used this way are mockable.</li>
 * <li>Services used this way are not treeshakable.</li>
 * </ul>
 */
export class ServiceContainer {
    // AngularJS
    public static $q: angular.IQService;
    public static $http: angular.IHttpService;
    public static $cacheFactory: angular.ICacheFactoryService;
    public static $translate: Translator;
    public static $window: angular.IWindowService;
    public static $filter: angular.IFilterService;
    public static $state: StateService;

    // kitAdmin
    public static appConfigService: IAppConfigService;
    public static wochenplanService: IWochenplanService;
    public static errorService: IErrorService;
    public static displayNameService: IDisplayNameService;

    public static restBackend: string = '';
}

serviceContainer.$inject = [
    // AngularJS
    '$q',
    '$http',
    '$cacheFactory',
    '$translate',
    '$window',
    '$filter',
    '$state',

    // kitAdmin
    'appConfigService',
    'wochenplanService',
    'errorService',
    'displayNameService',
];

export function serviceContainer(
    $q: angular.IQService,
    $http: angular.IHttpService,
    $cacheFactory: angular.ICacheFactoryService,
    $translate: Translator,
    $window: angular.IWindowService,
    $filter: angular.IFilterService,
    $state: StateService,
    appConfigService: IAppConfigService,
    wochenplanService: IWochenplanService,
    errorService: IErrorService,
    displayNameService: IDisplayNameService,
    // eslint-disable-next-line @typescript-eslint/ban-types
): angular.Injectable<Function> {

    // AngularJS
    ServiceContainer.$q = $q;
    ServiceContainer.$http = $http;
    ServiceContainer.$cacheFactory = $cacheFactory;
    ServiceContainer.$translate = $translate;
    ServiceContainer.$window = $window;
    ServiceContainer.$filter = $filter;
    ServiceContainer.$state = $state;

    // kitAdmin
    ServiceContainer.appConfigService = appConfigService;
    ServiceContainer.wochenplanService = wochenplanService;
    ServiceContainer.errorService = errorService;
    ServiceContainer.displayNameService = displayNameService;

    return ServiceContainer;
}
