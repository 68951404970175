/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DECIMAL_PCT_FACTOR} from '@dv/shared/code';
import {AbstractLimitedPlaetze} from './kontingente/LimitedPlaetze';

export type BewilligtePlaetzeType = 'BEWILLIGTEPLAETZE';

export class BewilligtePlaetze extends AbstractLimitedPlaetze<BewilligtePlaetzeType> {
    public static override $inject: readonly string[] = [];

    public constructor() {
        super('BEWILLIGTEPLAETZE');
    }

    public static apiResponseTransformer(data: any): BewilligtePlaetze {
        data.prozentPunkteProWoche = parseFloat(data.plaetze) * DECIMAL_PCT_FACTOR;

        return super.baseTransformer(data, new BewilligtePlaetze());
    }

    public override toRestObject(): Record<string, unknown> {
        const answer = super.toRestObject();
        delete answer.prozentPunkteProWoche;
        answer.plaetze = this.plaetze;

        return answer;
    }
}
