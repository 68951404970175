/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IEntityList, IPersistable, IRestModel, ISearchable, IValidable, SearchResultEntry} from '@dv/shared/code';
import {checkPresent, END_OF_TIME} from '@dv/shared/code';
import type moment from 'moment';
import type {GruppenWochenBelegung} from '../belegung/GruppenWochenBelegung';
import type {CustomFieldValue} from '../customfield/CustomFieldValue';
import type {KinderOrt, KinderOrtId} from './KinderOrt';
import type {WochenKapazitaet} from './WochenKapazitaet';
import type {Wochenplan} from './Wochenplan';

export enum KinderOrtFraktionType {
    BETREUUNGS_PERSON = 'BETREUUNGS_PERSON',
    GRUPPE = 'GRUPPE',
}

export type KinderOrtFraktionId = string;

export abstract class KinderOrtFraktion implements IEntityList, ISearchable, IRestModel, IPersistable, IValidable {

    protected constructor(
        public dtype: KinderOrtFraktionType,
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment = END_OF_TIME,
        public wochenKapazitaeten: WochenKapazitaet[] = [],
        public wochenplan: Wochenplan | null = null,
        public wochenplanId: string | null = null,
        public gruppenWochenBelegungen: GruppenWochenBelegung[] = [],
        public kita: KinderOrt | null = null,
        public kinderOrtId: KinderOrtId | null = null,
        public customFieldValues: CustomFieldValue[] = [],
    ) {
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public badgeText(): string | undefined {
        return undefined;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public toRestObject(): { dtype: string; id: string | null; attributes: Record<string, unknown> } {
        return {
            dtype: this.dtype,
            id: this.id,
            attributes: {
                dtype: this.dtype,
            },
        };
    }

    public abstract toSearchResultEntry(): SearchResultEntry;

    public abstract getDisplayName(): string;

    public abstract isValid(): boolean;
}
