/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Displayable, Geschlecht, IDisplayable, Persisted, Selectable} from '@dv/shared/code';
import {Adresse, checkPersisted, checkPresent, isPresent} from '@dv/shared/code';
import type moment from 'moment';
import type {BelegteEinheit} from '../belegung/BelegteEinheit';
import {Pensum} from '../belegung/Pensum';
import {WochenplanBewerbung} from '../belegung/WochenplanBewerbung';
import {CustomFieldValueNotNamed} from '../customfield/CustomFieldValueNotNamed';
import type {KinderOrt} from '../kinderort/KinderOrt';
import type {Language} from '../locale/Language';
import {ExternalAnmeldung} from './ExternalAnmeldung';
import type {ExternalAnmeldungCustomField} from './ExternalAnmeldungCustomField';
import type {ExternalKontaktperson} from './ExternalKontaktperson';
import type {KindFormState} from './public/KindFormState';
import type {WochenplanExternalAnmeldung} from './WochenplanExternalAnmeldung';

export type WochenplanModel = {
    wochenplan: WochenplanExternalAnmeldung;
    model: WochenplanBewerbung;
};

export const DEFAULT_WOCHENPLAN = 'default';

/**
 * Not persisted information of an external anmeldung kind. Used for signing up multiple children at once. Gets
 * converted to an ExternalAnmeldung for saving.
 */
export class ExternalAnmeldungKind implements IDisplayable {

    public constructor(
        public familienName: string = '',
        public vorName: string = '',
        public geburtsTag: moment.Moment | null = null,
        public geburtsTermin: moment.Moment | null = null,
        public geschlecht: Geschlecht | null = null,
        public muttersprache: Language | null = null,
        public adresse: Adresse = new Adresse(),
        public kinderOrte: KinderOrt[] = [],
        public pensum: Pensum = new Pensum(),
        public subventionierterPlatz: boolean = false,
        public privaterPlatz: boolean = false,
        public gewuenschteBetreuungAb: moment.Moment | null = null,
        public platzartSelection: { [firmaId: EntityId]: boolean } = {},
        public adressWieHauptkontakt: boolean = true,
        public selectedKinderOrte: Selectable<Displayable>[] = [],
        public customFieldValues: CustomFieldValueNotNamed[] = [],
        public availableWochenplaene: WochenplanExternalAnmeldung[] = [],
        public editedWochenplaene: {
            // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
            [wochenplanExternalAnmeldungId: EntityId | typeof DEFAULT_WOCHENPLAN]: WochenplanModel;
        } = {},
        public selectedWochenplan: WochenplanExternalAnmeldung | null = null,
        public form: KindFormState = {
            showStammdaten: true,
            showBetreuung: true,
            showAddress: true,
        },
    ) {
    }

    public isGeburtsDatumTerminSet(): boolean {
        return isPresent(this.geburtsTermin) || isPresent(this.geburtsTag);
    }

    public isPlatzArtSelected(): boolean {
        return this.subventionierterPlatz || this.privaterPlatz || Object.values(this.platzartSelection).some(p => p);
    }

    public getDisplayName(): string {
        return `${this.familienName} ${this.vorName || ''}`;
    }

    public initCustomFieldValues(customFields: ExternalAnmeldungCustomField[]): void {
        this.customFieldValues = customFields.map(field => new CustomFieldValueNotNamed(null, field));
    }

    public getSelectedWochenplanBewerbung(): WochenplanBewerbung {
        return checkPresent(this.editedWochenplaene[this.selectedWochenplan?.id ?? DEFAULT_WOCHENPLAN]).model;
    }

    public getOrCreateWochenplanModel(wochenplan: WochenplanExternalAnmeldung): WochenplanModel {
        const model: WochenplanModel = this.editedWochenplaene[wochenplan?.id ?? DEFAULT_WOCHENPLAN] ??= {
            wochenplan,
            model: new WochenplanBewerbung(checkPersisted(wochenplan.wochenplan)),
        };

        return model;
    }

    public toExternalAnmeldung(
        kinderOrte: Persisted<KinderOrt>[],
        externalKontaktpersonen: ExternalKontaktperson[],
        belegteEinheiten: BelegteEinheit[],
        bemerkung: string,
    ): ExternalAnmeldung {
        const selectedPlatzarten = Object.entries(this.platzartSelection)
            .filter(([_key, value]) => value)
            .map(([key]) => key);

        return new ExternalAnmeldung(
            null,
            this.familienName,
            this.vorName,
            this.geburtsTag,
            this.geburtsTermin,
            checkPresent(this.geschlecht),
            this.muttersprache,
            this.adresse,
            bemerkung,
            kinderOrte,
            this.pensum,
            this.subventionierterPlatz,
            this.privaterPlatz,
            externalKontaktpersonen,
            this.gewuenschteBetreuungAb,
            belegteEinheiten,
            this.editedWochenplaene[this.selectedWochenplan?.id ?? DEFAULT_WOCHENPLAN]?.wochenplan?.wochenplan,
            [],
            selectedPlatzarten,
            this.customFieldValues);
    }
}
