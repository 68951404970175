/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungsPositionType} from '@dv/shared/code';
import type {TarifPositionType} from '../leistungen/TarifPositionType';
import {RechnungsPosition} from './RechnungsPosition';

export class KindRechnungsPosition extends RechnungsPosition {

    public constructor(
        public tarifPositionType: TarifPositionType | null = null,
        id: string | null = null,
        positionType: RechnungsPositionType | null = null,
        text: string | null = null,
        betrag: number | null = 0,
        anzahl: number = 0,
        summe: number = 0,
    ) {
        super(id, positionType, text, betrag, anzahl, summe);
    }

    public static apiResponseTransformer(data: any): KindRechnungsPosition {
        const position = new KindRechnungsPosition(data.tarifPositionType);
        RechnungsPosition.baseTransformer(data, position);

        return position;
    }
}
