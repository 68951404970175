/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {stringUnion} from '@dv/shared/code';
import {KinderOrtFraktionTransformer} from '../KinderOrtTransformer';
import type {BetreuungsPerson} from './BetreuungsPerson';

export const MONATLICHE_STUNDEN_ERFASSUNG_STATUS = stringUnion(
    'OFFEN',
    'FREIGEGEBEN',
    'KORRIGIERT',
    'BESTAETIGT');

export type MonatlicheStundenErfassungStatus = typeof MONATLICHE_STUNDEN_ERFASSUNG_STATUS.type;

export const MONATLICHE_STUNDEN_ERFASSUNG_FILTER_STATES = stringUnion(
    ...MONATLICHE_STUNDEN_ERFASSUNG_STATUS.values,
    'NONE');

export type MonatlicheStundenErfassungFilterStates = typeof MONATLICHE_STUNDEN_ERFASSUNG_FILTER_STATES.type;

export class BetreuungsPersonWithStatus {

    public constructor(
        public betreuungsPerson: BetreuungsPerson,
        public status: MonatlicheStundenErfassungFilterStates,
    ) {
    }

    public static apiResponseTransformer(data: any): BetreuungsPersonWithStatus {
        const transformer = KinderOrtFraktionTransformer.create();

        const status = data.status ? data.status : 'NONE';

        return new BetreuungsPersonWithStatus(
            transformer.apiResponseTransformer(data.betreuungsPerson) as BetreuungsPerson,
            status);
    }

}
