/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxRelationship} from '@dv/shared/backend/model/jax-relationship';
import {Abholberechtigt} from './Abholberechtigt';
import {Erziehungsberechtigter} from './Erziehungsberechtigter';
import {Hauptkontakt} from './Hauptkontakt';
import {Rechnungsempfaenger} from './Rechnungsempfaenger';
import {SonstigerKontakt} from './SonstigerKontakt';

export class KindKontaktRelationship {

    public constructor(
        public hauptkontakt: Hauptkontakt | null = null,
        public erziehungsberechtigter: Erziehungsberechtigter | null = null,
        public sonstigerKontakt: SonstigerKontakt | null = null,
        public rechnungsempfaenger: Rechnungsempfaenger | null = null,
        public abholberechtigt: Abholberechtigt | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxRelationship): KindKontaktRelationship {
        const answer = new KindKontaktRelationship();
        if (data.hauptkontakt) {
            answer.hauptkontakt = Hauptkontakt.apiResponseTransformer(data.hauptkontakt);
        }
        if (data.erziehungsberechtigter) {
            answer.erziehungsberechtigter = Erziehungsberechtigter.apiResponseTransformer(
                data.erziehungsberechtigter);
        }
        if (data.sonstigerKontakt) {
            answer.sonstigerKontakt = SonstigerKontakt.apiResponseTransformer(data.sonstigerKontakt);
        }
        if (data.rechnungsempfaenger) {
            answer.rechnungsempfaenger = Rechnungsempfaenger.apiResponseTransformer(data.rechnungsempfaenger);
        }
        if (data.abholberechtigt) {
            answer.abholberechtigt = Abholberechtigt.apiResponseTransformer(data.abholberechtigt);
        }

        return answer;
    }

    public isAdresseRequired(): boolean {
        return this.isHauptkontakt() || this.isErziehungsberechtigter() || this.isRechnungsempfaenger();
    }

    public isHauptkontakt(): boolean {
        return !!this.hauptkontakt;
    }

    public createHauptkontakt(): void {
        this.hauptkontakt = new Hauptkontakt();
    }

    public deleteHauptkontakt(): void {
        this.hauptkontakt = null;
    }

    public isRechnungsempfaenger(): boolean {
        return !!this.rechnungsempfaenger;
    }

    public createRechnungsempfaenger(): void {
        this.rechnungsempfaenger = new Rechnungsempfaenger(Rechnungsempfaenger.RECHNUNG_AUFTEILUNG_TYPE.PROZENT);
    }

    public deleteRechnungsempfaenger(): void {
        this.rechnungsempfaenger = null;
    }

    public isErziehungsberechtigter(): boolean {
        return !!this.erziehungsberechtigter;
    }

    public createErziehungsberechtigter(): void {
        this.erziehungsberechtigter = new Erziehungsberechtigter();
    }

    public deleteErziehungsberechtigter(): void {
        this.erziehungsberechtigter = null;
    }

    public isSonstigerKontakt(): boolean {
        return !!this.sonstigerKontakt;
    }

    public createSonstigerKontakt(): void {
        this.sonstigerKontakt = new SonstigerKontakt();
    }

    public deleteSonstigerKontakt(): void {
        this.sonstigerKontakt = null;
    }

    public isAbholberechtigt(): boolean {
        return !!this.abholberechtigt;
    }

    public createAbholberechtigt(): void {
        this.abholberechtigt = new Abholberechtigt();
    }

    public deleteAbholberechtigt(): void {
        this.abholberechtigt = null;
    }

    public toRestObject(): JaxRelationship {
        return {
            hauptkontakt: this.hauptkontakt?.toRestObject(),
            erziehungsberechtigter: this.erziehungsberechtigter?.toRestObject(),
            sonstigerKontakt: this.sonstigerKontakt?.toRestObject(),
            rechnungsempfaenger: this.rechnungsempfaenger?.toRestObject(),
            abholberechtigt: this.abholberechtigt?.toRestObject(),
        };
    }
}
