/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class ClientNotification implements IPersistable {

    public constructor(
        public id: string | null = null,
        public notification: string | null = null,
        public dueDate: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): ClientNotification {
        return new ClientNotification(
            data.id,
            data.notification,
            DvbRestUtil.localDateTimeToMoment(data.dueDate),
        );
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            notification: this.notification,
            dueDate: DvbRestUtil.momentToLocalDateTime(checkPresent(this.dueDate)),
        };
    }
}
