/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxTerminType} from '@dv/shared/backend/model/jax-termin-type';
import type {ILimited, IPersistable, IRestModel, IZeitraum, Persisted} from '@dv/shared/code';
import {
    checkPersisted,
    checkPresent,
    ColorUtil,
    DvbDateUtil,
    DvbRestUtil,
    Gueltigkeit,
    isNullish,
    isPresent,
    TimeRange,
    TimeRangeUtil,
} from '@dv/shared/code';
import moment from 'moment';
import {RRuleUtil} from './rrule/RRuleUtil';

export class Termin implements IPersistable, IRestModel, ILimited {

    private static readonly DATE_FORMAT = 'DD.MM.YYYY';

    public rruleText: string | null = null;
    public hasHighLuminance: boolean = false;

    private _rruleStr: string | null = null;

    public constructor(
        public id: string | null = null,
        public angestellteIds: string[] = [],
        public excludedAngestellteIds: string[] = [],
        public kinderOrtIds: string[] = [],
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public von: moment.Moment | null = null,
        public bis: moment.Moment | null = null,
        public title: string | null = null,
        public bemerkung: string | null = null,
        public backgroundColor: string | null = null,
        public textColor: string | null = null,
        public alleKinderOrte: boolean = false,
        public alleAngestellte: boolean = false,
        public wiederkehrend: boolean = false,
        public recurrenceEnd: moment.Moment | null = null,
        rruleStr: string | null = null,
        public parent: Persisted<Termin> | null = null,
        public excludedDateRanges: Gueltigkeit[] = [],
        public terminType: JaxTerminType | null = null,
    ) {
        this.rruleStr = rruleStr;
        this.hasHighLuminance = this.backgroundColor ? ColorUtil.hasHighLuminance(this.backgroundColor) : false;
    }

    public get rruleStr(): string | null {
        return this._rruleStr;
    }

    public set rruleStr(value: string | null) {
        this._rruleStr = value;
        if (value) {
            this.rruleText = RRuleUtil.rruleToText(value);
        }
    }

    public static apiResponseTransformer(data: any): Termin {
        return new Termin(
            data.id,
            Array.isArray(data.angestellteIds) ? data.angestellteIds : [],
            Array.isArray(data.excludedAngestellteIds) ? data.excludedAngestellteIds : [],
            Array.isArray(data.kinderOrtIds) ? data.kinderOrtIds : [],
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
            DvbRestUtil.localeHHMMTimeToMoment(data.von),
            DvbRestUtil.localeHHMMTimeToMoment(data.bis),
            data.title,
            data.bemerkung,
            data.colors.background,
            data.colors.text,
            data.alleKinderOrte,
            data.alleAngestellte,
            data.wiederkehrend,
            DvbRestUtil.localDateToMoment(data.recurrenceEnd),
            data.rruleStr,
            data.parent ? checkPersisted(Termin.apiResponseTransformer(data.parent)) : null,
            DvbRestUtil.transformArray(data.excludedDateRanges, Gueltigkeit),
            data.terminType);
    }

    public displayRange(affectingDay?: moment.Moment): string {
        const dates = this.buildDateRangeDisplayFormat(affectingDay);
        if (!dates) {
            return '';
        }

        const von = isNullish(this.von) ? '' : `${dates.dateTimeSpacer}${this.von.format('HH:mm')}`;

        const defaultBis = von && dates.datesVisible ? `${dates.dateTimeSpacer}23:59` : '';
        const bis = isNullish(this.bis) ? defaultBis : `${dates.dateTimeSpacer}${this.bis.format('HH:mm')}`;

        if (!dates.datesVisible && !von && !bis) {
            return this.gueltigAb!.format(Termin.DATE_FORMAT);
        }

        return `${dates.gueltigAb}${von} - ${dates.gueltigBis}${bis}`;
    }

    public affectsZeitraum(zeitraum: IZeitraum, date: moment.Moment): boolean {

        if (!date.isBetween(this.gueltigAb, this.gueltigBis, 'day', '[]')) {
            return false;
        }

        let affected = true;
        if (this.gueltigAb!.isSame(date, 'day') && isPresent(this.von)) {
            affected &&= zeitraum.bis!.isAfter(this.von);
        }
        if (this.gueltigBis!.isSame(date, 'day') && isPresent(this.bis)) {
            affected &&= zeitraum.von!.isBefore(this.bis);
        }

        return affected;
    }

    public toTimeRange(date: moment.Moment): TimeRange | null {
        if (!this.affectsDate(date)) {
            return null;
        }

        const {von, bis} = TimeRangeUtil.toTimeRangeAtLocalDate(this, date);

        return new TimeRange(DvbDateUtil.toHHMMMoment(checkPresent(von)), DvbDateUtil.toHHMMMoment(checkPresent(bis)));
    }

    public toCompleteTimeRange(): TimeRange {
        checkPresent(this.gueltigAb);
        checkPresent(this.gueltigBis);

        const start = moment(this.gueltigAb);
        if (this.von) {
            start.hours(this.von.hours());
            start.minutes(this.von.minutes());
        }
        const end = moment(this.gueltigBis);
        if (this.bis) {
            end.hours(this.bis.hours());
            end.minutes(this.bis.minutes());
        } else {
            end.endOf('day');
        }

        return new TimeRange(start, end);
    }

    public affectsDate(date: moment.Moment): boolean {
        return date.isBetween(this.gueltigAb, this.gueltigBis, 'day', '[]');
    }

    public toRestObject(): any {
        return {
            id: this.id,
            angestellteIds: this.alleAngestellte ? [] : this.angestellteIds,
            excludedAngestellteIds: this.alleAngestellte ? this.excludedAngestellteIds : [],
            kinderOrtIds: this.alleKinderOrte ? [] : this.kinderOrtIds,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis ?? this.gueltigAb),
            von: DvbRestUtil.momentTolocaleHHMMTime(this.von),
            bis: DvbRestUtil.momentTolocaleHHMMTime(this.bis),
            title: this.title,
            bemerkung: this.bemerkung,
            colors: {
                background: this.backgroundColor,
                text: this.textColor,
            },
            alleKinderOrte: this.alleKinderOrte,
            alleAngestellte: this.alleAngestellte,
            wiederkehrend: this.wiederkehrend,
            recurrenceEnd: DvbRestUtil.momentToLocalDate(this.recurrenceEnd),
            rruleStr: this.rruleStr,
            excludedDateRanges: this.excludedDateRanges.map((range: Gueltigkeit) => range.toRestObject()),
            terminType: this.terminType,
        };
    }

    public isValid(): boolean {
        return this.hasValidAngestellte() && this.hasValidKinderOrt();
    }

    public hasValidAngestellte(): boolean {
        return this.alleAngestellte || (this.angestellteIds.length > 0 && this.excludedAngestellteIds.length === 0);
    }

    public hasValidKinderOrt(): boolean {
        return this.alleKinderOrte || this.kinderOrtIds.length > 0;
    }

    private buildDateRangeDisplayFormat(affectingDay?: moment.Moment)
        : { gueltigAb: string; gueltigBis: string; dateTimeSpacer: string; datesVisible: boolean } | undefined {

        if (isNullish(this.gueltigAb) || isNullish(this.gueltigBis)) {
            return undefined;
        }

        const isSingleDay = this.gueltigAb.isSame(this.gueltigBis, 'day');
        const hideDates = isSingleDay && affectingDay && this.gueltigAb.isSame(affectingDay, 'day');
        const gueltigAb = hideDates ? '' : this.gueltigAb.format(Termin.DATE_FORMAT);
        const gueltigBis = hideDates ? '' : this.gueltigBis.format(Termin.DATE_FORMAT);
        const dateTimeSpacer = hideDates ? '' : ' ';

        return {
            gueltigAb,
            gueltigBis,
            dateTimeSpacer,
            datesVisible: !hideDates,
        };
    }
}
