/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntityId} from '@dv/shared/backend/model/entity-id';
import {DvbRestUtil, type ILimited, type IPersistable, type IRestModel} from '@dv/shared/code';
import type moment from 'moment';
import {AbstractLeistungsPosition} from './AbstractLeistungsPosition';
import {TarifrechnungPosition} from './TarifrechnungPosition';

export class EingewoehnungPosition implements IPersistable, IRestModel, ILimited {
    public constructor(
        public id: string | null,
        public kindId: EntityId | null,
        public kinderOrtId: EntityId | null,
        public gueltigAb: moment.Moment | null,
        public gueltigBis: moment.Moment | null,
        public days: number | null = null,
        public leistungsMonatAb: moment.Moment | null,
        public totalBetrag: number | null = null,
        public leistungsPosition: TarifrechnungPosition | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): EingewoehnungPosition {
        return new EingewoehnungPosition(
            data.id,
            data.kindId,
            data.kinderOrtId,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
            data.days,
            DvbRestUtil.localDateToMoment(data.leistungsMonatAb),
            data.totalBetrag,
            AbstractLeistungsPosition.baseTransformer(data.leistungsPosition, new TarifrechnungPosition()),
        );
    }

    public toRestObject(): any {
        return {
            id: this.id,
            kindId: this.kindId,
            kinderOrtId: this.kinderOrtId,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            days: this.days,
            leistungsMonatAb: DvbRestUtil.momentToLocalDate(this.leistungsMonatAb),
            totalBetrag: this.totalBetrag,
        };
    }
}
