/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, Persisted} from '@dv/shared/code';
import {checkPersisted, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class BetreuungsGrund implements ILimited {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public grund: string | null = null,
        public prioritaet: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Persisted<BetreuungsGrund> {
        return checkPersisted(new BetreuungsGrund(data.id,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
            data.grund,
            parseInt(data.prioritaet, 10)));
    }
}
