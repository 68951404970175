/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    CustomFieldType,
    ICustomField,
    IPersistable,
    IRestModel,
    ISortable,
    NamedEntityType,
    ParamValueDType,
} from '@dv/shared/code';
import {checkPresent, CustomFieldUtil} from '@dv/shared/code';

export class ExternalAnmeldungCustomField implements IRestModel, IPersistable, ICustomField, ISortable {

    private static readonly SELECT_VALUE_SEPARATOR: string = ',';

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public namedEntityType: NamedEntityType | null = null,
        public selectValues: string | null = null,
        public fieldType: CustomFieldType | null = null,
        public orderValue: number | null = null,
        public paramValueType: ParamValueDType | null = fieldType ?
            CustomFieldUtil.customFieldTypeToParamValueType(fieldType) :
            null,
        private splitSelectValues: string[] | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): ExternalAnmeldungCustomField {
        return new ExternalAnmeldungCustomField(
            data.id,
            data.name,
            data.namedEntityType,
            data.selectValues,
            data.fieldType,
            data.orderValue,
        );
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            namedEntityType: this.namedEntityType,
            fieldType: this.fieldType,
            selectValues: this.selectValues,
            orderValue: this.orderValue,
        };
    }

    public getParamValueType(): ParamValueDType {
        return CustomFieldUtil.customFieldTypeToParamValueType(checkPresent(this.fieldType));
    }

    public getFieldName(): string | null {
        return this.name;
    }

    public getSplitSelectValues(): string[] {
        if (!this.splitSelectValues) {
            this.splitSelectValues =
                CustomFieldUtil.splitSelectValues(this.selectValues,
                    ExternalAnmeldungCustomField.SELECT_VALUE_SEPARATOR);
        }

        return this.splitSelectValues;
    }
}
