/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {IPersistable, IRestModel} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {KinderOrt} from '../../kinderort/KinderOrt';
import {KinderOrtTransformer} from '../../kinderort/KinderOrtTransformer';
import {Kontaktperson} from '../../kontakte/Kontaktperson';

export type JaxKontaktersponZahlung = {
    id?: string;
    kitaId?: string;
    datum: BackendLocalDate;
    betrag: number;
};

export abstract class AbstractKontaktpersonZahlung implements IPersistable, IRestModel<JaxKontaktersponZahlung> {

    public id: string | null = null;
    public kontaktperson: Kontaktperson | null = null;
    public kita: KinderOrt | null = null;
    public kitaId: string | null = null;
    public datum: moment.Moment | null = null;
    public betrag: number | null = null;

    protected static baseTransformer<T extends AbstractKontaktpersonZahlung>(data: any, answer: T): T {
        answer.id = data.id;
        answer.kitaId = data.kitaId;
        answer.datum = DvbRestUtil.localDateToMoment(data.datum);
        answer.betrag = parseFloat(data.betrag);

        if (data.kontaktperson) {
            answer.kontaktperson = Kontaktperson.apiResponseTransformer(data.kontaktperson);
        }

        if (data.kita) {
            answer.kita = KinderOrtTransformer.create().apiResponseTransformer(data.kita);
        }

        return answer;
    }

    public toRestObject(): JaxKontaktersponZahlung {
        return {
            id: this.id ?? undefined,
            kitaId: this.kitaId ?? undefined,
            datum: DvbRestUtil.momentToLocalDateChecked(this.datum),
            betrag: checkPresent(this.betrag),
        };
    }
}
