/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ICustomFieldValue, IPersistable, IRestModel} from '@dv/shared/code';
import {checkPresent, CustomFieldUtil, ParamValue} from '@dv/shared/code';
import {CustomField} from './CustomField';

export class CustomFieldValue implements IRestModel, IPersistable, ICustomFieldValue {

    public constructor(
        public id: string | null = null,
        public entityId: string | null = null,
        public customField: CustomField,
        public value: ParamValue = new ParamValue(null,
            CustomFieldUtil.customFieldTypeToParamValueType(checkPresent(customField.fieldType))),
    ) {
    }

    public static fromCustomFields(customFields: CustomField[]): CustomFieldValue[] {
        return customFields.map(field => new CustomFieldValue(null, null, field));
    }

    public static apiResponseTransformer(data: any): CustomFieldValue {
        const value = data.value ? ParamValue.apiResponseTransformer(data.value) : undefined;

        const customField = CustomField.apiResponseTransformer(data.customField);

        return new CustomFieldValue(data.id, data.entityId, customField, value);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            entityId: this.entityId,
            customField: this.customField.toRestObject(),
            updateValue: this.value.toRestObject(),
        };
    }
}
