/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, RechnungsPositionType} from '@dv/shared/code';

export abstract class RechnungsPosition implements IPersistable {

    protected constructor(
        public id: string | null = null,
        public positionType: RechnungsPositionType | null = null,
        public text: string | null = null,
        public betrag: number | null = 0,
        public anzahl: number = 0,
        public summe: number = 0,
    ) {
    }

    protected static baseTransformer<T extends RechnungsPosition>(data: any, position: T): void {
        position.id = data.id;
        position.positionType = data.positionType;
        position.text = data.text;
        position.betrag = parseFloat(data.betrag);
        position.anzahl = parseFloat(data.anzahl);
        position.summe = parseFloat(data.summe);
    }
}
