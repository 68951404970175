/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type OffenerPostenType = 'VORAUSZAHLUNG' | 'RECHNUNGS_REVISION' | 'VORAUSZAHLUNG_VERBUCHT' | 'EINZAHLUNG' | 'RUECKERSTATTUNG' | 'RUECKERSTATTUNG_AUSBEZAHLT' | 'GEBUEHR' | 'GEBUEHR_COMPENSATED';

export const OffenerPostenType = {
    VORAUSZAHLUNG: 'VORAUSZAHLUNG' as OffenerPostenType,
    RECHNUNGS_REVISION: 'RECHNUNGS_REVISION' as OffenerPostenType,
    VORAUSZAHLUNG_VERBUCHT: 'VORAUSZAHLUNG_VERBUCHT' as OffenerPostenType,
    EINZAHLUNG: 'EINZAHLUNG' as OffenerPostenType,
    RUECKERSTATTUNG: 'RUECKERSTATTUNG' as OffenerPostenType,
    RUECKERSTATTUNG_AUSBEZAHLT: 'RUECKERSTATTUNG_AUSBEZAHLT' as OffenerPostenType,
    GEBUEHR: 'GEBUEHR' as OffenerPostenType,
    GEBUEHR_COMPENSATED: 'GEBUEHR_COMPENSATED' as OffenerPostenType
};

