/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {TarifParameter} from './TarifParameter';

export class TarifParameterValue implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public parameter: TarifParameter | null = null,
        public value: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): TarifParameterValue {
        return new TarifParameterValue(
            data.id,
            TarifParameter.apiResponseTransformer(data.tarifParameter),
            data.value,
        );
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    public isValid(): boolean {
        return this.value !== null && !isNaN(this.value) && isFinite(this.value) && !!this.parameter;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            parameterId: this.parameter ? this.parameter.id : null,
            value: this.value,
        };
    }
}
