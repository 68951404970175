/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class TempBlob implements IPersistable {

    public constructor(
        public id: string | null = null,
        public accessToken: string | null = null,
        public timestampErstellt: moment.Moment | null = null,
        public timestampMutiert: moment.Moment | null = null,
        public filename: string | null = null,
        public mimeType: string | null = null,
        public size: number | null = null,
        public href: string | null = null,
        public virusFound: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): TempBlob {
        const answer = new TempBlob();

        answer.id = data.id;
        answer.accessToken = data.accessToken;
        answer.timestampErstellt = DvbRestUtil.localDateTimeToMoment(data.timestampErstellt);
        answer.timestampMutiert = DvbRestUtil.localDateTimeToMoment(data.timestampMutiert);
        answer.filename = data.filename;
        answer.mimeType = data.mimeType;
        answer.size = data.size;
        answer.href = data.href;
        answer.virusFound = data.virusFound;

        return answer;
    }

    public getDownloadUrl(restBackend: string): string {
        return restBackend + checkPresent(this.href);
    }
}
