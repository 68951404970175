/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {JaxMonatsBelegung} from '@dv/shared/backend/model/jax-monats-belegung';
import type {ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {BelegungsZustand, DvbRestUtil, presentCustomFieldValues} from '@dv/shared/code';
import type moment from 'moment';
import {CustomFieldValueNotNamed} from '../../customfield/CustomFieldValueNotNamed';
import type {Kontingente} from '../../kinderort/kontingente/KontingentTransformer';
import {KontingentTransformer} from '../../kinderort/kontingente/KontingentTransformer';
import {Belegung} from '../Belegung';
import {AnwesenheitCustomField} from './AnwesenheitCustomField';
import {AnwesenheitsZeit} from './AnwesenheitsZeit';

export class MonatsBelegung implements IRestModel, IPersistable, ILimited {

    public constructor(
        public id: string | null = null,
        public status: BelegungsZustand = BelegungsZustand.BELEGT,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public belegungen: Belegung[] = [],
        public anwesenheitsZeiten: AnwesenheitsZeit[] = [],
        public standardKontingent: Kontingente | null = null,
        public standardKontingentId: string | null = null,
        public standardVertraglichesPensum: number | null = null,
        public customFieldValuesPerDate: Map<BackendLocalDate, CustomFieldValueNotNamed[]> = new Map(),
        public contactUserTimestampMutiert: BackendLocalDate | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxMonatsBelegung): MonatsBelegung {
        const monatsBelegung = new MonatsBelegung(
            data.id,
            BelegungsZustand.BELEGT,
            DvbRestUtil.localDateTimeToMoment(data.gueltigAb),
            DvbRestUtil.localDateTimeToMoment(data.gueltigBis),
            data.belegungen.map(b => Belegung.apiResponseTransformer(b)),
            data.anwesenheitsZeiten.map(zeit => AnwesenheitsZeit.apiResponseTransformer(zeit)),
        );

        if (data.standardKontingent) {
            monatsBelegung.standardKontingent = KontingentTransformer.apiResponseTransformer(data.standardKontingent);
        }

        monatsBelegung.standardVertraglichesPensum = data.standardVertraglichesPensum ?? null;

        monatsBelegung.customFieldValuesPerDate = new Map(Object.entries(data.customFieldValuesPerDate ?? [])
            .map(([date, customFieldValues]) => [
                date,
                CustomFieldValueNotNamed.apiResponseTransformer(customFieldValues, AnwesenheitCustomField),
            ]));

        monatsBelegung.contactUserTimestampMutiert = data.contactUserTimestampMutiert ?? null;

        return monatsBelegung;
    }

    public toRestObject(): Record<string, unknown> {
        const customFieldValuesPerDate = new Map();

        this.customFieldValuesPerDate.forEach((customFieldValues, key) => {
            const values = presentCustomFieldValues(customFieldValues)
                .map(val => val.toRestObject());
            if (values.length) {
                customFieldValuesPerDate.set(key, values);
            }
        });

        return {
            id: this.id,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            belegungen: this.belegungen?.map(belegung => belegung.toRestObject()),
            anwesenheitsZeiten: this.anwesenheitsZeiten?.map(zeit => zeit.toRestObject?.()),
            standardKontingentId: this.standardKontingentId,
            standardVertraglichesPensum: this.standardVertraglichesPensum,
            customFieldValuesPerDate: Object.fromEntries(customFieldValuesPerDate),
            contactUserTimestampMutiert: this.contactUserTimestampMutiert,
        };
    }
}
