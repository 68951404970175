/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbUtil} from '@dv/shared/code';
import type {IRechnungsKonfiguration} from '../konfiguration/IRechnungsKonfiguration';

export class SmallInvoiceMandantKonfiguration implements IRechnungsKonfiguration {

    public static readonly TOKEN_LENGTH: number = 32;

    public constructor(public token: string | null = null) {
    }

    public static apiResponseTransformer(data: any): SmallInvoiceMandantKonfiguration {
        return new SmallInvoiceMandantKonfiguration(data.token);
    }

    /**
     * @param isNew if this entity is a new one or an edit of an existing one
     */
    public isValid(isNew: boolean): boolean {
        if (!isNew) {
            return true;
        }

        return DvbUtil.isNotEmptyString(this.token) &&
            this.token.length === SmallInvoiceMandantKonfiguration.TOKEN_LENGTH;
    }

    public toRestObject(): { token: string | null } {
        return {
            token: this.token,
        };
    }
}
