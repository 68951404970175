/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {ContextualAdresse} from '../adressen/ContextualAdresse';
import {Translation} from '../i18n/Translation';
import type {PensumDisplayType} from './PensumDisplayType';

export class BetreuungsVereinbarungsKonfiguration implements IRestModel, IPersistable {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public contextualAdresse: ContextualAdresse | null = null,
        public dokumentLayoutId: string | null = null,
        public alternativeKinderOrtName: Translation | null = null,
        public einfuehrung: Translation | null = null,
        public konditionen: Translation | null = null,
        public gruppeVerstecken: boolean = false,
        public platzartVerstecken: boolean = false,
        public pensumVerstecken: boolean = false,
        public showModuleNames: boolean = false,
        public unterschrift1: boolean = true,
        public unterschrift1Str: Translation | null = null,
        public unterschrift2: boolean = false,
        public unterschrift2Str: Translation | null = null,
        public unterschriftTitleVerstecken: boolean = false,
        public fusszeile: Translation | null = null,
        public pensumDisplayType: PensumDisplayType = 'PCT',
        public faktorVerstecken: boolean = false,
        public belegungZeitenVerstecken: boolean = true,
        public kinderOrtNameVerstecken: boolean = false,
        public betreuungBeiFamilie: boolean = false,
    ) {
    }

    public static createNew(): BetreuungsVereinbarungsKonfiguration {
        const result = new BetreuungsVereinbarungsKonfiguration();
        result.contextualAdresse = new ContextualAdresse(ContextualAdresse.ADRESSE_CONTEXT.MANDANT);
        result.alternativeKinderOrtName = new Translation();
        result.einfuehrung = new Translation();
        result.konditionen = new Translation();
        result.unterschrift1Str = new Translation();
        result.unterschrift2Str = new Translation();
        result.fusszeile = new Translation();

        return result;
    }

    public static apiResponseTransformer(data: any): BetreuungsVereinbarungsKonfiguration {
        return new BetreuungsVereinbarungsKonfiguration(
            data.id,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
            ContextualAdresse.apiResponseTransformer(data.contextualAdresse),
            data.dokumentLayoutId,
            data.alternativeKinderOrtName ?
                Translation.apiResponseTransformer(data.alternativeKinderOrtName) :
                new Translation(),
            data.einfuehrung ? Translation.apiResponseTransformer(data.einfuehrung) : new Translation(),
            data.konditionen ? Translation.apiResponseTransformer(data.konditionen) : new Translation(),
            data.gruppeVerstecken,
            data.platzartVerstecken,
            data.pensumVerstecken,
            data.showModuleNames,
            data.unterschrift1,
            data.unterschrift1Str ? Translation.apiResponseTransformer(data.unterschrift1Str) : new Translation(),
            data.unterschrift2,
            data.unterschrift2Str ? Translation.apiResponseTransformer(data.unterschrift2Str) : new Translation(),
            data.unterschriftTitleVerstecken,
            data.fusszeile ? Translation.apiResponseTransformer(data.fusszeile) : new Translation(),
            data.pensumDisplayType,
            data.faktorVerstecken,
            data.belegungZeitenVerstecken,
            data.kinderOrtNameVerstecken,
            data.betreuungBeiFamilie,
        );
    }

    public applyUnterschriftState(): void {
        if (!this.unterschrift1) {
            this.unterschrift2 = false;
            this.unterschrift1Str = new Translation();
            this.unterschrift2Str = new Translation();
        } else if (!this.unterschrift2) {
            this.unterschrift2Str = new Translation();
        }
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            contextualAdresse: this.contextualAdresse?.toRestObject(),
            dokumentLayoutId: this.dokumentLayoutId,
            alternativeKinderOrtName: this.alternativeKinderOrtName?.toRestObject(),
            einfuehrung: this.einfuehrung?.toRestObject(),
            konditionen: this.konditionen?.toRestObject(),
            gruppeVerstecken: this.gruppeVerstecken,
            platzartVerstecken: this.platzartVerstecken,
            pensumVerstecken: this.pensumVerstecken,
            showModuleNames: this.showModuleNames,
            unterschrift1: this.unterschrift1,
            unterschrift1Str: this.unterschrift1Str?.toRestObject(),
            unterschrift2: this.unterschrift2,
            unterschrift2Str: this.unterschrift2Str?.toRestObject(),
            unterschriftTitleVerstecken: this.unterschriftTitleVerstecken,
            fusszeile: this.fusszeile?.toRestObject(),
            pensumDisplayType: this.pensumDisplayType,
            faktorVerstecken: this.faktorVerstecken,
            belegungZeitenVerstecken: this.belegungZeitenVerstecken,
            kinderOrtNameVerstecken: this.kinderOrtNameVerstecken,
            betreuungBeiFamilie: this.betreuungBeiFamilie,
        };
    }
}
