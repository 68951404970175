/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    CustomFieldType,
    ICustomField,
    IPersistable,
    IRestModel,
    ISortable,
    ParamValueDType,
} from '@dv/shared/code';
import {checkPresent, CustomFieldUtil, isPresent} from '@dv/shared/code';
import {CheckInCustomField} from '../../customfield/CheckInCustomField';

export class AnwesenheitCustomField implements IRestModel, IPersistable, ICustomField, ISortable {

    private static readonly SELECT_VALUE_SEPARATOR: string = ',';

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public selectValues: string | null = null,
        public fieldType: CustomFieldType | null = null,
        public paramValueType: ParamValueDType | null = fieldType ?
            CustomFieldUtil.customFieldTypeToParamValueType(fieldType) :
            null,
        private splitSelectValues: string[] | null = null,
        public tagesbasiert: boolean = false,
        public orderValue: number | null = null,
        public checkInCustomField: CheckInCustomField | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): AnwesenheitCustomField {
        const result = new AnwesenheitCustomField(
            data.id,
            data.name,
            data.selectValues,
            data.fieldType,
            null,
            null,
            data.tagesbasiert,
            data.orderValue,
        );

        if (isPresent(data.checkInCustomField)) {
            result.checkInCustomField = CheckInCustomField.apiResponseTransformer(data.checkInCustomField);
        }

        return result;
    }

    public getSplitSelectValues(): string[] {
        if (!this.splitSelectValues) {
            this.splitSelectValues =
                CustomFieldUtil.splitSelectValues(this.selectValues, AnwesenheitCustomField.SELECT_VALUE_SEPARATOR);
        }

        return this.splitSelectValues;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            fieldType: this.fieldType,
            selectValues: this.selectValues,
            tagesbasiert: this.tagesbasiert,
            orderValue: this.orderValue,
        };
    }

    public getParamValueType(): ParamValueDType {
        return CustomFieldUtil.customFieldTypeToParamValueType(checkPresent(this.fieldType));
    }

    public getFieldName(): string | null {
        return this.name;
    }
}
