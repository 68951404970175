/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AppConfigKey} from '@dv/shared/backend/model/app-config-key';
import type {IRestModel} from '@dv/shared/code';
import {Country, DvbUtil} from '@dv/shared/code';
import {Language} from '../locale/Language';
import {AppConfigEntry} from './AppConfigEntry';

export class AppConfig implements IRestModel {

    public constructor(
        public entries: AppConfigEntry[] = [],
        public languages: Language[] = [],
        public countries: Country[] = [],
        public oidcIdentityProvider?: string,
    ) {
    }

    public static apiResponseTransformer(data: any): AppConfig {
        const answer = new AppConfig();

        if (Array.isArray(data.entries)) {
            answer.entries = data.entries.map((entry: any) => AppConfigEntry.apiResponseTransformer(entry));
        }

        if (Array.isArray(data.languages)) {
            answer.languages = data.languages.map((language: any) => Language.apiResponseTransformer(language));
        }

        if (Array.isArray(data.countries)) {
            answer.countries = data.countries.map((country: any) => Country.apiResponseTransformer(country));
        }

        answer.oidcIdentityProvider = data.oidcIdentityProvider;

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            entries: this.entries.map(entry => entry.toRestObject()),
        };
    }

    public get(key: AppConfigKey): AppConfigEntry {
        const found = DvbUtil.findFirst(this.entries, entry => !!entry && entry.key === key);

        if (found) {
            return found;
        }

        return new AppConfigEntry(key);
    }
}
