/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {ExtraPlatzCategory} from './ExtraPlatzCategory';
import type {ExtraPlatzType} from './ExtraPlatzType';
import {Platz} from './Platz';

export class ExtraPlatz extends Platz {

    public kinderOrtFraktionId: string | null = null;
    public kindId: string | null = null;
    public affectedDay: moment.Moment | null = null;
    public kinderOrtName: string | null = null;
    public fraktionName: string | null = null;
    public extraPlatzCategory: ExtraPlatzCategory | null = null;
    public extraPlatzType: ExtraPlatzType | null = null;

    public static fromPlatz(platz: Platz): ExtraPlatz {
        return Object.assign(new ExtraPlatz(), platz);
    }

    public static override apiResponseTransformer(data: any): ExtraPlatz {
        const result = this.fromPlatz(super.apiResponseTransformer(data));

        result.kinderOrtFraktionId = data.kinderOrtFraktionId;
        result.kindId = data.kindId;
        result.affectedDay = DvbRestUtil.localDateToMoment(data.affectedDay);
        result.kinderOrtName = data.kinderOrtName;
        result.fraktionName = data.fraktionName;
        result.extraPlatzCategory = data.extraPlatzCategory;
        result.extraPlatzType = data.extraPlatzType;

        return result;
    }

    public override toRestObject(): Record<string, unknown> {
        const result = super.toRestObject();

        result.kinderOrtFraktionId = this.kinderOrtFraktionId;
        result.kindId = this.kindId;
        result.affectedDay = DvbRestUtil.momentToLocalDate(this.affectedDay);
        result.extraPlatzCategory = this.extraPlatzCategory;
        result.extraPlatzType = this.extraPlatzType;

        return result;
    }
}
