/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

const COMPLETE = 100;

export class UploadTempBlob {

    public constructor(
        public id: string | null = null,
        public filename: string | null = '',
        public progress: number = 0,
        public errorMessage: string | null = null,
        public file: File | null = null,
        public href: string | null = null,
        public mimeType: string | null = null,
        public size: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): UploadTempBlob {
        // TODO Es sollte eine Superklasse geben, mit ausprägungen Upload & Persisted/Saved.
        // Die Properties unterscheiden sich je nach upload / download context!
        const answer = new UploadTempBlob();
        answer.id = data.id;
        answer.filename = data.filename;
        answer.href = data.href;
        answer.mimeType = data.mimeType;
        answer.progress = COMPLETE;

        return answer;
    }

    public static getTempBlobIds(tempBlobs: UploadTempBlob[]): (string | null)[] {
        return tempBlobs.filter(tempBlob => tempBlob.href === null).map(a => a.id);
    }

    public isInProgress(): boolean {
        return this.progress < 0 || (this.progress >= 0 && this.progress < COMPLETE);
    }
}
