/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungsStatusType} from './RechnungsStatus';

export class RechnungsStatusResponse {

    public constructor(
        public statuses: { [rechnungId: string]: RechnungsStatusType },
    ) {
    }

    public static apiResponseTransformer(data: any): RechnungsStatusResponse {
        return new RechnungsStatusResponse(data.statuses);
    }
}
