/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {BetreuungsZeitraumBelegung} from './BetreuungsZeitraumBelegung';
import {WochenPlaetze} from './WochenPlaetze';

export class GruppenWochenBelegung implements ILimited {

    public constructor(
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public gruppeId: string | null = null,
        public betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung[] = [],
        public plaetze: WochenPlaetze = new WochenPlaetze()) {
    }

    /**
     * @Deprecated
     */
    public get firstOfWeek(): moment.Moment | null {
        return this.gueltigAb;
    }

    /**
     * @Deprecated
     */
    public get lastOfWeek(): moment.Moment | null {
        return this.gueltigBis;
    }

    public static apiResponseTransformer(data: any): GruppenWochenBelegung {
        const answer = new GruppenWochenBelegung(
            DvbRestUtil.localDateToMoment(data.firstOfWeek),
            DvbRestUtil.localDateToMoment(data.lastOfWeek),
            data.gruppeId,
        );

        if (Array.isArray(data.betreuungsZeitraumBelegung)) {
            answer.betreuungsZeitraumBelegung = data.betreuungsZeitraumBelegung
                .map((bzb: any) => BetreuungsZeitraumBelegung.apiResponseTransformer(bzb));
        }

        if (data.wochenPlaetze) {
            answer.plaetze = WochenPlaetze.apiResponseTransformer(data.wochenPlaetze);
        }

        return answer;
    }
}
