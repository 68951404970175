/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Geschlecht} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {AnmeldungKind} from '../kind/AnmeldungKind';
import {AbstractKibonExchangeKind} from './AbstractKibonExchangeKind';

export class TagesschuleAnmeldungKind extends AbstractKibonExchangeKind {

    public constructor(
        id: string | null,
        vorName: string,
        familienName: string,
        geburtsdatum: moment.Moment | null,
        public geschlecht: Geschlecht,
    ) {
        super(id, vorName, familienName, geburtsdatum);
    }

    public static apiResponseTransformer(data: any): TagesschuleAnmeldungKind {
        return new TagesschuleAnmeldungKind(
            data.id,
            data.vorName,
            data.familienName,
            DvbRestUtil.localDateTimeToMoment(data.geburtsdatum),
            data.geschlecht,
        );
    }

    public toAnmeldungKind(): AnmeldungKind {
        return new AnmeldungKind(this.vorName, this.familienName, this.geburtsdatum, this.geschlecht);
    }
}
