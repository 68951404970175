/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable, IPersistable} from '@dv/shared/code';
import type moment from 'moment';

export abstract class AbstractKibonExchangeKind implements IDisplayable, IPersistable {

    protected constructor(
        public id: string | null,
        public vorName: string,
        public familienName: string,
        public geburtsdatum: moment.Moment | null,
    ) {

    }

    public getDisplayName(): string {
        return `${this.familienName} ${this.vorName}`;
    }
}
