/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export enum LeistungsTyp {
    PRIVAT = 'PRIVAT',
    SUBVENTIONIERTES_KONTINGENT = 'SUBVENTIONIERTES_KONTINGENT',
    FIRMEN_KONTINGENT = 'FIRMEN_KONTINGENT',
    BETREUUNGS_GUTSCHEIN = 'BETREUUNGS_GUTSCHEIN',
    BETREUUNGS_UNABHAENGIG = 'BETREUUNGS_UNABHAENGIG',
}
