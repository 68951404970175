/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {BankStatementEntryStatus} from '@dv/shared/backend/model/bank-statement-entry-status';
import type {IRestModel} from '@dv/shared/code';
import {Gueltigkeit} from '@dv/shared/code';

export class BankStatementEntryFilter implements IRestModel {

    public constructor(
        public gueltigkeit: Gueltigkeit = new Gueltigkeit(),
        public bankStatementIds: string[] = [],
        public states: { [index in BankStatementEntryStatus]?: boolean } = {},
    ) {
    }

    public getStateArray(): BankStatementEntryStatus[] {
        const stateArray = Object.keys(BankStatementEntryStatus)
            .filter(key => this.states[key as BankStatementEntryStatus])
            .map(key => key as BankStatementEntryStatus);

        if (stateArray.length === 0) {
            // none selected means the same as everything is selected
            return [
                BankStatementEntryStatus.NICHT_ZUGEWIESEN,
                BankStatementEntryStatus.ZUGEWIESEN,
                BankStatementEntryStatus.IGNORIERT,
            ];
        }

        return stateArray;
    }

    public toRestObject(): any {
        const result: any = this.gueltigkeit.toRestObject();
        result.states = this.getStateArray();
        if (this.bankStatementIds.length > 0) {
            result.bankStatementIds = this.bankStatementIds;
        }

        return result;
    }
}
