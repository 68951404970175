/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Country, IsoObject} from '@dv/shared/code';
import {DvbUtil, hasOwnPropertyGuarded} from '@dv/shared/code';
import type angular from 'angular';
import type {AppConfig} from './benutzer/AppConfig';
import type {Language} from './locale/Language';
import {ServiceContainer} from './ServiceContainer';

export type IsoTypes = 'COUNTRY' | 'LANGUAGE';

export class IsoLanguagesAndCountriesService {

    private static isoObjectFetcher: {
        [index in IsoTypes]: () => angular.IPromise<IsoObject[]>;
    } = {
        COUNTRY: IsoLanguagesAndCountriesService.getCountries,
        LANGUAGE: IsoLanguagesAndCountriesService.getLanguages,
    };

    public static getIsoObjectsByType(type: IsoTypes): angular.IPromise<IsoObject[]> {
        if (!hasOwnPropertyGuarded(this.isoObjectFetcher, type)) {
            return ServiceContainer.$q.reject(new Error(`invalid type given: ${type}`));
        }

        return this.isoObjectFetcher[type]();
    }

    public static getLanguages(): angular.IPromise<Language[]> {
        return ServiceContainer.appConfigService.getConfig().then((config: AppConfig) => config.languages);
    }

    /**
     * @param languageCode ISO 639 two-letter language code
     */
    public static getLanguage(languageCode: string): angular.IPromise<Language | null> {
        return this.getLanguages()
            .then((languages: Language[]) => this.filterByIsoCode(languages, languageCode));
    }

    public static getCountries(): angular.IPromise<Country[]> {
        return ServiceContainer.appConfigService.getConfig().then((config: AppConfig) => config.countries);
    }

    /**
     * @param countryCode ISO 3166 two-letter country code
     */
    public static getCountry(countryCode: string): angular.IPromise<Country | null> {
        return this.getCountries()
            .then((countries: Country[]) => this.filterByIsoCode(countries, countryCode));
    }

    private static filterByIsoCode<T extends IsoObject>(isoObjects: T[], isoCode: string): T | null {
        return DvbUtil.findFirst(isoObjects, obj => !!obj && obj.isoCode === isoCode);
    }
}
