/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BelegungsZustand, ILimited} from '@dv/shared/code';
import {KindergartenBelegung} from '@dv/shared/code';
import type moment from 'moment';
import type {Betreuungsfaktor} from '../betreuungsfaktor/Betreuungsfaktor';
import type {Belegung} from './Belegung';
import type {ExtraPlatzWochenBelegung} from './ExtraPlatzWochenBelegung';

export class BelegungInterval implements ILimited {

    public constructor(
        public gueltigAb: moment.Moment,
        public gueltigBis: moment.Moment,
        public belegungsZustand: BelegungsZustand | null = null,
        public monatsBelegungId: string | null = null,
        public belegungen: Belegung[] = [],
        public betreuungsfaktor: Betreuungsfaktor | null = null,
        public kindergartenBelegung: KindergartenBelegung = KindergartenBelegung.KEINE,
        public bemerkung: string = '',
        public targetFraktionIds: Set<string> = new Set(),
        public extraPlatzWochenBelegungen: ExtraPlatzWochenBelegung[] = [],
        public isAustritt: boolean = false,
    ) {
    }

    public get isMonatsbelegung(): boolean {
        return !!this.monatsBelegungId;
    }
}
