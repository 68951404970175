/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AddressMode} from '@dv/shared/backend/model/address-mode';
import type {Geschlecht, IDisplayable, IPersistable} from '@dv/shared/code';
import {Adresse, checkPresent, DvbUtil, presentCustomFieldValues} from '@dv/shared/code';
import {LimitedAdresse} from '../adressen/LimitedAdresse';
import {CustomFieldValueNotNamed} from '../customfield/CustomFieldValueNotNamed';
import {IsoLanguagesAndCountriesService} from '../isoLanguagesAndCountriesService';
import {AbstractKontaktperson} from '../kontakte/AbstractKontaktperson';
import {AnmeldungKindKontaktperson} from '../kontakte/AnmeldungKindKontaktperson';
import type {Kontaktperson} from '../kontakte/Kontaktperson';
import type {Language} from '../locale/Language';
import {ServiceContainer} from '../ServiceContainer';
import {ExternalAnmeldungCustomField} from './ExternalAnmeldungCustomField';

export class ExternalKontaktperson extends AbstractKontaktperson implements IPersistable, IDisplayable {

    public addressMode: AddressMode = AddressMode.HAUPTKONTAKT;

    public constructor(
        id: string | null = null,
        familienName: string = '',
        vorName: string = '',
        organisation: string = '',
        isOrganisation: boolean = false,
        public geschlecht: Geschlecht | null = null,
        public telefon: string | null = null,
        public mobile: string | null = null,
        public geschaeft: string | null = null,
        public email: string | null = null,
        public korrespondenzsprache: Language | null = null,
        public adresse: Adresse | null = null,
        public hauptkontakt: boolean = false,
        public erziehungsberechtigt: boolean = false,
        public abholberechtigt: boolean = false,
        public customFieldValues: CustomFieldValueNotNamed[] = [],
    ) {
        super(id, vorName, familienName, organisation, isOrganisation);
    }

    public static apiResponseTransformer(data: any): ExternalKontaktperson {
        const result = new ExternalKontaktperson();
        result.id = data.id;
        result.familienName = data.familienName;
        result.vorName = data.vorName;
        result.organisation = data.organisation;
        result.isOrganisation = data.isOrganisation;
        result.geschlecht = data.geschlecht;
        result.telefon = data.telefon;
        result.mobile = data.mobile;
        result.geschaeft = data.geschaeft;
        result.email = data.email;
        result.hauptkontakt = data.hauptkontakt;
        result.erziehungsberechtigt = data.erziehungsberechtigt;
        result.abholberechtigt = data.abholberechtigt;

        if (data.korrespondenzsprache) {
            IsoLanguagesAndCountriesService.getLanguage(data.korrespondenzsprache).then((sprache: Language | null) => {
                result.korrespondenzsprache = sprache;
            });
        }

        result.addressMode = data.addressMode;
        if (data.adresse) {
            result.adresse = Adresse.apiResponseTransformer(data.adresse);
        }

        result.customFieldValues =
            CustomFieldValueNotNamed.apiResponseTransformer(data.customFieldValues, ExternalAnmeldungCustomField);

        return result;
    }

    public static initializeKontaktperson(
        kontaktperson: Kontaktperson,
        externalKontakt: ExternalKontaktperson,
    ): Kontaktperson {
        kontaktperson.familienName = externalKontakt.familienName;
        kontaktperson.vorName = externalKontakt.vorName;
        kontaktperson.organisation = externalKontakt.organisation;
        kontaktperson.isFirma = externalKontakt.isOrganisation;
        kontaktperson.geschlecht = externalKontakt.geschlecht;
        kontaktperson.telefon = externalKontakt.telefon;
        kontaktperson.mobile = externalKontakt.mobile;
        kontaktperson.geschaeft = externalKontakt.geschaeft;
        kontaktperson.email = externalKontakt.email;
        kontaktperson.korrespondenzsprache = externalKontakt.korrespondenzsprache;

        kontaktperson.addressMode = externalKontakt.addressMode;
        if (externalKontakt.adresse) {
            const limitedAdresse = new LimitedAdresse(null, null, null, externalKontakt.adresse.clone());
            kontaktperson.adressen.push(limitedAdresse);
        }

        return kontaktperson;
    }

    public initCustomFieldValues(customFields: ExternalAnmeldungCustomField[]): void {
        this.customFieldValues = customFields.map(field => new CustomFieldValueNotNamed(null, field));
    }

    public toAnmeldungKontaktperson(): AnmeldungKindKontaktperson {
        return new AnmeldungKindKontaktperson(
            this.vorName,
            this.familienName,
            null,
            checkPresent(this.geschlecht),
            this.email,
            this.telefon,
            this.mobile,
            this.geschaeft,
            this.organisation,
            this.hauptkontakt,
            this.adresse,
            this.customFieldValues);
    }

    public getDisplayName(): string {
        const name = [this.organisation, [this.familienName || '', this.vorName || ''].join(' ')]
            .filter(s => DvbUtil.isNotEmptyString(s.trim()))
            .join(', ');

        return DvbUtil.isEmptyString(name) ? ServiceContainer.$translate.instant('COMMON.KONTAKT') : name;
    }

    public isAdresseRequired(): boolean {
        return this.hauptkontakt || this.erziehungsberechtigt;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            familienName: this.familienName,
            vorName: this.vorName,
            organisation: this.organisation,
            isOrganisation: this.isOrganisation,
            geschlecht: this.geschlecht,
            telefon: this.telefon,
            mobile: this.mobile,
            geschaeft: this.geschaeft,
            email: this.email,
            korrespondenzsprache: this.korrespondenzsprache ? this.korrespondenzsprache.toRestObject() : null,
            adresse: this.addressMode === AddressMode.OWN && this.adresse ? this.adresse.toRestObject() : null,
            addressMode: this.addressMode,
            hauptkontakt: this.hauptkontakt,
            erziehungsberechtigt: this.erziehungsberechtigt,
            abholberechtigt: this.abholberechtigt,
            customFieldValues: presentCustomFieldValues(this.customFieldValues).map(val => val.toRestObject()),
        };
    }
}
