/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {PageContainer, RestPaginated} from '@dv/shared/code';
import type angular from 'angular';

export abstract class AbstractPagesController<T> implements angular.IOnInit {
    public isLoading: boolean = true;
    public itemsPerPage: number = 10;
    public currentPage: number = 1;
    public pageContainer: PageContainer<T> | null = null;

    public $onInit(): void {
        this.reloadItems();
    }

    public updatePage(page: number): void {
        this.currentPage = page;
        this.reloadItems();
    }

    public onUpdateItemsPerPage(itemsPerPage: number): void {
        this.itemsPerPage = itemsPerPage;
        this.reloadItems();
    }

    public setPagionationValues(object: RestPaginated): void {
        object.top = this.itemsPerPage;
        object.skip = (this.currentPage - 1) * this.itemsPerPage;
    }

    public abstract reloadItems(): void;
}
