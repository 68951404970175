/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitadminModul} from '@dv/shared/backend/model/kitadmin-modul';
import type {IRestModel} from '@dv/shared/code';

export class ModulPreise implements IRestModel {

    public constructor(
        public modul: KitadminModul,
        public preisKita: number | null = null,
        public preisBetreuungsPerson: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): ModulPreise {
        return new ModulPreise(data.modul, data.preisKita, data.preisBetreuungsPerson);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            modul: this.modul,
            preisKita: this.preisKita,
            preisBetreuungsPerson: this.preisBetreuungsPerson,
        };
    }
}
