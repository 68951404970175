/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel, IValidable} from '@dv/shared/code';
import {DOKUMENT_LAYOUT_MAX_MM, DvbUtil} from '@dv/shared/code';

export class DokumentLayoutPageMargin implements IRestModel, IValidable {

    public constructor(
            public top: number | null = null,
            public bottom: number | null = null,
            public left: number | null = null,
            public right: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): DokumentLayoutPageMargin {
        return new DokumentLayoutPageMargin(
                data.top,
                data.bottom,
                data.left,
                data.right);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            top: this.top,
            bottom: this.bottom,
            left: this.left,
            right: this.right,
        };
    }

    public isValid(): boolean {
        return this.top !== null && DvbUtil.isInRange(this.top, 0, DOKUMENT_LAYOUT_MAX_MM) &&
                this.bottom !== null && DvbUtil.isInRange(this.bottom, 0, DOKUMENT_LAYOUT_MAX_MM) &&
                this.left !== null && DvbUtil.isInRange(this.left, 0, DOKUMENT_LAYOUT_MAX_MM) &&
                this.right !== null && DvbUtil.isInRange(this.right, 0, DOKUMENT_LAYOUT_MAX_MM);
    }
}
