/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable} from '@dv/shared/code';
import {Geschlecht} from '@dv/shared/code';
import {IsoLanguagesAndCountriesService} from '../isoLanguagesAndCountriesService';
import type {Language} from '../locale/Language';

export class MissingGeschlechtOrSprache implements IDisplayable {

    public constructor(
        public kontaktpersonId: string | null = null,
        public displayName: string = '',
        public geschlecht: Geschlecht = Geschlecht.UNBEKANNT,
        public sprache: Language | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): MissingGeschlechtOrSprache {
        const answer = new MissingGeschlechtOrSprache();
        answer.kontaktpersonId = data.kontaktpersonId;
        answer.displayName = data.displayName;
        answer.geschlecht = data.geschlecht;

        if (data.sprache) {
            IsoLanguagesAndCountriesService.getLanguage(data.sprache).then(sprache => {
                answer.sprache = sprache;
            });
        }

        return answer;
    }

    public getDisplayName(): string {
        return this.displayName;
    }
}
