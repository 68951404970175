/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek, IEquals, IZeitraum, TagUndZeitraum, ZeitraumFeldIcon} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import type {Kontingente} from '../kinderort/kontingente/KontingentTransformer';
import {KontingentTransformer} from '../kinderort/kontingente/KontingentTransformer';
import type {ColorMode} from './ColorMode';
import {ZeitraumFeldSelectionType} from './ZeitraumFeldSelectionType';

export type CustomIcon = {
    value: string | null;
    tooltip: string | null;
    colorMode: ColorMode;
};

export class ZeitraumFeld<T extends IZeitraum = IZeitraum> implements IEquals<ZeitraumFeld<T>>, TagUndZeitraum {
    protected _zeitraum: T;
    /**
     * value displayed in the fields little box ('P', 'S', 'F' etc.)
     */
    protected _value: string | null;
    /**
     * Info regarding the amount of free and occupied spots. See KinderListeStrategy.getZeitraumFeldTitle
     */
    protected _title: string;

    /**
     * Concatenation of zeitraum.name, title, kontingent. See ZeitraumFeld.initTooltip()
     */
    protected _tooltip: string | null;

    private _kontingent: Kontingente | null;

    private _selected: boolean;

    private _selectionType: ZeitraumFeldSelectionType = ZeitraumFeldSelectionType.DEFAULT;

    public constructor(
        zeitraum: T,
        public dayOfWeek: DayOfWeek,
        value: string = '',
        title: string = '',
        selected: boolean = false,
        public active: boolean = false,
        public hasError: boolean = false,
        public icon: ZeitraumFeldIcon | null = null,
        kontingent: Kontingente | null = null,
        public hatFreiePlaetze: boolean = false,
        public maximumUeberschritten: boolean = false,
        tooltip: string | null = null,
        public backup?: ZeitraumFeld<T>,
        // region Dienst specific
        public customIcon: CustomIcon | null = null,
        public tooltipAddition: string | null = null,
        public cssClass: string | null = null,
        // endregion
    ) {
        this._zeitraum = zeitraum;
        this._value = value;
        this._title = title;
        this._selected = selected;
        this._kontingent = kontingent;
        this._tooltip = tooltip;
    }

    public equals(other: ZeitraumFeld<T> | null): boolean {
        if (!other) {
            return false;
        }

        const equalDayOfWeek = this.dayOfWeek === other.dayOfWeek;
        // eslint-disable-next-line no-underscore-dangle
        const equalZeitraum = this._zeitraum && other._zeitraum && this._zeitraum.id === other._zeitraum.id;

        return equalDayOfWeek && equalZeitraum;
    }

    public kontingentEquals(other: Kontingente | null): boolean {
        if (other === null && this._kontingent === null) {
            return true;
        }

        if (other !== null && this._kontingent !== null) {
            return other.equals(this._kontingent);
        }

        return false;
    }

    public get tooltip(): string | null {
        if (this._tooltip === null) {
            this.initTooltip();
        }

        return this._tooltip;
    }

    public get selectionType(): ZeitraumFeldSelectionType {
        return this._selectionType;
    }

    public set selectionType(value: ZeitraumFeldSelectionType) {
        this._selectionType = value;
    }

    //
    // Getters and setters for attrbutes that affect the tooltip.
    // Whenever these fields are modified, the tooltip has to be initialized again.
    //
    public get zeitraum(): T {
        return this._zeitraum;
    }

    public set zeitraum(value: T) {
        this._zeitraum = value;
        this.resetTooltip();
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
        this.resetTooltip();
    }

    public get value(): string | null {
        return this._value;
    }

    public set value(value: string | null) {
        this._value = value;
        this.resetTooltip();
    }

    public get selected(): boolean {
        return this._selected;
    }

    public set selected(value: boolean) {
        this._selected = value;
        this.resetTooltip();
    }

    public get kontingent(): Kontingente | null {
        return this._kontingent;
    }

    public set kontingent(value: Kontingente | null) {
        this._kontingent = value;
        this.resetTooltip();
    }

    protected initTooltip(): void {
        const result: string[] = [];

        if (DvbUtil.isNotEmptyString(this._value)) {
            result.push(`${this._zeitraum.getDisplayName()}:`);

            if (this._selected) {
                result.push(KontingentTransformer.getKontingentName(this._kontingent));
            }
        }

        result.push(this._title);
        if (this.tooltipAddition) {
            result.push(this.tooltipAddition);
        }

        this._tooltip = result.filter(value => DvbUtil.isNotEmptyString(value))
            .join('\n');
    }

    private resetTooltip(): void {
        // Forces the tooltip getter to call initTooltip()
        this._tooltip = null;
    }
}
