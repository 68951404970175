/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import type {KinderOrtFraktion} from '../kinderort/KinderOrtFraktion';
import {KinderOrtFraktionTransformer} from '../kinderort/KinderOrtTransformer';
import {BelegungZeiten} from './BelegungZeiten';
import type {BelegungZeitenByDayOfWeek} from './BelegungZeitenByDayOfWeek';
import {Platz} from './Platz';
import {VertraglichesPensum} from './VertraglichesPensum';

export class GruppenBelegung implements IRestModel, IPersistable {

    public constructor(
        public id: string | null = null,
        public plaetze: Platz[] = [],
        public gruppe: KinderOrtFraktion | null = null,
        public gruppeId: string | null = null,
        public pensum: string | null = null,
        public vertraglichePensen: VertraglichesPensum [] = [],
        public bemerkung: string = '',
        public belegungZeiten: BelegungZeitenByDayOfWeek = {},
    ) {
    }

    public static apiResponseTransformer(data: any): GruppenBelegung {

        const belegungZeiten: BelegungZeitenByDayOfWeek = {};
        if (Array.isArray(data.belegungZeiten)) {
            data.belegungZeiten.forEach((zeiten: any) => {
                const anwesenheit = BelegungZeiten.apiResponseTransformer(zeiten);
                if (!belegungZeiten[anwesenheit.dayOfWeek]) {
                    belegungZeiten[anwesenheit.dayOfWeek] = [];
                }
                belegungZeiten[anwesenheit.dayOfWeek].push(anwesenheit);
            });
        }

        return new GruppenBelegung(
            data.id,
            Array.isArray(data.plaetze) ? data.plaetze.map((p: any) => Platz.apiResponseTransformer(p)) : [],
            data.gruppe ? KinderOrtFraktionTransformer.create().apiResponseTransformer(data.gruppe) : null,
            data.gruppe ? data.gruppe.id : data.gruppeId,
            data.pensum,
            Array.isArray(data.vertraglichePensen) ?
                data.vertraglichePensen.map((vs: any) => VertraglichesPensum.apiResponseTransformer(vs)) :
                [],
            data.bemerkung,
            belegungZeiten,
        );
    }

    public toRestObject(): Record<string, unknown> {
        const restObject: any = {};
        restObject.id = this.id;
        restObject.plaetze = this.plaetze.map(p => p.toRestObject());
        if (this.gruppeId) {
            restObject.gruppeId = this.gruppeId;
        } else if (this.gruppe) {
            restObject.gruppeId = this.gruppe.id;
        }
        restObject.pensum = this.pensum;
        restObject.vertraglichePensen = this.vertraglichePensen.map(vs => vs.toRestObject());
        restObject.bemerkung = this.bemerkung;
        restObject.belegungZeiten = Object.values(this.belegungZeiten)
            .flat()
            .filter(anwesenheit => anwesenheit.hasValues())
            .map(anwesenheit => anwesenheit.toRestObject());

        return restObject;
    }
}
