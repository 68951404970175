/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbRestUtil} from '@dv/shared/code';
import {TarifParameterHistoryEntry} from './TarifParameterHistoryEntry';

export class TarifParameterHistoryEntries {

    public constructor(
        public entries: TarifParameterHistoryEntry[] = [],
        public relevantParameterIds: string[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): TarifParameterHistoryEntries {
        const result = new TarifParameterHistoryEntries();

        result.entries = DvbRestUtil.transformArray(data.tarifParameterHistoryEntries, TarifParameterHistoryEntry);

        result.relevantParameterIds = data.relevantParameterIds;

        return result;
    }
}
