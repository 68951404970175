/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {KontaktpersonOffenePosten} from './KontaktpersonOffenePosten';

export class KitaOffenePosten {

    public constructor(
        public saldo: number = 0,
        public kontaktpersonOffenePosten: KontaktpersonOffenePosten[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): KitaOffenePosten {
        const answer = new KitaOffenePosten();
        answer.saldo = data.saldo;

        if (angular.isArray(data.kontaktpersonOffenePosten)) {
            answer.kontaktpersonOffenePosten = data.kontaktpersonOffenePosten.map(
                (kontaktpersonPosten: any) => KontaktpersonOffenePosten.apiResponseTransformer(kontaktpersonPosten));
        }

        return answer;
    }
}
