/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    CustomFieldType,
    ICustomField,
    IPersistable,
    IRestModel,
    ISortable,
    NamedEntityType,
    ParamValueDType,
} from '@dv/shared/code';
import {checkPresent, CustomFieldUtil} from '@dv/shared/code';

export class CustomField implements IPersistable, IRestModel, ICustomField, ISortable {

    public constructor(
        public id: string | null = null,
        public entityType: NamedEntityType | null = null,
        public name: string | null = null,
        public fieldType: CustomFieldType | null = null,
        public mandatory: boolean = false,
        public orderValue: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): CustomField {
        return new CustomField(data.id, data.entityType, data.name, data.dataType, data.mandatory, data.orderValue);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            entityType: this.entityType,
            dataType: this.fieldType,
            mandatory: this.mandatory,
            orderValue: this.orderValue,
        };
    }

    public getParamValueType(): ParamValueDType {
        return CustomFieldUtil.customFieldTypeToParamValueType(checkPresent(this.fieldType));
    }

    public getFieldName(): string {
        return `${this.mandatory ? '*' : ''}${this.name}`;
    }
}
