/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {BankStatementEntryStatus} from '@dv/shared/backend/model/bank-statement-entry-status';
import type {BankStatementType} from '@dv/shared/backend/model/bank-statement-type';
import type {IPersistable, IRestModel} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {AdresseWithoutGemeinde} from '../../adressen/AdresseWithoutGemeinde';
import {Rechnung} from '../Rechnung';

export class BankStatementEntry implements IPersistable, IRestModel {
    public constructor(
        public id: string | null = null,
        public type: BankStatementType | null = null,
        public status: BankStatementEntryStatus | null = null,
        public localDateTime: moment.Moment | null = null,
        public einleseDatum: moment.Moment | null = null,
        public referenzNummer: string | null = null,
        public betrag: number | null = null,
        public rawImportData: string | null = null,
        public automaticAssignment: boolean | null = null,
        public benutzerDisplayName: string | null = null,
        public username: string | null = null,
        public rechnung: Rechnung | null = null,
        public bankStatementId: string | null = null,
        public accountIdentificationIban: string | null = null,
        public identification: string | null = null,
        public name: string | null = null,
        public adresse: AdresseWithoutGemeinde | null = null,
        public iban: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): BankStatementEntry {
        const answer = new BankStatementEntry();
        answer.id = data.id;
        answer.type = data.type;
        answer.status = data.status ?? BankStatementEntryStatus.NICHT_ZUGEWIESEN;
        answer.localDateTime = DvbRestUtil.localDateTimeToMoment(data.localDateTime);
        answer.einleseDatum = DvbRestUtil.localDateTimeToMoment(data.einleseDatum);
        answer.referenzNummer = data.referenzNummer;
        answer.rawImportData = data.rawImportData;
        answer.betrag = parseFloat(data.betrag);
        answer.automaticAssignment = data.automaticAssignment;
        answer.benutzerDisplayName = data.benutzerDisplayName;
        answer.username = data.username;
        answer.bankStatementId = data.bankStatementId;
        answer.accountIdentificationIban = data.accountIdentificationIban;
        answer.identification = data.identification;
        answer.name = data.name;
        answer.iban = data.iban;

        if (data.rechnung) {
            answer.rechnung = Rechnung.apiResponseTransformer(data.rechnung);
        }

        if (data.adresse) {
            answer.adresse = AdresseWithoutGemeinde.apiResponseTransformer(data.adresse);
        }

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        const result: Record<string, unknown> = {
            type: this.type,
            localDateTime: this.localDateTime && DvbRestUtil.momentToLocalDateTime(this.localDateTime),
            referenzNummer: this.referenzNummer,
            rawImportData: this.rawImportData,
            betrag: this.betrag,
            bankStatementId: this.bankStatementId,
            accountIdentificationIban: this.accountIdentificationIban,
            identification: this.identification,
            name: this.name,
            iban: this.iban,
        };

        if (this.adresse) {
            result.adresse = this.adresse.toRestObject();
        }

        return result;
    }
}
