/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek, IRestModel} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import moment from 'moment';

export class BelegungZeiten implements IRestModel {

    public constructor(
        public dayOfWeek: DayOfWeek,
        public von: moment.Moment | null = null,
        public bis: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): BelegungZeiten {
        return new BelegungZeiten(
            data.dayOfWeek,
            DvbRestUtil.localeHHMMTimeToMoment(data.von),
            DvbRestUtil.localeHHMMTimeToMoment(data.bis));
    }

    public hasValues(): boolean {
        return moment.isMoment(this.von) || moment.isMoment(this.bis);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            dayOfWeek: this.dayOfWeek,
            von: DvbRestUtil.momentTolocaleHHMMTime(this.von),
            bis: DvbRestUtil.momentTolocaleHHMMTime(this.bis),
        };
    }
}
