/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export class RechnungDisplayMode {

    public static modes = {
        REVISION: new RechnungDisplayMode(false, true),
        KONTAKTPERSON: new RechnungDisplayMode(true, false),
        KINDERORT: new RechnungDisplayMode(true, false),
        UEBERSICHT: new RechnungDisplayMode(false, false),
        TRANSAKTION: new RechnungDisplayMode(false, false),
    };

    public constructor(
        public refreshStatus: boolean,
        public allowMultiple: boolean,
    ) {
    }
}
