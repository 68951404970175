/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {AbstractKibonExchangeKind} from './AbstractKibonExchangeKind';

export class KibonExchangeKind extends AbstractKibonExchangeKind {

    public constructor(
        id: string | null,
        vorName: string,
        familienName: string,
        geburtsdatum: moment.Moment | null,
    ) {
        super(id, vorName, familienName, geburtsdatum);
    }

    public static apiResponseTransformer(data: any): KibonExchangeKind {
        return new KibonExchangeKind(
            data.id,
            data.vorName,
            data.familienName,
            DvbRestUtil.localDateTimeToMoment(data.geburtsdatum),
        );
    }
}
