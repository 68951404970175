/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type AddressMode = 'HAUPTKONTAKT' | 'OWN' | 'NONE';

export const AddressMode = {
    HAUPTKONTAKT: 'HAUPTKONTAKT' as AddressMode,
    OWN: 'OWN' as AddressMode,
    NONE: 'NONE' as AddressMode
};

