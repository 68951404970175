/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted} from '@dv/shared/code';
import {KitaRechnungenStats} from './KitaRechnungenStats';
import {Rechnung} from './Rechnung';

export class KitaRechnungen {

    public constructor(
        public stats: KitaRechnungenStats = new KitaRechnungenStats(),
        public rechnungen: Persisted<Rechnung>[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): KitaRechnungen {
        const answer = new KitaRechnungen();

        if (Array.isArray(data.items)) {
            answer.rechnungen = data.items.map((r: any) => Rechnung.apiResponseTransformer(r));
        }
        answer.stats = KitaRechnungenStats.apiResponseTransformer(data);

        return answer;
    }
}
