/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Gebuehr} from './Gebuehr';
import {KontaktpersonZahlungType} from './KontaktpersonZahlungType';
import {Rueckerstattung} from './Rueckerstattung';
import {Vorauszahlung} from './Vorauszahlung';

export type KontaktpersonZahlungTypeMap = {
    VORAUSZAHLUNG: Vorauszahlung;
    RUECKERSTATTUNG: Rueckerstattung;
    GEBUEHR: Gebuehr;
};

export function createKontaktpersonZahlung(dtype: KontaktpersonZahlungType): KontaktpersonZahlungTypeMap[typeof dtype] {
    switch (dtype) {
        case KontaktpersonZahlungType.VORAUSZAHLUNG:
            return new Vorauszahlung();
        case KontaktpersonZahlungType.RUECKERSTATTUNG:
            return new Rueckerstattung();
        case KontaktpersonZahlungType.GEBUEHR:
            return new Gebuehr();
        default:
            throw new Error(`Unsupported KontaktpersonZahlungType: ${String(dtype)}`);
    }
}
