/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, hasOwnPropertyGuarded, stringUnion} from '@dv/shared/code';
import moment from 'moment';

export const INVALID_BELEGUNGS_EINHEIT = 'ERRORS.ERR_INVALID_BELEGUNGSEINHEIT';
export const errUnknownWochentag = 'ERRORS.ERR_UNKNOWN_WOCHENTAG';
export const BEWERBUNGSPLAN_ERRORS = stringUnion(INVALID_BELEGUNGS_EINHEIT, errUnknownWochentag);
export type BewerbungsplanErrors = typeof BEWERBUNGSPLAN_ERRORS.type;
export type BewerbungsplanError = {
    msgKey: BewerbungsplanErrors;
    args: {
        wochentag: string;
        dayOfWeek: DayOfWeek;
    };
};

export function isBewerbungsplanError(err: unknown): err is BewerbungsplanError {
    return typeof err === 'object'
        && err !== null
        && hasOwnPropertyGuarded(err, 'msgKey')
        && BEWERBUNGSPLAN_ERRORS.guard(err.msgKey)
        && hasOwnPropertyGuarded(err, 'args');
}

export function bewerbungsplanError(msgKey: BewerbungsplanErrors, dayOfWeek: DayOfWeek): BewerbungsplanError {
    return {
        msgKey,
        args: {
            wochentag: checkPresent(DvbDateUtil.getDayOfWeekMoment(dayOfWeek, moment())).format('dddd'),
            dayOfWeek,
        },
    };
}
