/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek} from '@dv/shared/code';
import {PlaetzeByDay} from './PlaetzeByDay';

export class PlaetzeByWeek {

    public constructor(
        public totalPlaetze: number | null = null,
        public plaetze: PlaetzeByDay[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): PlaetzeByWeek {
        const answer = new PlaetzeByWeek(data.totalPlaetze);

        Object.keys(data.plaetzeByWeek).forEach(dayOfWeek => {
            answer.plaetze.push(PlaetzeByDay.apiResponseTransformer(data.plaetzeByWeek[dayOfWeek]));
        });

        return answer;
    }

    public hasError(): boolean {
        return this.plaetze.some(plaetzeByDay => plaetzeByDay.hasError());
    }

    public hasErrorAtDayOfWeek(dayOfWeek: DayOfWeek): boolean {
        return this.plaetze
            .filter(plaetzeByDay => plaetzeByDay.dayOfWeek === dayOfWeek)
            .some(plaetzeByDay => plaetzeByDay.hasError());
    }
}
