/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {PERMISSION} from '@dv/shared/authentication/model';
import {NamedEntityType, stringUnion} from '@dv/shared/code';
import {GENERAL_SUFFIX} from '@dv/shared/roles';
import type {NameWithPrincipal} from './NameWithPrincipal';

export type TaskCategory =
    'KINDERORT_LEISTUNGSBERECHNUNG_INTERNALERROR' |
    'RECHNUNGS_REVISION_DELIVERY_FAILURE' |
    'KINDERORT_TARIF_ZUWEISUNG' |
    'KINDERORT_TARIF_ZUWEISUNG_ZEITRAUM' |
    'KINDERORT_TARIF_FIRMENKONTINGENT' |
    'KIND_TARIFPARAMETER' |
    'GEBURTSTAG_KIND' |
    'MANDATORY_CUSTOM_FIELD' |
    'KIND_AUSTRITT_PROVISORISCH' |
    'KIND_BELEGUNG_PROVISORISCH' |
    'KIND_BELEGUNG_ANGEBOT' |
    'KIND_RECHNUNGSDIFFERENZ' |
    'RECHNUNG_ZAHLUNGEN' |
    'KONTAKTPERSON_ADRESSE' |
    'MONATSBLATT' |
    'COMMUNICATION_BRING_ABHOLZEITEN' |
    'KIBON_EXCHANGE_VERFUEGUNG' |
    'KIBON_EXCHANGE_MUTATION' |
    'CHECK_OUT_MISSING' |
    'KIND_ABWEICHUNGS_MELDUNG' |
    'PENDING_EXTERNAL_ANMELDUNG' ;

export const TASK_FILTER_CATEGORY = stringUnion(
    'BELEGUNG',
    'BRING_ABHOLZEITEN',
    'CUSTOM_FIELD',
    'KIBON',
    'LEISTUNGSRECHNUNG',
    'GROUP_ADMIN_KONTROLLE',
    'EXTERNAL_ANMELDUNG_KONTROLLE',
);

export type TaskFilterCatergory = typeof TASK_FILTER_CATEGORY.type;

const KINDER_ORT_PRINCIPAL = [NamedEntityType.KITA, NamedEntityType.TAGES_ELTERN];
const KINDER_ORT_FRAKTION_PRINCIPAL = [NamedEntityType.GRUPPE, NamedEntityType.BETREUUNGS_PERSON];

export const TASK_CATEGORY: { [k in TaskCategory]: Readonly<NameWithPrincipal> } = {
    KINDERORT_LEISTUNGSBERECHNUNG_INTERNALERROR: {
        name: 'KINDERORT_LEISTUNGSBERECHNUNG_INTERNALERROR',
        principal: KINDER_ORT_PRINCIPAL,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.CONTROL_ANY,
    },
    RECHNUNGS_REVISION_DELIVERY_FAILURE: {
        name: 'RECHNUNGS_REVISION_DELIVERY_FAILURE',
        principal: NamedEntityType.RECHNUNG,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    KINDERORT_TARIF_ZUWEISUNG: {
        name: 'KINDERORT_TARIF_ZUWEISUNG',
        principal: KINDER_ORT_PRINCIPAL,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.ADMINISTRATE_ANY,
    },
    KINDERORT_TARIF_ZUWEISUNG_ZEITRAUM: {
        name: 'KINDERORT_TARIF_ZUWEISUNG_ZEITRAUM',
        principal: KINDER_ORT_PRINCIPAL,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.ADMINISTRATE_ANY,
    },
    KINDERORT_TARIF_FIRMENKONTINGENT: {
        name: 'KINDERORT_TARIF_FIRMENKONTINGENT',
        principal: NamedEntityType.FIRMA, // eigentlich ist es das Kontingent, hat aber kein Profil
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.ADMINISTRATE_ANY,
    },
    KIND_TARIFPARAMETER: {
        name: 'KIND_TARIFPARAMETER',
        principal: NamedEntityType.KIND,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    GEBURTSTAG_KIND: {
        name: 'GEBURTSTAG_KIND',
        principal: NamedEntityType.KIND,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    MANDATORY_CUSTOM_FIELD: {
        name: 'MANDATORY_CUSTOM_FIELD',
        principal: Object.values(NamedEntityType),
        super: 'CUSTOM_FIELD',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    KIND_AUSTRITT_PROVISORISCH: {
        name: 'KIND_AUSTRITT_PROVISORISCH',
        principal: NamedEntityType.KIND,
        super: 'BELEGUNG',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    KIND_BELEGUNG_PROVISORISCH: {
        name: 'KIND_BELEGUNG_PROVISORISCH',
        principal: NamedEntityType.KIND,
        super: 'BELEGUNG', // Could also be LEISTUNGSRECHNUNG
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    KIND_BELEGUNG_ANGEBOT: {
        name: 'KIND_BELEGUNG_ANGEBOT',
        principal: NamedEntityType.KIND,
        super: 'BELEGUNG',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    KIND_RECHNUNGSDIFFERENZ: {
        name: 'KIND_RECHNUNGSDIFFERENZ',
        principal: NamedEntityType.KIND,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    RECHNUNG_ZAHLUNGEN: {
        name: 'RECHNUNG_ZAHLUNGEN',
        principal: NamedEntityType.RECHNUNG,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    KONTAKTPERSON_ADRESSE: {
        name: 'KONTAKTPERSON_ADRESSE',
        principal: NamedEntityType.KONTAKTPERSON,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    MONATSBLATT: {
        name: 'MONATSBLATT',
        principal: KINDER_ORT_FRAKTION_PRINCIPAL,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    COMMUNICATION_BRING_ABHOLZEITEN: {
        name: 'COMMUNICATION_BRING_ABHOLZEITEN',
        principal: NamedEntityType.KIND,
        super: 'BRING_ABHOLZEITEN',
        permission: [PERMISSION.MODULE.ANWESENHEIT, PERMISSION.KITA.MANAGE_ANY],
    },
    KIBON_EXCHANGE_VERFUEGUNG: {
        name: 'KIBON_EXCHANGE_VERFUEGUNG',
        principal: KINDER_ORT_PRINCIPAL,
        super: 'KIBON',
        permission: [PERMISSION.FEATURE.KIBON_MELDUNGEN + GENERAL_SUFFIX, PERMISSION.KITA.MANAGE_ANY],
    },
    KIBON_EXCHANGE_MUTATION: {
        name: 'KIBON_EXCHANGE_MUTATION',
        principal: KINDER_ORT_PRINCIPAL,
        super: 'KIBON',
        permission: [PERMISSION.FEATURE.KIBON_MELDUNGEN + GENERAL_SUFFIX, PERMISSION.KITA.MANAGE_ANY],
    },
    CHECK_OUT_MISSING: {
        name: 'CHECK_OUT_MISSING',
        principal: NamedEntityType.KIND,
        super: 'LEISTUNGSRECHNUNG',
        permission: PERMISSION.KITA.CONTROL_ANY,
    },
    KIND_ABWEICHUNGS_MELDUNG: {
        name: 'KIND_ABWEICHUNGS_MELDUNG',
        principal: KINDER_ORT_PRINCIPAL,
        super: 'GROUP_ADMIN_KONTROLLE',
        permission: PERMISSION.KITA.CONTROL_ANY,
    },
    PENDING_EXTERNAL_ANMELDUNG: {
        name: 'PENDING_EXTERNAL_ANMELDUNG',
        principal: KINDER_ORT_PRINCIPAL,
        super: 'EXTERNAL_ANMELDUNG_KONTROLLE',
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
};
