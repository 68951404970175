/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KitaPermissionType} from './kita-permission-type';
import {PersonalPermissionType} from './personal-permission-type';
import type {UserRole} from './user-role';

export const GENERAL_SUFFIX = ':general';

/**
 * kita specific permission availability based on a users role
 */
export const AVAILABLE_PERM_BY_ROLE
    : { [k in UserRole]: { kita: KitaPermissionType[]; personal: PersonalPermissionType[] } } = {
    SUPER_ADMIN: {kita: [], personal: []},
    MANDANT_ADMIN: {kita: [], personal: []},
    SUPPORT_ADMIN: {kita: [], personal: []},
    ADMIN: {kita: [], personal: []},
    USER: {
        kita: [
            KitaPermissionType.VIEW,
            KitaPermissionType.VIEW_PLUS,
            KitaPermissionType.CONTROL,
            KitaPermissionType.MANAGE,
            KitaPermissionType.ADMINISTRATE,
            KitaPermissionType.BETREUUNG_ADMIN,
        ],
        personal: [
            PersonalPermissionType.VIEW,
            PersonalPermissionType.MANAGE,
            PersonalPermissionType.ADMINISTRATE,
        ],
    },
};

/**
 * kita specific KitaPermissionType availability based on a users global KitaPermissionType
 */
export const AVAILABLE_PERM_BY_KITA_PERM = new Map([
    [KitaPermissionType.ADMINISTRATE, [KitaPermissionType.ADMINISTRATE]],
    [KitaPermissionType.MANAGE, [KitaPermissionType.MANAGE, KitaPermissionType.ADMINISTRATE]],
    [
        KitaPermissionType.CONTROL,
        [
            KitaPermissionType.CONTROL,
            KitaPermissionType.VIEW_PLUS,
            KitaPermissionType.MANAGE,
            KitaPermissionType.ADMINISTRATE,
            KitaPermissionType.BETREUUNG_ADMIN,
        ],
    ],
    [
        KitaPermissionType.VIEW_PLUS,
        [
            KitaPermissionType.VIEW_PLUS,
            KitaPermissionType.CONTROL,
            KitaPermissionType.MANAGE,
            KitaPermissionType.ADMINISTRATE,
            KitaPermissionType.BETREUUNG_ADMIN,
        ],
    ],
    [
        KitaPermissionType.VIEW,
        [
            KitaPermissionType.VIEW,
            KitaPermissionType.VIEW_PLUS,
            KitaPermissionType.CONTROL,
            KitaPermissionType.MANAGE,
            KitaPermissionType.ADMINISTRATE,
            KitaPermissionType.BETREUUNG_ADMIN,
        ],
    ],
    [
        null,
        [
            KitaPermissionType.VIEW,
            KitaPermissionType.VIEW_PLUS,
            KitaPermissionType.CONTROL,
            KitaPermissionType.MANAGE,
            KitaPermissionType.ADMINISTRATE,
            KitaPermissionType.BETREUUNG_ADMIN,
        ],
    ],
]);

/**
 * kita specific PersonalPermissionType availability based on a users global PersonalPermissionType
 */
export const AVAILABLE_PERM_BY_PERSONAL_BERM = new Map([
    [PersonalPermissionType.ADMINISTRATE, [PersonalPermissionType.ADMINISTRATE]],
    [PersonalPermissionType.MANAGE, [PersonalPermissionType.MANAGE, PersonalPermissionType.ADMINISTRATE]],
    [
        PersonalPermissionType.VIEW,
        [
            PersonalPermissionType.VIEW,
            PersonalPermissionType.MANAGE,
            PersonalPermissionType.ADMINISTRATE,
        ],
    ],
    [null, [PersonalPermissionType.VIEW, PersonalPermissionType.MANAGE, PersonalPermissionType.ADMINISTRATE]],
]);
