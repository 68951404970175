/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Abholberechtigt} from './Abholberechtigt';
import type {Erziehungsberechtigter} from './Erziehungsberechtigter';
import type {Hauptkontakt} from './Hauptkontakt';
import {Rechnungsempfaenger} from './Rechnungsempfaenger';
import type {RelationshipWithKontaktperson} from './RelationshipWithKontaktperson';

const DEFAULT_PROZENT = 100;

export class KindKontakteUtil {

    public static findHauptkontaktRelationshipsWithKontaktperson(
        kontakte: RelationshipWithKontaktperson[]): RelationshipWithKontaktperson[] {

        return kontakte.filter(KindKontakteUtil.isHauptkontakt);
    }

    public static findHauptkontakte(kontakte: RelationshipWithKontaktperson[]): Hauptkontakt[] {
        return KindKontakteUtil.findHauptkontaktRelationshipsWithKontaktperson(kontakte)
            .map(KindKontakteUtil.mapToHauptkontakt);
    }

    public static recalculateProzent(
        kontakte: RelationshipWithKontaktperson[],
        editedKontakt: RelationshipWithKontaktperson,
    ): void {

        const rechnungsempfaenger = KindKontakteUtil.findRechnungsempfaenger(kontakte);

        if (rechnungsempfaenger.length === 1) {
            KindKontakteUtil.setRechnungsempfaengerAntail100Prozent(rechnungsempfaenger[0]);

            return;
        }

        const rechnungsempfaengerMitProzentwerten = KindKontakteUtil
            .findRechnungsempfaengerMitProzentwerten(rechnungsempfaenger);

        if (rechnungsempfaengerMitProzentwerten.length === 1) {
            KindKontakteUtil.setRechnungsempfaengerAntail100Prozent(rechnungsempfaengerMitProzentwerten[0]);

            return;
        }

        if (!(rechnungsempfaengerMitProzentwerten.length === 2 &&
            editedKontakt &&
            KindKontakteUtil.isRechnungsempfaenger(editedKontakt))) {

            return;
        }

        const otherRechnungsempfaenger = rechnungsempfaengerMitProzentwerten
            .find(r => r !== editedKontakt.relationship.rechnungsempfaenger);

        KindKontakteUtil.recalulateRestProzentValueOfOtherRechnungsempfaenger(otherRechnungsempfaenger!,
            editedKontakt.relationship.rechnungsempfaenger!);
    }

    public static findRechnungsempfaengerRelationshipsWithKontaktperson(
        kontakte: RelationshipWithKontaktperson[]): RelationshipWithKontaktperson[] {

        return kontakte.filter(k => KindKontakteUtil.isRechnungsempfaenger(k));
    }

    public static findErziehungsberechtigte(kontakte: RelationshipWithKontaktperson[]): Erziehungsberechtigter[] {
        return KindKontakteUtil.findErziehungsberechtigteRelationshipsWithKontaktperson(kontakte)
            .map(KindKontakteUtil.mapToErziehungsberechtigter);
    }

    public static findErziehungsberechtigteRelationshipsWithKontaktperson(
        kontakte: RelationshipWithKontaktperson[]): RelationshipWithKontaktperson[] {

        return kontakte.filter(k => KindKontakteUtil.isErziehungsberechtigter(k));
    }

    public static findAbholberechtigt(kontakte: RelationshipWithKontaktperson[]): Abholberechtigt[] {
        return KindKontakteUtil.findAbholberechtigteRelationshipsWithKontaktperson(kontakte)
            .map(KindKontakteUtil.mapToAbholberechtigt);
    }

    public static findAbholberechtigteRelationshipsWithKontaktperson(
        kontakte: RelationshipWithKontaktperson[]): RelationshipWithKontaktperson[] {

        return kontakte.filter(k => KindKontakteUtil.isAbholberechtigt(k));
    }

    public static findRechnungsempfaenger(kontakte: RelationshipWithKontaktperson[]): Rechnungsempfaenger[] {
        return KindKontakteUtil.findRechnungsempfaengerRelationshipsWithKontaktperson(kontakte)
            .map(KindKontakteUtil.mapToRechnungsempfaenger);
    }

    private static isHauptkontakt(kontakt: RelationshipWithKontaktperson): boolean {
        return kontakt.relationship?.isHauptkontakt();
    }

    private static mapToHauptkontakt(kontakt: RelationshipWithKontaktperson): Hauptkontakt {
        return kontakt.relationship.hauptkontakt!;
    }

    private static setRechnungsempfaengerAntail100Prozent(rechnungsempfaenger: Rechnungsempfaenger): void {
        rechnungsempfaenger.setProzent();

        rechnungsempfaenger.value = DEFAULT_PROZENT;
    }

    private static isRechnungsempfaenger(kontakt: RelationshipWithKontaktperson): boolean {
        return kontakt.relationship?.isRechnungsempfaenger();
    }

    private static mapToRechnungsempfaenger(kontakt: RelationshipWithKontaktperson): Rechnungsempfaenger {
        return kontakt.relationship.rechnungsempfaenger!;
    }

    private static isErziehungsberechtigter(kontakt: RelationshipWithKontaktperson): boolean {
        return kontakt.relationship?.isErziehungsberechtigter();
    }

    private static mapToErziehungsberechtigter(kontakt: RelationshipWithKontaktperson): Erziehungsberechtigter {
        return kontakt.relationship.erziehungsberechtigter!;
    }

    private static isAbholberechtigt(kontakt: RelationshipWithKontaktperson): boolean {
        return kontakt.relationship?.isAbholberechtigt();
    }

    private static mapToAbholberechtigt(kontakt: RelationshipWithKontaktperson): Abholberechtigt {
        return kontakt.relationship.abholberechtigt!;
    }

    private static recalulateRestProzentValueOfOtherRechnungsempfaenger(
        otherRechnungsempfaenger: Rechnungsempfaenger,
        editedRechnungsempfaenger: Rechnungsempfaenger,
    ): void {

        let restProzent = Math.max(0, DEFAULT_PROZENT - editedRechnungsempfaenger.value!);
        if (restProzent === 0) {
            restProzent = 0;
        }
        otherRechnungsempfaenger.value = restProzent;
    }

    private static findRechnungsempfaengerMitProzentwerten(
        rechnungsempfaenger: Rechnungsempfaenger[]): Rechnungsempfaenger[] {

        return rechnungsempfaenger.filter(k => k.type === Rechnungsempfaenger.RECHNUNG_AUFTEILUNG_TYPE.PROZENT);
    }
}
