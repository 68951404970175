/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxCheckInCustomField} from '@dv/shared/backend/model/jax-check-in-custom-field';
import type {CustomFieldType, ICustomField, ISortable, ParamValueDType} from '@dv/shared/code';
import {checkPresent, CustomFieldUtil, isPresent} from '@dv/shared/code';
import {CustomField} from './CustomField';

export class CheckInCustomField implements ICustomField, ISortable {

    public static readonly SELECT_VALUE_SEPARATOR: string = ',';

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public selectValues: string | null = null,
        public fieldType: CustomFieldType | null = null,
        public valuePerDay: boolean = false,
        public internOnly: boolean = false,
        public editable: boolean = false,
        public displayedOnOverview: boolean = false,
        public displayedOnDayOverview: boolean = false,
        public orderValue: number | null = null,
        public kindCustomField: CustomField | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): CheckInCustomField {
        const result = new CheckInCustomField(
            data.id,
            data.name,
            CustomFieldUtil.joinSelectValues(data.selectValues, CheckInCustomField.SELECT_VALUE_SEPARATOR),
            checkPresent(data.fieldType),
            data.valuePerDay,
            data.internOnly,
            data.editable,
            data.displayedOnOverview,
            data.displayedOnDayOverview,
            data.orderValue,
        );

        if (isPresent(data.kindCustomField)) {
            result.kindCustomField = CustomField.apiResponseTransformer(data.kindCustomField);
        }

        return result;
    }

    public getParamValueType(): ParamValueDType {
        return CustomFieldUtil.customFieldTypeToParamValueType(checkPresent(this.fieldType));
    }

    public getFieldName(): string | null {
        return this.name;
    }

    public toJaxRestObject(): JaxCheckInCustomField {
        return this.toRestObject() as unknown as JaxCheckInCustomField;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id ?? undefined,
            name: this.name,
            fieldType: checkPresent(this.fieldType),
            selectValues: CustomFieldUtil.splitSelectValues(
                this.selectValues,
                CheckInCustomField.SELECT_VALUE_SEPARATOR),
            valuePerDay: this.valuePerDay,
            internOnly: this.internOnly,
            editable: this.editable,
            displayedOnOverview: this.displayedOnOverview,
            displayedOnDayOverview: this.displayedOnDayOverview,
            orderValue: this.orderValue,
        };
    }
}
