/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {ServiceContainer} from '../ServiceContainer';
import {UploadTempBlob} from './UploadTempBlob';

export class ImageBlob extends UploadTempBlob {

    public constructor(
        id: string | null = null,
        filename: string | null = '',
        progress: number = 0,
        errorMessage: string | null = null,
        file: File | null = null,
        href: string | null = null,
        mimeType: string | null = null,
        size: number | null = null,
        public base64: string | null = null,
    ) {
        super(id, filename, progress, errorMessage, file, href, mimeType, size);
    }

    public static override apiResponseTransformer(data: any): ImageBlob {
        const answer = new ImageBlob();

        angular.extend(answer, UploadTempBlob.apiResponseTransformer(data));
        answer.base64 = data.base64;

        return answer;
    }

    public static readFromUploadBlob(blob: UploadTempBlob): angular.IPromise<ImageBlob> {
        const deferred = ServiceContainer.$q.defer<ImageBlob>();
        const reader = new ServiceContainer.$window.FileReader();

        reader.readAsDataURL(blob.file);

        reader.onload = (): void => {
            const uri = reader.result;
            const base64 = uri.replace(/data:[a-zA-Z0-9-./]+;base64,/, '');

            const image = new ImageBlob();
            angular.extend(image, blob);
            image.base64 = base64;

            deferred.resolve(image);
        };

        reader.onerror = (): void => {
            deferred.reject();
        };

        return deferred.promise;
    }
}
