/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel, LeistungsArt} from '@dv/shared/code';

export abstract class AbstractLeistungsPosition implements IPersistable, IRestModel {
    public id: string | null = null;
    public leistungsArt: LeistungsArt | null = null;
    public bezeichnung: string | null = null;
    public anzahl: number | null = null;
    public betragProEinheit: number | null = null;
    public totalBetrag: number | null = null;

    public static baseTransformer<T extends AbstractLeistungsPosition>(data: any, leistungsPosition: T): T {
        leistungsPosition.id = data.id;
        leistungsPosition.leistungsArt = data.leistungsArt;
        leistungsPosition.bezeichnung = data.bezeichnung;
        leistungsPosition.anzahl = parseFloat(data.anzahl);
        leistungsPosition.betragProEinheit = parseFloat(data.betragProEinheit);
        leistungsPosition.totalBetrag = parseFloat(data.totalBetrag);

        return leistungsPosition;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            leistungsArt: this.leistungsArt,
            bezeichnung: this.bezeichnung,
            anzahl: this.anzahl,
            betragProEinheit: this.betragProEinheit,
            totalBetrag: this.totalBetrag,
        };
    }
}
