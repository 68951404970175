/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxEinzahlungsschein} from '@dv/shared/backend/model/jax-einzahlungsschein';
import type {IPersistable, IRestModel, IValidable} from '@dv/shared/code';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import {ContextualAdresse} from '../../adressen/ContextualAdresse';

export type EinzahlungsscheinType = 'ESR' | 'QR';

export class Einzahlungsschein implements IPersistable, IRestModel<JaxEinzahlungsschein>, IValidable {

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public kontoNummer: string | null = null,
        public auslieferungsnummer: string = '',
        public type: EinzahlungsscheinType = 'QR',
        public bankName: string = '',
        public bankAdresszeile: string = '',
        public contextualAdresse: ContextualAdresse = new ContextualAdresse(),
        public offsetTop: number | null = null,
        public offsetLeft: number | null = null,
        public einzahlungsscheinNotOnPageOne: boolean | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxEinzahlungsschein): Einzahlungsschein {
        const answer = new Einzahlungsschein();
        answer.id = data.id ?? null;
        answer.name = data.name;
        answer.kontoNummer = data.kontoNummer;
        answer.auslieferungsnummer = data.auslieferungsnummer;
        answer.type = data.type;
        answer.bankName = data.bankName;
        answer.bankAdresszeile = data.bankAdresszeile;
        answer.contextualAdresse = ContextualAdresse.apiResponseTransformer(data.contextualAdresse);
        answer.offsetTop = data.offsetTop;
        answer.offsetLeft = data.offsetLeft;
        answer.einzahlungsscheinNotOnPageOne = data.einzahlungsscheinNotOnPageOne ?? null;

        return answer;
    }

    public applyDefaults(): void {
        if (!angular.isNumber(this.offsetTop)) {
            this.offsetTop = 0;
        }
        if (!angular.isNumber(this.offsetLeft)) {
            this.offsetLeft = 0;
        }
        if (typeof this.einzahlungsscheinNotOnPageOne !== 'boolean') {
            this.einzahlungsscheinNotOnPageOne = false;
        }
    }

    public toRestObject(): JaxEinzahlungsschein {
        return {
            id: this.id ?? undefined,
            name: checkPresent(this.name),
            kontoNummer: checkPresent(this.kontoNummer),
            auslieferungsnummer: this.auslieferungsnummer,
            type: this.type,
            bankName: this.bankName,
            bankAdresszeile: this.bankAdresszeile,
            contextualAdresse: this.contextualAdresse.toRestObject(),
            offsetTop: checkPresent(this.offsetTop),
            offsetLeft: checkPresent(this.offsetLeft),
            einzahlungsscheinNotOnPageOne: checkPresent(this.einzahlungsscheinNotOnPageOne),
        };
    }

    public isValid(): boolean {
        return DvbUtil.isNotEmptyString(this.name) &&
            DvbUtil.isNotEmptyString(this.kontoNummer) &&
            DvbUtil.isEmptyString(this.bankName) === DvbUtil.isEmptyString(this.bankAdresszeile) &&
            angular.isNumber(this.offsetTop) &&
            angular.isNumber(this.offsetLeft) &&
            typeof this.einzahlungsscheinNotOnPageOne === 'boolean';
    }
}
