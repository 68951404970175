/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted} from '@dv/shared/code';
import {checkPersisted, Displayable, DvbRestUtil} from '@dv/shared/code';
import {Firma} from '../../firma/Firma';
import type {KinderOrt} from '../../kinderort/KinderOrt';
import {KinderOrtTransformer} from '../../kinderort/KinderOrtTransformer';
import {ExternalAnmeldungConfig} from '../ExternalAnmeldungConfig';
import {ExternalAnmeldungCustomField} from '../ExternalAnmeldungCustomField';

export class ExternalAnmeldungSetup {

    public constructor(
        public readonly kinderOrte: Persisted<KinderOrt>[],
        public readonly platzarten: Persisted<Firma>[],
        public readonly config?: Persisted<ExternalAnmeldungConfig>,
        public readonly customFields?: ExternalAnmeldungCustomField[],
    ) {
    }

    public static apiResponseTransformer(data: any): ExternalAnmeldungSetup {
        const kinderOrte = DvbRestUtil.transformArray(data.kinderOrte, KinderOrtTransformer.create())
            .map(checkPersisted);

        const platzarten = DvbRestUtil.transformArray(data.platzarten, Firma)
            .map(checkPersisted);

        const config = data.config ?
            checkPersisted(ExternalAnmeldungConfig.apiResponseTransformer(data.config)) :
            undefined;

        const customFields = DvbRestUtil.transformArray(data.customFields, ExternalAnmeldungCustomField);

        return new ExternalAnmeldungSetup(kinderOrte, platzarten, config, customFields);
    }

    public getDisplayKinderOrte(): Displayable[] {
        return this.kinderOrte.map(k => new Displayable(k.id, k.getDisplayName()));
    }
}
