/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable} from '@dv/shared/code';

export class Wohnadresse implements IPersistable {

    public constructor(
        public id: string | null = null,
        public strasse: string | null = null,
        public hausnummer: string | null = null,
        public plz: string | null = null,
        public ort: string | null = null,
        public gemeindeBfsNr: number | null = null,
        public gemeinde: string | null = null,
        public land: string | null = null,
        public externalId: string | null = null,        // aus geoadmin api
        public fuzzy: boolean | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Wohnadresse {
        return new Wohnadresse(data.id,
            data.strasse,
            data.hausnummer,
            data.plz,
            data.ort,
            data.gemeindeBFSNr,
            data.gemeinde,
            data.land,
            data.externalId,
            data.fuzzy);
    }
}
