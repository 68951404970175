/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Geschlecht} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {AdresseWithoutGemeinde} from '../adressen/AdresseWithoutGemeinde';
import {AnmeldungKindKontaktperson} from '../kontakte/AnmeldungKindKontaktperson';
import {AbstractGesuchsteller} from './AbstractGesuchsteller';

export class TagesschuleGesuchsteller extends AbstractGesuchsteller {

    public constructor(
        id: string | null,
        vorName: string,
        familienName: string,
        email: string,
        public geschlecht: Geschlecht,
        public geburtsdatum: moment.Moment | null,
        public telefon: string | null = null,
        public mobile: string | null = null,
        public telefonAusland: string | null = null,
        public kibonAddress: AdresseWithoutGemeinde | null = null,
    ) {
        super(id, vorName, familienName, email);
    }

    public static apiResponseTransformer(data: any): TagesschuleGesuchsteller {
        return new TagesschuleGesuchsteller(data.id,
            data.vorName,
            data.familienName,
            data.email,
            data.geschlecht,
            DvbRestUtil.localDateTimeToMoment(data.geburtsdatum),
            data.telefon,
            data.mobile,
            data.telefonAusland,
            data.adresse === null ? null :
                AdresseWithoutGemeinde.apiResponseTransformer(data.adresse));
    }

    public toAnmeldungKontaktperson(isHauptkontakt: boolean): AnmeldungKindKontaktperson {
        return new AnmeldungKindKontaktperson(this.vorName,
            this.familienName,
            this.geburtsdatum,
            this.geschlecht,
            this.email,
            this.telefon ?? this.telefonAusland,
            this.mobile,
            null,
            '',
            isHauptkontakt,
            this.kibonAddress);
    }
}
