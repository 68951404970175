/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {SearchResultEntry} from '@dv/shared/code';
import {KinderOrt, KinderOrtType} from '../KinderOrt';

export class Kita extends KinderOrt {
    public static override $inject: readonly string[] = [];

    public constructor() {
        super(KinderOrtType.KITA);
    }

    public toSearchResultEntry(): SearchResultEntry {
        return new SearchResultEntry('KITA', this.entityId(), this.getDisplayName());
    }
}
