/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {GebuehrType} from '@dv/shared/backend/model/gebuehr-type';
import type {IRestModel, RechnungsPositionType} from '@dv/shared/code';
import {RechnungsPosition} from './RechnungsPosition';

export class CustomRechnungsPosition extends RechnungsPosition implements IRestModel {

    public constructor(
        public gebuehrType: GebuehrType | null,
        id: string | null = null,
        positionType: RechnungsPositionType | null = null,
        text: string | null = null,
        betrag: number | null = 0,
        anzahl: number = 0,
        summe: number = 0,
    ) {
        super(id, positionType, text, betrag, anzahl, summe);
    }

    public static apiResponseTransformer(data: any): CustomRechnungsPosition {
        const position = new CustomRechnungsPosition(data.gebuehrType);
        RechnungsPosition.baseTransformer(data, position);

        return position;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            positionType: this.positionType,
            text: this.text,
            betrag: this.betrag,
            anzahl: this.anzahl,
            summe: this.summe,
            gebuehrType: this.gebuehrType,
        };
    }
}
