/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

export class BetreuungsZeitraumBelegung {

    public constructor(
        public anzahlKinder: number | null = null,
        public anzahlPlaetze: number | null = null,
        public maxAnzahlPlaetze: number | null = null,
        public belegtePlaetze: number | null = null,
        public wochentag: DayOfWeek | null = null,
        public zeitraumId: string | null = null,
        public verfuegbarBis: moment.Moment | null = null) {
    }

    public static apiResponseTransformer(data: any): BetreuungsZeitraumBelegung {
        const radix = 10;
        const answer = new BetreuungsZeitraumBelegung();

        if (angular.isNumber(data.anzahlKinder)) {
            answer.anzahlKinder = parseInt(data.anzahlKinder, radix);
        }

        if (angular.isNumber(data.anzahlPlaetze)) {
            answer.anzahlPlaetze = parseInt(data.anzahlPlaetze, radix);
        }

        if (angular.isNumber(data.maxAnzahlPlaetze)) {
            answer.maxAnzahlPlaetze = parseInt(data.maxAnzahlPlaetze, radix);
        }

        if (angular.isNumber(data.belegtePlaetze)) {
            answer.belegtePlaetze = parseFloat(data.belegtePlaetze);
        }

        answer.wochentag = data.wochentag;
        answer.zeitraumId = data.zeitraumId;
        answer.verfuegbarBis = DvbRestUtil.localDateToMoment(data.verfuegbarBis);

        return answer;
    }

    public hasBelegungWithoutKapazitaet(): boolean {
        return this.maxAnzahlPlaetze === null && this.belegtePlaetze !== null && this.belegtePlaetze > 0;
    }

    public isBelegungExceedingMaximum(): boolean {
        if (this.hasBelegungWithoutKapazitaet()) {
            return true;
        }

        return this.maxAnzahlPlaetze !== null && this.maxAnzahlPlaetze < (this.belegtePlaetze ?? 0);
    }

    public hasFreeSpaces(): boolean {
        return angular.isNumber(this.anzahlPlaetze) && this.anzahlPlaetze > (this.belegtePlaetze ?? 0);
    }
}
