/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FakturaStatus} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class FakturaStatusPeriode {

    public constructor(
        public datum: moment.Moment | null = null,
        public status: FakturaStatus | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): FakturaStatusPeriode {
        return new FakturaStatusPeriode(DvbRestUtil.localDateToMoment(data.datum), data.status);
    }
}
