/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {VersandartRechnung} from '@dv/shared/backend/model/versandart-rechnung';
import type {RechnungsRevisionError} from '@dv/shared/code';
import type {LeistungsrechnungWarning} from '../../leistungen/LeistungsrechnungWarning';
import {DisplayTask} from '../../tasks/DisplayTask';
import {Rechnung} from '../Rechnung';

export class RechnungsLaufKontaktpersonEntry {

    public constructor(
        public rechnung: Rechnung | null = null,
        public kontaktpersonDisplayName: string | null = null,
        public tasks: DisplayTask[] = [],
        public error: RechnungsRevisionError | null = null,
        public rechnungsdifferenz: boolean = false,
        public vorauszahlungen: number = 0,
        public versandartRechnung: VersandartRechnung | null = null,
        public email: string | null = null,
        public warnings: { [kindDisplayName: string]: LeistungsrechnungWarning[] } = {},
        public abweichungVormonat: boolean = false,
        public abweichungRevisionsTotal: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): RechnungsLaufKontaktpersonEntry {
        const answer = new RechnungsLaufKontaktpersonEntry();
        answer.kontaktpersonDisplayName = data.kontaktpersonDisplayName;
        answer.rechnungsdifferenz = data.rechnungsdifferenz;
        answer.versandartRechnung = data.versandartRechnung;
        answer.vorauszahlungen = parseFloat(data.vorauszahlungen);
        answer.email = data.email;
        answer.abweichungVormonat = data.abweichungVormonat;
        answer.abweichungRevisionsTotal = data.abweichungRevisionsTotal;

        if (data.rechnung) {
            answer.rechnung = Rechnung.apiResponseTransformer(data.rechnung);
        }

        if (Array.isArray(data.tasks)) {
            answer.tasks = data.tasks.map((g: any) => DisplayTask.apiResponseTransformer(g));
        }

        if (data.warnings) {
            answer.warnings = data.warnings;
        }

        return answer;
    }
}
