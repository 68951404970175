/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {EmailVersandProperties} from '../email/EmailVersandProperties';
import type {RechnungsStatus} from './status/RechnungsStatus';

export class Mahnlauf implements IRestModel {

    public constructor(
        public status: Readonly<RechnungsStatus>,
        public selectedIds: string[],
        public zahlungsFrist: moment.Moment,
        public gebuehrText: string | null,
        public gebuehrBetrag: number | null,
        public emailVersandProperties: EmailVersandProperties,
    ) {
    }

    public toRestObject(): Record<string, unknown> {
        return {
            status: this.status.toRestObject().status,
            selectedIds: this.selectedIds,
            zahlungsFrist: DvbRestUtil.momentToLocalDate(this.zahlungsFrist),
            gebuehrText: this.gebuehrText,
            gebuehrBetrag: this.gebuehrBetrag,
            emailVersandProperties: this.emailVersandProperties.toRestObject(),
        };
    }
}
