/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel} from '@dv/shared/code';

export class MandantRechnungsPDF implements IRestModel {

    public constructor(
        public rechnungsIds: string[] = [],
        public dokumentLayoutId: string | null = null,
    ) {
    }

    public toRestObject(): Record<string, unknown> {
        return {
            rechnungsIds: this.rechnungsIds,
            dokumentLayoutId: this.dokumentLayoutId,
        };
    }
}
