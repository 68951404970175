/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable} from '@dv/shared/code';
import {Translation} from '../i18n/Translation';
import {Wochenplan} from '../kinderort/Wochenplan';

export type WochenplanExternalAnmeldungDisplayType = 'ZEITRAUM_NAMES' | 'ZEITRAUM_TIMES';

export class WochenplanExternalAnmeldung implements IPersistable {

    public constructor(
        public id: string | null = null,
        public wochenplan: Wochenplan | null = null,
        public nameTranslation: Translation | null = null,
        public bemerkungTranslation: Translation | null = null,
        public displayType: WochenplanExternalAnmeldungDisplayType | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): WochenplanExternalAnmeldung {
        return new WochenplanExternalAnmeldung(
            data.id,
            Wochenplan.apiResponseTransformer(data.wochenplan),
            Translation.apiResponseTransformer(data.nameTranslation),
            data.bemerkungTranslation ?
                Translation.apiResponseTransformer(data.bemerkungTranslation) :
                new Translation(),
            data.displayType);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            wochenplanId: this.wochenplan!.id,
            nameTranslation: this.nameTranslation?.toRestObject(),
            bemerkungTranslation: this.bemerkungTranslation?.hasTranslations() ?
                this.bemerkungTranslation?.toRestObject() :
                undefined,
            displayType: this.displayType,
        };
    }
}
