/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel, IValidable} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type {IRechnungsKonfiguration} from '../konfiguration/IRechnungsKonfiguration';

export class DvbMandantKonfiguration implements IRechnungsKonfiguration, IValidable, IRestModel {

    public constructor(
        public automaticPayments: boolean = false,
        public einzahlungsscheinOnAllPages: boolean = false,
        public individualFiles: boolean = false,
        public rechnungsLaufLockedBefore: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): DvbMandantKonfiguration {
        const answer = new DvbMandantKonfiguration();
        answer.automaticPayments = data.automaticPayments;
        answer.einzahlungsscheinOnAllPages = data.einzahlungsscheinOnAllPages;
        answer.individualFiles = data.individualFiles;
        answer.rechnungsLaufLockedBefore = DvbRestUtil.localDateToMoment(data.rechnungsLaufLockedBefore);

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            automaticPayments: this.automaticPayments,
            einzahlungsscheinOnAllPages: this.einzahlungsscheinOnAllPages,
            individualFiles: this.individualFiles,
            rechnungsLaufLockedBefore: DvbRestUtil.momentToLocalDate(this.rechnungsLaufLockedBefore),
        };
    }

    public isValid(): boolean {
        return true;
    }
}
