/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsZeitraum, DayOfWeek, ITimeRange, IZeitraum} from '@dv/shared/code';
import {
    BelegungsZustand,
    checkPresent,
    DvbDateUtil,
    DvbError,
    DvbRestUtil,
    DvbUtil,
    Zeitraum,
    ZeitraumFeldIcon,
} from '@dv/shared/code';
import type {Comparator} from 'comparators';
import Comparators from 'comparators';
import moment from 'moment';
import type {BetreuungsZeitraumBelegung} from './belegung/BetreuungsZeitraumBelegung';
import {ExtraPlatzType} from './belegung/ExtraPlatzType';
import type {PlatzTyp} from './belegung/Platz';
import {Platz} from './belegung/Platz';
import {ZeitraumFeld} from './belegung/ZeitraumFeld';
import {ZeitraumFeldSelectionType} from './belegung/ZeitraumFeldSelectionType';
import type {BelegungsEinheit} from './kinderort/BelegungsEinheit';
import type {KinderOrtFraktion} from './kinderort/KinderOrtFraktion';
import type {Tagesplan} from './kinderort/Tagesplan';
import type {Wochenplan} from './kinderort/Wochenplan';

const ZEITRAUM_ID_SEPARATOR = '-';

export type ZeitraumId = `${string}${typeof ZEITRAUM_ID_SEPARATOR}${string}`;

export interface ZeitraumFeldStrategy {
    getZeitraumFeldValue: (betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung) => string;
    getZeitraumFeldTitle: (betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung) => string;
}

export class ZeitraumUtil {

    public static readonly DAY_OF_WEEK_COMPARATOR: Comparator<DayOfWeek> = Comparators
        .comparing(DvbDateUtil.getIsoWeekDayNumber);

    /**
     * @param fraktionen
     * @param dayOfWeek falls gesetzt, werden nur für diesen Wochentag Tagespläne erstellt
     */
    public static mergeTagesplaene(
        fraktionen: KinderOrtFraktion[],
        dayOfWeek?: DayOfWeek,
    ): Map<DayOfWeek, BetreuungsZeitraum[]> {

        const wochenplaene = fraktionen.filter(gruppe => gruppe.wochenplan)
            .map(gruppe => gruppe.wochenplan!);
        const distinctWochenplaene = DvbUtil.uniqueArray(wochenplaene, w => w.id);
        const result = new Map<DayOfWeek, BetreuungsZeitraum[]>();

        distinctWochenplaene.forEach(wochenplan => {
            wochenplan.tagesplaene.forEach(tagesplan => {
                if (dayOfWeek && dayOfWeek !== tagesplan.wochentag) {
                    return;
                }
                if (!result.has(tagesplan.wochentag)) {
                    result.set(tagesplan.wochentag, []);
                }

                const tagesplanZeitraeume = ZeitraumUtil.findTagesplanZeitraeume(tagesplan, wochenplan);
                const zeitraeumeOfDay = result.get(tagesplan.wochentag)!;
                tagesplanZeitraeume
                    .filter(z => !zeitraeumeOfDay.some(existing => existing.id === z.id))
                    .forEach(z => zeitraeumeOfDay.push(z));
            });
        });

        return result;
    }

    public static createZeitraumFelderWithWochenplan(wochenplan: Wochenplan): ZeitraumFeld[] {
        const tagesplaene = wochenplan.tagesplaene;
        if (!Array.isArray(tagesplaene)) {
            return [];
        }

        return tagesplaene.flatMap(tagesplan => {
            if (!Array.isArray(wochenplan.distinctZeitraeume) || !tagesplan.wochentag) {
                return [];
            }

            const tagesplanZeitraeume = ZeitraumUtil.findTagesplanZeitraeume(tagesplan, wochenplan);

            return tagesplanZeitraeume.map(zeitraum => new ZeitraumFeld(zeitraum, tagesplan.wochentag));
        });
    }

    public static createZeitraumFelder(zeitraeumeByDayOfWeek: Map<DayOfWeek, BetreuungsZeitraum[]>): ZeitraumFeld[] {

        const result: ZeitraumFeld<BetreuungsZeitraum>[] = [];
        zeitraeumeByDayOfWeek.forEach((zeitraeume, day) => {
            const zeitraumFelder = zeitraeume.map(zeitraum => new ZeitraumFeld(zeitraum, day));
            result.push(...zeitraumFelder);
        });

        return result;
    }

    public static findZeitraumFelder(wochenplan: Wochenplan, platz: Platz): ZeitraumFeld[] {
        const dayOfWeek = checkPresent(platz.wochentag);
        const belegungsEinheitId = checkPresent(platz.belegungsEinheitId);

        return ZeitraumUtil.findZeitraumFelderFromWochenplan(wochenplan, dayOfWeek, belegungsEinheitId);
    }

    /**
     * @return alle ZeitraumFelder des Wochenplans welche zu der angegebenen belegungsEinheitId und dem gewuenschen
     *         Wochentag gehoeren.
     */
    public static findZeitraumFelderFromWochenplan(
        wochenplan: Wochenplan,
        dayOfWeek: DayOfWeek,
        belegungsEinheitId: string,
    ): ZeitraumFeld[] {

        const tagesplaeneAtDayOfWeek = wochenplan.tagesplaene
            .filter(tagesplan => tagesplan.wochentag === dayOfWeek);

        if (tagesplaeneAtDayOfWeek.length !== 1) {
            return [];
        }

        const belegungsEinheiten = tagesplaeneAtDayOfWeek[0].belegungsEinheiten
            .filter(einheit => einheit.id === belegungsEinheitId);

        if (belegungsEinheiten.length !== 1) {
            return [];
        }

        return belegungsEinheiten[0].zeitraumIds.map(zeitraumId => {
            const zeitraumFelder = (wochenplan.zeitraumFelder || [])
                .filter(feld => feld.zeitraum.id === zeitraumId && feld.dayOfWeek === dayOfWeek);

            if (zeitraumFelder.length !== 1) {
                throw new Error('ZeitraumFeld wurde nicht gefunden');
            }

            return zeitraumFelder[0];
        });
    }

    /**
     * @return die belegungsEinheit, welche alle 'selected' zeitraumFelder enthaelt
     */
    public static findBelegungsEinheitFromZeitraumFelder(
        belegungsEinheiten: BelegungsEinheit[],
        zeitraumfelder: ZeitraumFeld[],
    ): BelegungsEinheit | null {

        if (!Array.isArray(zeitraumfelder)) {
            return null;
        }

        // Collect all Zeitraum Ids from 'selected' zeitraumfelder
        const zeitraumIdsFromZeitraumFelder = zeitraumfelder
            .filter(zeitraumFeld => zeitraumFeld.selected)
            .map(zeitraumFeld => checkPresent(zeitraumFeld.zeitraum.id));

        return this.findBelegungsEinheit(zeitraumIdsFromZeitraumFelder, belegungsEinheiten);
    }

    public static findBelegungsEinheit(
        betreuungsZeitraumIds: string[],
        belegungsEinheiten: BelegungsEinheit[],
    ): BelegungsEinheit | null {

        if (!Array.isArray(belegungsEinheiten) || !Array.isArray(betreuungsZeitraumIds)) {
            return null;
        }

        const found = belegungsEinheiten
            .filter(einheit => einheit.zeitraumIds.length === betreuungsZeitraumIds.length)
            .filter(einheit => einheit.zeitraumIds
                .every(zeitraumId => betreuungsZeitraumIds.includes(zeitraumId)));

        if (found.length === 1) {
            return found[0];
        }

        return null;
    }

    public static hasZeitraeumeOverlapping<T extends IZeitraum>(zeitraum1: T, zeitraum2: T): boolean {
        const von1 = zeitraum1.von;
        const bis1 = zeitraum1.bis;
        const von2 = zeitraum2.von;
        const bis2 = zeitraum2.bis;

        if (!DvbDateUtil.isValidMoment(von1) ||
            !DvbDateUtil.isValidMoment(bis1) ||
            !DvbDateUtil.isValidMoment(von2) ||
            !DvbDateUtil.isValidMoment(bis2)) {

            throw new Error(`Invalid input: von/bis must be of type moment. ${
                JSON.stringify(zeitraum1)} ${JSON.stringify(zeitraum2)}`);
        }

        return von1.isBefore(bis2) && bis1.isAfter(von2);
    }

    public static getWeekDaysFromWochenplan(wochenplan: Wochenplan | null): DayOfWeek[] {
        if (!wochenplan) {
            return [];
        }

        const result = wochenplan.tagesplaene.map(tagesplan => tagesplan.wochentag);
        result.sort(ZeitraumUtil.DAY_OF_WEEK_COMPARATOR);

        return result;
    }

    public static getWeekDaysFromGruppen(fraktionen: KinderOrtFraktion[]): DayOfWeek[] {
        const fraktionToWeekDays = (f: KinderOrtFraktion): DayOfWeek[] => this.getWeekDaysFromWochenplan(f.wochenplan);
        const result = DvbUtil.uniqueArray(fraktionen.flatMap(fraktionToWeekDays));
        result.sort(ZeitraumUtil.DAY_OF_WEEK_COMPARATOR);

        return result;
    }

    /**
     * Applies the gruppenWochenBelegung on the given zeitraumFelder.
     * Meaning that the value and icon attributes will be set if possible, as well as the attributes modified by
     * {@link setupZeitraumfeld}
     */
    public static setBelegungToZeitraumFelder(
        betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung[],
        zeitraumFelder: ZeitraumFeld[],
        zeitraumFeldStrategy: ZeitraumFeldStrategy,
    ): void {
        if (!Array.isArray(betreuungsZeitraumBelegung) || !Array.isArray(zeitraumFelder)) {
            return;
        }
        zeitraumFelder.forEach(zeitraumFeld => {
            const bzb = this.findBetreuungsZeitraumBelegung(zeitraumFeld, betreuungsZeitraumBelegung);

            if (bzb === null) {
                return;
            }
            zeitraumFeld.value = zeitraumFeldStrategy.getZeitraumFeldValue(bzb);
            this.setupZeitraumfeld(zeitraumFeld, bzb, zeitraumFeldStrategy);
            if (moment.isMoment(bzb.verfuegbarBis)) {
                zeitraumFeld.icon = ZeitraumFeldIcon.KAPAZITAET_BEGRENZT;
            }
        });
    }

    /**
     * Sets the zeitraumFelds hatFreiePlaetze, maximumUeberschritten and title attributes.
     */
    public static setupZeitraumfeld(
        zeitraumFeld: ZeitraumFeld,
        bzb: BetreuungsZeitraumBelegung,
        zeitraumFeldStrategy: ZeitraumFeldStrategy,
    ): void {
        zeitraumFeld.hatFreiePlaetze = bzb.hasFreeSpaces();
        zeitraumFeld.maximumUeberschritten = bzb.isBelegungExceedingMaximum();
        zeitraumFeld.title = zeitraumFeldStrategy.getZeitraumFeldTitle(bzb);
    }

    /**
     * @return the icon belonging to the given BelegungsZustand.
     */
    public static belegungsZustandToZeitraumFeldIcon(
        belegungsZustand: BelegungsZustand | null,
    ): ZeitraumFeldIcon | undefined {
        if (!belegungsZustand) {
            return undefined;
        }

        const zustandMap: { [k in BelegungsZustand]: ZeitraumFeldIcon } = {
            [BelegungsZustand.BELEGT]: ZeitraumFeldIcon.BELEGT,
            [BelegungsZustand.PROVISORISCH]: ZeitraumFeldIcon.PROVISORISCH,
            [BelegungsZustand.ANGEBOT_ERSTELLT]: ZeitraumFeldIcon.ANGEBOT_ERSTELLT,
        };

        return zustandMap[belegungsZustand];
    }

    public static buildPlaetze(
        fraktion: KinderOrtFraktion,
        zeitraumFelder: ZeitraumFeld[],
        firstOfWeek: moment.Moment,
    ): Platz[] {
        const plaetze: Platz[] = [];

        checkPresent(fraktion.wochenplan).tagesplaene.forEach(tagesplan => {
            const zeitraumFelderOfDay = zeitraumFelder.filter(feld =>
                feld.dayOfWeek === tagesplan.wochentag && feld.selected);

            const zeitraumFelderByPlatzTyp = this.groupByPlatzTyp(zeitraumFelderOfDay);

            zeitraumFelderByPlatzTyp.forEach((felder, platzTyp) => {
                plaetze.push(this.buildPlatz(tagesplan, felder, platzTyp, fraktion, firstOfWeek));
            });
        });

        return plaetze;
    }

    public static findBetreuungsZeitraumBelegung(
        zeitraumFeld: ZeitraumFeld,
        betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung[],
    ): BetreuungsZeitraumBelegung | null {

        if (!this.isValidInput(zeitraumFeld, betreuungsZeitraumBelegung)) {
            return null;
        }

        const found = betreuungsZeitraumBelegung.filter(
            bzb => bzb.wochentag === zeitraumFeld.dayOfWeek && bzb.zeitraumId === zeitraumFeld.zeitraum.id);

        if (found.length > 1) {
            console.warn('Duplicated BetreuungsZeitraumBelegung found.', zeitraumFeld.dayOfWeek,
                zeitraumFeld.zeitraum.id);

            return found[0];
        }

        if (found.length === 1) {
            return found[0];
        }

        return null;
    }

    /**
     * Sets the felds selection type based on the extra platz type (green and red borders).
     */
    public static setExtraPlatzSelection(zeitraumFeld: ZeitraumFeld, type: ExtraPlatzType): void {
        zeitraumFeld.selected = true;
        switch (type) {
            case ExtraPlatzType.ADDITIONAL:
                zeitraumFeld.selectionType = ZeitraumFeldSelectionType.BORDER_GREEN;
                break;
            case ExtraPlatzType.ABSENCE:
                zeitraumFeld.selectionType = ZeitraumFeldSelectionType.BORDER_RED;
                break;
            default:
                throw new Error(`ExtraPlatzType ${JSON.stringify(type)} is not implemented`);
        }
    }

    public static buildZeitraumId(zeitraum: ITimeRange): ZeitraumId {
        return `${DvbRestUtil.momentTolocaleHHMMTime(zeitraum.von)}${ZEITRAUM_ID_SEPARATOR}${
            DvbRestUtil.momentTolocaleHHMMTime(zeitraum.bis)}`;
    }

    public static zeitraumIdToZeitraum(id: ZeitraumId): Zeitraum {
        const parts = id.split(ZEITRAUM_ID_SEPARATOR);

        return new Zeitraum(
            DvbRestUtil.localeHHMMTimeToMoment(parts[0])!,
            DvbRestUtil.localeHHMMTimeToMoment(parts[1])!);
    }

    /**
     * Removes the extra platz selection from the given ZeitraumFeld (green or red border, depending on the type)
     */
    public static removeExtraPlatzSelection(zeitraumFeld: ZeitraumFeld, type: ExtraPlatzType): void {
        if (ExtraPlatzType.ADDITIONAL === type) {
            zeitraumFeld.selected = false;
            zeitraumFeld.active = false;
        }
        zeitraumFeld.selectionType = ZeitraumFeldSelectionType.DEFAULT;
    }

    /**
     * Removes toRemove from zeitraum and returns any leftover zeitraeume.
     */
    public static removeZeitraum(zeitraum: Zeitraum, toRemove: Zeitraum): Zeitraum[] {
        // no intersection
        if (zeitraum.von.isAfter(toRemove.bis) || zeitraum.bis.isBefore(toRemove.von)) {
            return [zeitraum];
        }

        // toRemove includes full range
        if (zeitraum.von.isSameOrAfter(toRemove.von) && zeitraum.bis.isSameOrBefore(toRemove.bis)) {
            return [];
        }

        const withoutToRemove = [];

        if (toRemove.von.isBetween(zeitraum.von, zeitraum.bis, null, '(]')) {
            withoutToRemove.push(new Zeitraum(zeitraum.von, toRemove.von));
        }

        if (toRemove.bis.isBetween(zeitraum.von, zeitraum.bis, null, '[)')) {
            withoutToRemove.push(new Zeitraum(toRemove.bis, zeitraum.bis));
        }

        return withoutToRemove;
    }

    private static findTagesplanZeitraeume(tagesplan: Tagesplan, wochenplan: Wochenplan): BetreuungsZeitraum[] {
        const tagesplanZeitraumIds = tagesplan.belegungsEinheiten.flatMap(be => be.zeitraumIds);

        return wochenplan.distinctZeitraeume.filter(bz => tagesplanZeitraumIds.includes(bz.id));
    }

    private static isValidInput(
        zeitraumFeld: ZeitraumFeld,
        betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung[],
    ): boolean {
        if (zeitraumFeld?.dayOfWeek && zeitraumFeld.zeitraum?.id) {
            return Array.isArray(betreuungsZeitraumBelegung) && betreuungsZeitraumBelegung.length > 0;
        }

        return false;
    }

    private static groupByPlatzTyp(
        zeitraumFelderOfDay: ZeitraumFeld[],
    ): Map<PlatzTyp, ZeitraumFeld[]> {
        const zeitraumFelderByPlatzTyp = new Map<PlatzTyp, ZeitraumFeld[]>();

        zeitraumFelderOfDay.forEach(z => {
            if (z.kontingent) {
                const kontingentId = checkPresent(z.kontingent.id);
                if (!zeitraumFelderByPlatzTyp.has(kontingentId)) {
                    zeitraumFelderByPlatzTyp.set(kontingentId, []);
                }

                zeitraumFelderByPlatzTyp.get(kontingentId)!.push(z);
            } else {
                if (!zeitraumFelderByPlatzTyp.has(null)) {
                    zeitraumFelderByPlatzTyp.set(null, []);
                }
                zeitraumFelderByPlatzTyp.get(null)!.push(z);
            }
        });

        return zeitraumFelderByPlatzTyp;
    }

    private static buildPlatz(
        tagesplan: Tagesplan,
        zeitraumFelder: ZeitraumFeld[],
        kontingentId: PlatzTyp,
        fraktion: KinderOrtFraktion,
        firstOfWeek: moment.Moment,
    ): Platz {
        const belegungsEinheit = ZeitraumUtil.findBelegungsEinheitFromZeitraumFelder(
            tagesplan.belegungsEinheiten, zeitraumFelder);

        if (!belegungsEinheit) {
            const wochentag = DvbDateUtil.getDayOfWeekMoment(tagesplan.wochentag, firstOfWeek).format('dddd');
            const args = {
                zeitraumFelder,
                kontingentId,
                wochentag,
                gruppe: fraktion.getDisplayName(),
            };

            throw DvbError.validationError('ERRORS.ERR_BELEGUNGSEINHEIT_CONFLICT', args);
        }

        const platz = new Platz();
        platz.belegungsEinheitId = belegungsEinheit.id;
        platz.wochentag = tagesplan.wochentag;
        platz.kontingentId = kontingentId;

        return platz;
    }
}
