/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbUtil, isPresent} from '@dv/shared/code';
import {Tarif} from '../../tarife/Tarif';
import {AbstractKontingentValue} from '../AbstractKontingentValue';
import type {KinderOrt} from '../KinderOrt';
import {KontingentType} from './AbstractKontingent';

export class FirmenKontingentValue extends AbstractKontingentValue<KontingentType.FIRMA> {

    public kitas: KinderOrt[] = [];
    public kitasIds: string[] = [];
    public tarifId: string | null = null;
    public tarif: Tarif | null = null;

    public constructor() {
        super(KontingentType.FIRMA);
    }

    public static apiResponseTransformer(data: any): FirmenKontingentValue {
        const answer = super.baseTransformer(data, new FirmenKontingentValue());
        if (Array.isArray(data.kitasIds)) {
            answer.kitasIds = data.kitasIds;
        }
        answer.tarifId = data.tarifId;
        answer.tarif = data.tarif ? Tarif.apiResponseTransformer(data.tarif) : null;

        return answer;
    }

    public override toRestObject(): Record<string, unknown> {
        return {
            ...super.toRestObject(),
            kitasIds: this.kitasIds,
            tarifId: this.tarifId,
        };
    }

    public addKita(kita: KinderOrt): boolean {
        const isNew = this.kitas.every(k => k.id !== kita.id);

        if (isNew) {
            this.kitas.push(kita);
            this.kitasIds.push(kita.id!);
        }

        return isNew;
    }

    public removeKita(kita: KinderOrt): void {
        DvbUtil.removeFromArray(kita, this.kitas);
        DvbUtil.removeFromArray(kita.id, this.kitasIds);
    }

    public isKitaSet(): boolean {
        return this.kitas.length > 0;
    }

    public setTarif(tarif?: Tarif | null): boolean {
        if (isPresent(tarif)) {
            this.tarif = tarif;
            this.tarifId = tarif.id;
        } else {
            this.tarif = null;
            this.tarifId = null;
        }

        return true;
    }
}
