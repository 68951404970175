/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {checkPresent, END_OF_TIME, isNullish, isPresent} from '@dv/shared/code';
import type {unitOfTime} from 'moment';
import moment from 'moment';
import type {Options, Weekday} from 'rrule';
import {Frequency, RRule, rrulestr} from 'rrule';
import {ServiceContainer} from '../../ServiceContainer';
import {RRuleBisType} from './RRuleBisType';
import {RRuleFormModel} from './RRuleFormModel';

export class RRuleUtil {

    public static initModel(rruleStr: string, endDate?: moment.Moment): RRuleFormModel {
        const rrule: RRule = rrulestr(rruleStr);

        const model = new RRuleFormModel();
        model.frequenzTyp = rrule.options.freq;
        model.interval = rrule.options.interval;

        if (rrule.options.count) {
            model.bisType = RRuleBisType.ANZAHL;
            model.repetitions = rrule.options.count;
        } else {
            model.bisType = rrule.options.until && END_OF_TIME.isSameOrBefore(rrule.options.until) ?
                RRuleBisType.BIS_EWIG :
                RRuleBisType.BIS_DATUM;
        }

        model.endDateLocal = model.bisType === RRuleBisType.BIS_EWIG ? undefined : endDate;

        return model;
    }

    public static toRRuleString(model: RRuleFormModel): string {
        const rrule: RRule = RRuleUtil.toRRule(model);

        return rrule.toString();
    }

    public static toRRule(model: RRuleFormModel): RRule {
        if (isNullish(model.startUTC)) {
            throw new Error('startUTC parameter missing');
        }

        const dtstart = moment(model.startUTC).utc(true).toDate();

        const rrule: RRule = new RRule({
            freq: model.frequenzTyp,
            interval: model.interval,
            dtstart,
            ...RRuleUtil.getRecurrenceLimit(model),
        });

        return rrule;
    }

    public static rruleToText(strRRule: string): string {
        const translations = {
            dayNames: moment.weekdays(),
            monthNames: moment.months(),
            tokens: {},
        };

        const rRule = RRule.fromString(strRRule);

        return rRule.toText(RRuleUtil.getText, translations, RRuleUtil.dateFormat);
    }

    /**
     * Returns the occurrences between after and before.
     */
    public static momentsBetween(
        rrule: RRule,
        after: moment.Moment,
        before: moment.Moment,
        inclusive: boolean = true,
    ): moment.Moment[] {
        const dates: Date[] = rrule.between(after.toDate(), before.toDate(), inclusive);

        // passing a Date to moment.utc makes moment aware of the daylight saving time and shifts it. This is
        // avoided here (to keep the time) by parsing as "UTC" and then converting to local time with
        // "keepLocalTime" flag
        return dates.map(date => moment.utc(date).local(true));
    }

    /**
     * Determines a repeating termins end date based on the given parameters.
     */
    public static calculateEndDateLocalTime(model: RRuleFormModel): moment.Moment {
        const limit = RRuleUtil.getRecurrenceLimit(model);
        if (limit.until) {
            return moment.utc(limit.until).local(true);
        }

        const unit = this.toMomentUnit(model.frequenzTyp);
        const bis = moment(checkPresent(model.startUTC)).local();
        bis.add((model.repetitions ?? 1) * model.interval, unit);

        return bis;
    }

    private static toMomentUnit(frequenzTyp: Frequency): unitOfTime.DurationConstructor {
        switch (frequenzTyp) {
            case Frequency.DAILY:
                return 'days';
            case Frequency.MONTHLY:
                return 'month';
            case Frequency.WEEKLY:
                return 'week';
            default:
                throw new Error(`Unsupported frequency type: ${String(frequenzTyp)}`);
        }
    }

    private static getText(id: string | number | Weekday): string {
        let retStr: string | undefined = '';
        if (typeof id === 'string') {
            retStr = ServiceContainer.$translate.instant(`PERSONAL.TERMIN.RRULE.${id.toUpperCase()}`);
        }

        return isNullish(retStr) ? '' : retStr;
    }

    private static dateFormat(year: number, month: string, day: number): string {
        if (year === END_OF_TIME.year()) {
            return ServiceContainer.$translate.instant('PERSONAL.TERMIN.BIS_TYPE.BIS_EWIG').toLowerCase();
        }

        return moment()
            .date(day)
            .year(year)
            .month(month)
            .format('LL');
    }

    private static getRecurrenceLimit(model: RRuleFormModel): Partial<Pick<Options, 'count' | 'until'>> {
        switch (model.bisType) {
            case RRuleBisType.ANZAHL:
                if (isNullish(model.repetitions)) {
                    throw new Error('repetitions parameter missing');
                }

                return {count: model.repetitions};

            case RRuleBisType.BIS_EWIG:
                return {until: moment(END_OF_TIME).utc(true).toDate()};

            case RRuleBisType.BIS_DATUM:
                if (isPresent(model.endDateLocal)) {
                    return {until: moment(model.endDateLocal).utc(true).toDate()};
                }
                if (isNullish(model.endUTC)) {
                    throw new Error('endUTC parameter missing');
                }

                return {until: moment(model.endUTC).utc(true).toDate()};

            default:
                throw new Error(`no recurrence limit implemented for ${String(model.bisType)}`);
        }
    }
}
