/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {KinderOrt} from '../kinderort/KinderOrt';
import {KinderOrtTransformer} from '../kinderort/KinderOrtTransformer';
import {BetreuungsGutschein} from './BetreuungsGutschein';
import {KiTaxSchema} from './KiTaxSchema';

export class KiTaxVerfuegung implements ILimited {

    public constructor(
        public readonly schemaVersion: KiTaxSchema,
        public readonly referenzNummer: string | null = null,
        public readonly tempBlobId: string | null = null,
        public readonly kita: KinderOrt | null = null,
        public readonly betreuungsGutscheine: BetreuungsGutschein[] = [],
        public readonly gueltigAb: moment.Moment | null = null,
        public readonly gueltigBis: moment.Moment | null = null,
        public readonly importKitaName: string | null = null,
        public readonly importKindName: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): KiTaxVerfuegung {
        let betreuungsGutscheine = [];

        if (Array.isArray(data.betreuungsGutscheine)) {
            betreuungsGutscheine = data.betreuungsGutscheine
                .map((zeitabschnitt: any) => BetreuungsGutschein.apiResponseTransformer(zeitabschnitt));
        }

        return new KiTaxVerfuegung(
            KiTaxSchema.apiResponseTransformer(data.schemaVersion),
            data.referenzNummer,
            data.tempBlobId,
            data.kita ? KinderOrtTransformer.create().apiResponseTransformer(data.kita) : null,
            betreuungsGutscheine,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
            data.importKitaName,
            data.importKindName,
        );
    }
}
