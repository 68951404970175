/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungStatusText} from '@dv/shared/code';
import {ContextualAdresse} from '../../adressen/ContextualAdresse';
import {Translation} from '../../i18n/Translation';
import {KitaKonfigurationTexte} from '../KitaKonfigurationTexte';
import type {IKitaVendorKonfiguration} from '../konfiguration/IKitaVendorKonfiguration';

export class DvbKitaRechnungsKonfiguration implements IKitaVendorKonfiguration {

    public constructor(
        public absenderAdresse: ContextualAdresse = new ContextualAdresse(),
        public esrId: number | null = null,
        public esrName: string | null = null,
        public layoutId: number | null = null,
        public layoutName: string | null = null,
        public zahlungsKonditionen: Translation | null = null,
        public footer: Translation | null = null,
        public totalBetragIncrement: number = 0.05,
        public texte: { [index: string]: KitaKonfigurationTexte } = {},
        public hideSummary: boolean = false,
        public hideCustomerNumber: boolean = false,
    ) {
    }

    public static createNew(): DvbKitaRechnungsKonfiguration {
        const result = new DvbKitaRechnungsKonfiguration();
        result.zahlungsKonditionen = new Translation();
        result.footer = new Translation();

        return result;
    }

    public static apiResponseTransformer(data: any): DvbKitaRechnungsKonfiguration {
        const answer = new DvbKitaRechnungsKonfiguration();
        answer.absenderAdresse = ContextualAdresse.apiResponseTransformer(data.absenderAdresse);
        answer.esrId = data.esrId;
        answer.esrName = data.esrName;
        answer.layoutId = data.layoutId;
        answer.layoutName = data.layoutName;
        answer.zahlungsKonditionen =
            data.zahlungsKonditionen ? Translation.apiResponseTransformer(data.zahlungsKonditionen) : new Translation();
        answer.footer = data.footer ? Translation.apiResponseTransformer(data.footer) : new Translation();
        answer.totalBetragIncrement = parseFloat(data.totalBetragIncrement);
        answer.hideSummary = data.hideSummary;
        answer.hideCustomerNumber = data.hideCustomerNumber;

        data.texte.forEach((entry: { status: RechnungStatusText; texte: any }) => {
            answer.texte[entry.status] = KitaKonfigurationTexte.apiResponseTransformer(entry.texte);
        });

        return answer;
    }

    public toRestObject(): Record<string, unknown> {

        const texte = Object.keys(this.texte).map(status => ({
            status,
            texte: this.texte[status].toRestObject(),
        }), this);

        return {
            absenderAdresse: this.absenderAdresse.toRestObject(),
            esrId: this.esrId,
            esrName: this.esrName,
            layoutId: this.layoutId,
            layoutName: this.layoutName,
            zahlungsKonditionen: this.zahlungsKonditionen?.toRestObject(),
            footer: this.footer?.toRestObject(),
            totalBetragIncrement: this.totalBetragIncrement,
            hideSummary: this.hideSummary,
            hideCustomerNumber: this.hideCustomerNumber,
            texte,
        };
    }
}
