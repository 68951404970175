/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IEntityList, IPersistable, IRestModel} from '@dv/shared/code';
import {BetreuungsZeitraum, checkPresent, zeitraumComparator} from '@dv/shared/code';
import angular from 'angular';
import type {ZeitraumFeld} from '../belegung/ZeitraumFeld';
import {ZeitraumUtil} from '../zeitraumUtil';
import {Tagesplan} from './Tagesplan';

export class Wochenplan implements IPersistable, IEntityList, IRestModel {

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public isDefault: boolean = false,
        public showBetreuungsZeitraumName: boolean = false,
        public tagesplaene: Tagesplan[] = [],
        public distinctZeitraeume: BetreuungsZeitraum[] = [],
        public zeitraumFelder: ZeitraumFeld[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): Wochenplan {

        let distinctZeitraeume = [];
        if (Array.isArray(data.distinctZeitraeume)) {
            distinctZeitraeume = data.distinctZeitraeume.map((z: any) => BetreuungsZeitraum.apiResponseTransformer(z))
                .sort(zeitraumComparator);
        }

        if (Array.isArray(data.tagesplaene)) {
            const tagesplaene = data.tagesplaene.map((t: any) => Tagesplan.apiResponseTransformer(t));

            const wochenplan = new Wochenplan(
                data.id,
                data.name,
                data.isDefault,
                data.showBetreuungsZeitraumName,
                tagesplaene,
                distinctZeitraeume,
            );

            wochenplan.zeitraumFelder = ZeitraumUtil.createZeitraumFelderWithWochenplan(wochenplan);

            return wochenplan;
        }

        return new Wochenplan(
            data.id,
            data.name,
            data.isDefault,
            data.showBetreuungsZeitraumName,
            [],
            distinctZeitraeume,
        );
    }

    /**
     * Wochenplaene can be huge (i.E. Tagesschule with many modules and all possible combinations are valid)
     * angular.copy(wochenplan) takes forever.
     * --> Instead, we create a copy that copies the tagesplaene shallow.
     */
    public copyWithShallowTagesplaene(): Wochenplan {
        return new Wochenplan(this.id,
            this.name,
            this.isDefault,
            this.showBetreuungsZeitraumName,
            this.tagesplaene,
            angular.copy(this.distinctZeitraeume),
            angular.copy(this.zeitraumFelder));
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public badgeText(): string | undefined {
        return undefined;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public getDisplayName(): string {
        return this.name ?? '';
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            showBetreuungsZeitraumName: this.showBetreuungsZeitraumName,
            // other properties are currently not supported
        };
    }

}
