/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable, IPersistable} from '@dv/shared/code';

export abstract class AbstractGesuchsteller implements IDisplayable, IPersistable {

    protected constructor(
        public id: string | null = null,
        public vorName: string,
        public familienName: string,
        public email: string,
    ) {
    }

    public getDisplayName(): string {
        return `${this.familienName} ${this.vorName}`;
    }
}
