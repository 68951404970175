/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted} from '@dv/shared/code';
import {Zahlung} from './Zahlung';

export class Zahlungen {

    public constructor(
        public zahlungen: Persisted<Zahlung>[] = [],
        public ausstehenderBetrag: number | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Zahlungen {
        const answer = new Zahlungen();

        answer.ausstehenderBetrag = parseFloat(data.ausstehenderBetrag);

        if (Array.isArray(data.zahlungen)) {
            answer.zahlungen = data.zahlungen.map((zahlung: any) => Zahlung.apiResponseTransformer(zahlung));
        }

        return answer;
    }
}
